import { useNavigate, useOutletContext } from "react-router-dom";
import { FixedSizeList } from "react-window";
import { useSelector } from "react-redux";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";
import {
  Box,
  ListItem,
  ListItemText,
  Paper,
  Divider,
  Typography,
  Button,
} from "@mui/material";

import FlexWrapper from "../../FlexWrapper";
import ReceiveInspectionItemInfoModal from "./ReceiveInspectionItemInfoModal";
import ReceiveInspectionItemForm from "./ReceiveInspectionItemForm";

const ReceiveInspectionScanView = () => {
  const { orderItems, receivedItems, setLoading, handleReceive } =
    useOutletContext();
  const { currentDepartment } = useSelector((state) => state.user);
  const { openAlert } = useGeneralAlert();
  const navigate = useNavigate();

  const remainingItems = orderItems?.filter(
    (item) => item.lineitemqtyreceived !== item.lineitemqtypendingreceipt
  );

  // Render each row in FixedSizeList
  const renderRow = ({ index, style }) => {
    const item = remainingItems[index];

    return (
      <Box key={item?._id} style={style}>
        <ListItem>
          <ListItemText
            primary={
              <FlexWrapper justifyContent="space-between" alignItems="baseline">
                <FlexWrapper gap={1} alignItems="center">
                  <Typography variant="body1" fontWeight="bold">
                    {item?.lineitemname}
                  </Typography>
                  <ReceiveInspectionItemInfoModal item={item} />
                </FlexWrapper>

                <Typography color="black" variant="body2" fontWeight="bold">
                  Remaining:{" "}
                  {parseInt(item?.lineitemqtypendingreceipt) -
                    parseInt(item?.lineitemqtyreceived)}
                </Typography>
              </FlexWrapper>
            }
            secondary={
              <>
                <Typography variant="body2" color="text.secondary">
                  {item?.lineitemdescription}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {item?.lineitemclass} | {item?.lineitemboxsize}
                </Typography>
              </>
            }
          />
        </ListItem>
        {index !== remainingItems.length - 1 && <Divider />}
      </Box>
    );
  };

  const handleReceiveOrder = async () => {
    setLoading("Receiving Order...");
    try {
      const updatedOrder = await handleReceive();

      openAlert({
        type: "success",
        message: `Successfully received order. Receipt ID: ${updatedOrder?.itemreceiptinternalid}`,
        duration: 3000,
      });
      navigate(
        `/portal/${currentDepartment?.toLowerCase()}/receive-transaction-inspection`,
        { replace: true }
      );
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not fulfill order: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    } finally {
      setLoading("");
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Paper
        elevation={1}
        sx={{
          p: 1,
          backgroundColor: "white",
        }}
      >
        <ReceiveInspectionItemForm />

        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Paper elevation={1} sx={{ p: 1, backgroundColor: "white" }}>
            <Box height="calc(100vh - 200px)" sx={{ overflowY: "hidden" }}>
              {remainingItems?.length === 0 ? (
                receivedItems?.length ? (
                  <FlexWrapper
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    <Button
                      onClick={handleReceiveOrder}
                      size="large"
                      variant="contained"
                      color="success"
                    >
                      Receive Order
                    </Button>
                  </FlexWrapper>
                ) : (
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "center", my: 3, color: "gray" }}
                  >
                    No items Remaining.
                  </Typography>
                )
              ) : (
                <FixedSizeList
                  itemSize={100}
                  itemCount={remainingItems.length}
                  height={window.innerHeight - 200}
                  width="100%"
                >
                  {renderRow}
                </FixedSizeList>
              )}
            </Box>
          </Paper>
        </Box>
      </Paper>
    </Box>
  );
};

export default ReceiveInspectionScanView;
