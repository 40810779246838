import { useEffect, useState } from "react";
import { NoteAlt } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import GeneralModal from "../../GeneralModal";
import axios from "../../../axios/axios.config";

const Modal = ({ open, handleClose, children }) => {
  return (
    <GeneralModal open={open} handleClose={handleClose}>
      {children}
    </GeneralModal>
  );
};

//Can update the RMA Notes field on netsuite and also send an alert email if needed or it can just display the current notes in mongo
const RMANotes = ({ color, noUpdate = false, formid }) => {
  const [open, setOpen] = useState(false);
  const [notes, setNotes] = useState("");
  const { username } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    getCurrentNotes();
  }, []);

  const getCurrentNotes = async () => {
    try {
      const { data } = await axios.get(
        `forms/get/labor-claim/${formid}/get-notes`
      );

      setNotes(data.form?.rmanotes || "");
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error, could not get current rma notes:  ${
            error.response?.data?.msg || error.message
          }`,
          duration: 5000,
        })
      );
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    try {
      const updatedNote = formData.get("note");
      const updatedTime = new Date().toLocaleString();

      if (!updatedNote) {
        throw new Error("Note Field is Required");
      }

      const { data } = await axios.patch(`forms/update/no-version/${formid}`, {
        updateData: {
          "form.rmanotes": `${
            notes ? `${notes}\n` : ""
          }${updatedTime} (${username}) - ${updatedNote}`,
        },
      });

      setNotes(data.form.rmanotes);

      if (data.form.rmainternalid) {
        //update Netsuite notes
        axios.patch(
          `netsuite/patch/rma/${data.form.rmainternalid}/update-notes`,
          {
            notes: `${data.form.rmanotes}`,
          }
        );
      }

      dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully Updated RMA Notes`,
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error, could not update rma:  ${
            error.response?.data?.msg || error.message
          }`,
          duration: 5000,
        })
      );
    } finally {
      setOpen(false);
    }
  };

  return (
    <>
      <Modal open={open} handleClose={() => setOpen(false)}>
        <Box p={2}>
          <Typography gutterBottom variant="h4" textAlign="center">
            Labor Claim Notes
          </Typography>
        </Box>
        <Box
          pb={2}
          px={2}
          sx={{
            maxHeight: 400,
            overflow: "scroll",
          }}
        >
          {notes
            ? notes.split("\n").map((note) => {
                return <Typography key={note}>{note}</Typography>;
              })
            : "No Notes To Display"}
        </Box>
        {noUpdate ? null : (
          <>
            <Divider />
            <Box component="form" p={2} onSubmit={handleSubmit}>
              <TextField
                required
                name="note"
                fullWidth
                multiline
                minRows={4}
                maxRows={6}
                placeholder="Add Note(s) Here"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 1 }}
              >
                Add Note
              </Button>
            </Box>
          </>
        )}
      </Modal>

      <Tooltip title="RMA Notes" placement="top" arrow>
        <IconButton onClick={() => setOpen(true)}>
          <NoteAlt color={color} />
        </IconButton>
      </Tooltip>
    </>
  );
};
export default RMANotes;
