import { Box, Button, Container, Typography } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";

import FlexWrapper from "../../FlexWrapper";

const ReceiveInspectionDetailsScreen = ({ setIsDetailsModalOpen }) => {
  const { orderData } = useOutletContext();
  const details = [
    { label: "Transaction ID", detail: orderData?.tranid },
    { label: "Transaction Type", detail: orderData?.trantype },
    { label: "Transaction Date", detail: orderData?.date },
    { label: "Brand", detail: orderData?.brandpackaging },
    { label: "Location", detail: orderData?.location },
    { label: "Entity", detail: orderData?.entity },
    { label: "Memo", detail: orderData?.memo },
    { label: "Warehouse Notes", detail: orderData?.warehousenotes },
    { label: "Reference", detail: orderData?.tranref },
    { label: "SO Reference", detail: orderData?.soreference },
  ];

  return (
    <>
      <Typography textAlign="center" variant="h5" py={1}>
        Order Details
      </Typography>
      <Container maxWidth="sm">
        <Box
          sx={{
            backgroundColor: "#1976d2",
            borderRadius: "8px",
            p: "24px",
            color: "#FFF",
          }}
        >
          {details.map((detail) =>
            detail.detail ? (
              <Box
                key={detail.label}
                sx={{ borderBottom: "1px solid white", py: 1 }}
              >
                <FlexWrapper justifyContent="space-between" alignItems="center">
                  <Typography variant="subtitle1" gutterBottom pr={2}>
                    {detail.label}:
                  </Typography>
                  <Typography variant="body1" textTransform="uppercase">
                    {detail.detail}
                  </Typography>
                </FlexWrapper>
              </Box>
            ) : null
          )}
        </Box>

        <FlexWrapper justifyContent="flex-end">
          <Button
            sx={{ my: 1 }}
            endIcon={<ArrowForward />}
            onClick={() => setIsDetailsModalOpen(false)}
          >
            Continue
          </Button>
        </FlexWrapper>
      </Container>
    </>
  );
};
export default ReceiveInspectionDetailsScreen;
