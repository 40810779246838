import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CheckCircle, Close, ExpandMore, Search } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Container,
  Dialog,
  Icon,
  IconButton,
  InputAdornment,
  List,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../../FlexWrapper";

const PackProcessSearchItem = ({ open, close }) => {
  const [search, setSearch] = useState("");
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);

  const searchInputRef = useRef(null);
  const { items, packdetails } = useSelector((state) => state.packProcessV2);

  useEffect(() => {
    handleSearchInputRef();
  }, [results]);

  const handleSearch = (event) => {
    setSearch(event.target?.value?.toUpperCase());
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const found = items.filter((item) => {
      const itemSplit = search?.split("*");
      const newItem = itemSplit[0].trim();
      return (
        item.item.toUpperCase().includes(newItem) ||
        item.upc.toUpperCase().includes(newItem) ||
        item.vpn.toUpperCase().includes(newItem) ||
        item.addtlupc?.includes(newItem)
      );
    });

    let foundBoxes = [];

    for (
      let palletnumber = 0;
      palletnumber < packdetails.length;
      palletnumber++
    ) {
      const boxes = packdetails[palletnumber]?.boxes;
      const palletId = packdetails[palletnumber]?.palletId;

      for (const box of boxes) {
        const { items, boxId } = box;
        //search items arr for the item
        const found = items
          .filter(({ item }) => {
            const itemSplit = search?.split("*");
            const newItem = itemSplit[0].trim();
            return (
              item.item.toUpperCase().includes(newItem) ||
              item.upc.toUpperCase().includes(newItem) ||
              item.vpn.toUpperCase().includes(newItem) ||
              item.addtlupc?.includes(newItem)
            );
          })
          .map((boxObj) => {
            return {
              boxObj,
              palletNumber: palletId,
              boxNumber: boxId,
            };
          });

        //save them to foundBoxes if found
        if (found.length) {
          foundBoxes = [...foundBoxes, ...found];
        }
      }
    }

    const finalResults = {};
    found.map((obj) => {
      if (!finalResults[obj._id]) {
        finalResults[obj._id] = { item: obj, pallets: [] };
      }
    });

    foundBoxes.map(({ boxObj, palletNumber, boxNumber }) => {
      finalResults[boxObj.item._id].pallets.push({
        palletNumber,
        palletPackedAmount: boxObj.palletpackedamount,
        boxNumber,
      });
    });

    for (const pallet of packdetails) {
      for (const itemObj of pallet.items) {
        if (finalResults[itemObj.item._id]) {
          finalResults[itemObj.item._id].pallets.push({
            palletNumber: pallet.palletId,
            palletPackedAmount: itemObj.palletpackedamount,
          });
        }
      }
    }

    setResults(finalResults);
    setSearch("");
    setLoading(false);
  };
  //handles search input focus (hides keyboard)
  const handleSearchInputRef = () => {
    if (searchInputRef.current !== null) {
      searchInputRef.current.readOnly = true;
      searchInputRef.current.focus();
      setTimeout(() => {
        searchInputRef.current.focus();
        searchInputRef.current.readOnly = false;
      });
    }
  };

  return (
    <Dialog open={open} fullScreen>
      <Container maxWidth="xl">
        <IconButton onClick={() => close()}>
          <Close />
        </IconButton>

        <Box mt={2}>
          <form onSubmit={handleSubmit}>
            <TextField
              inputRef={searchInputRef}
              autoFocus
              fullWidth
              label="Search for an item"
              placeholder="Search for an item..."
              value={search}
              onChange={handleSearch}
              variant="outlined"
              inputProps={{ autoComplete: "off" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Box>
        {/* Loading spinner */}
        {loading && (
          <FlexWrapper>
            <CircularProgress variant="indeterminate" value={10} />
            <Typography>Searching Item...</Typography>
          </FlexWrapper>
        )}

        {!loading && results ? (
          <Stack
            spacing={1}
            sx={{
              maxHeight: "85vh",
              overflowY: "auto",
            }}
          >
            {Object.keys(results).length > 0 ? (
              <List>
                {Object.keys(results).map((key, index) => (
                  <Accordion key={key} defaultExpanded={index === 0}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <FlexWrapper flexDirection="column">
                        <FlexWrapper alignItems="center" gap={1}>
                          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            {results[key].item.item}
                          </Typography>
                          {results[key].item.quantity ===
                            results[key].item.packedamount && (
                            <Icon>
                              <CheckCircle color="success" />
                            </Icon>
                          )}
                        </FlexWrapper>

                        <Typography>
                          Qty: {results[key].item.quantity} - Packed:{" "}
                          {results[key].item.packedamount}
                        </Typography>
                        <FlexWrapper gap={1} alignItems="center">
                          <Typography variant="body2" color="GrayText">
                            {results[key].item.iful}
                          </Typography>
                          -
                          <Typography variant="body2" color="GrayText">
                            Line ID: {results[key].item.lineid}
                          </Typography>
                        </FlexWrapper>
                      </FlexWrapper>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack
                        spacing={2}
                        sx={{
                          maxHeight: "60vh",
                          overflowY: "scroll",
                          padding: 2,
                          backgroundColor: "#f5f5f5", // Light background for the stack
                          borderRadius: 2, // Rounded corners for a modern look
                        }}
                      >
                        {results[key].pallets.length ? (
                          results[key].pallets.map((obj, index) => (
                            <Paper
                              key={index}
                              elevation={3}
                              sx={{
                                padding: 2,
                                backgroundColor: "#ffffff", // White background for each item
                                borderRadius: 2, // Rounded corners
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: "bold" }}
                              >
                                Pallet {obj.palletNumber}{" "}
                                {obj.boxNumber ? `Box ${obj.boxNumber}` : ""}
                              </Typography>
                              <Typography variant="body1">
                                Packed: {obj.palletPackedAmount}
                              </Typography>
                            </Paper>
                          ))
                        ) : (
                          <Typography fontWeight="bold" textAlign="center">
                            No Pallet Details To Display
                          </Typography>
                        )}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </List>
            ) : (
              <Box pt={3}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  textAlign="center"
                >
                  Item Not Found
                </Typography>
              </Box>
            )}
          </Stack>
        ) : null}
      </Container>
    </Dialog>
  );
};
export default PackProcessSearchItem;
