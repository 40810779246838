import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Cancel } from "@mui/icons-material";
import {
  Box,
  TextField,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  Container,
  Button,
} from "@mui/material";

const ReceiveInspectionSearchView = ({ setIsSearchViewOpen }) => {
  const { orderItems, receivedItems } = useOutletContext();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOrderItem, setFilteredOrderItem] = useState(null);
  const [filteredReceivedItems, setFilteredReceivedItems] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);

  // Handle Search Submission
  const handleSearch = (event) => {
    event.preventDefault();
    setHasSearched(true); // Track that a search has been performed

    if (!searchTerm) {
      setFilteredOrderItem(null);
      setFilteredReceivedItems([]);
      return;
    }

    const parsedItem = searchTerm?.split("*")[0].trim();

    // Find the first matching order item
    const matchedOrderItem = orderItems?.find(
      (item) =>
        item?.lineitemname.toLowerCase().includes(parsedItem.toLowerCase()) ||
        item?.lineitemupc.toLowerCase().includes(parsedItem.toLowerCase()) ||
        item.lineitemaddtlupccodes?.includes(parsedItem)
    );

    // Filter received items based on search input
    const matchedReceivedItems = receivedItems?.filter(
      ({ item, manualitem }) => {
        const dataItem = item || manualitem;
        return (
          dataItem?.lineitemname
            ?.toLowerCase()
            ?.includes(parsedItem?.toLowerCase()) ||
          dataItem?.lineitemupc
            ?.toLowerCase()
            ?.includes(parsedItem?.toLowerCase()) ||
          dataItem?.lineitemaddtlupccodes?.includes(parsedItem?.toLowerCase())
        );
      }
    );

    setFilteredOrderItem(matchedOrderItem || null);
    setFilteredReceivedItems(matchedReceivedItems);
    setSearchTerm("");
  };

  return (
    <Container maxWidth="md">
      <IconButton size="small" onClick={() => setIsSearchViewOpen(false)}>
        <Cancel color="error" />
      </IconButton>

      {/* Search Input & Button */}
      <Box
        display="flex"
        gap={1}
        my={2}
        component="form"
        onSubmit={handleSearch}
      >
        <TextField
          autoFocus
          autoComplete="off"
          placeholder="Scan item to search"
          variant="outlined"
          fullWidth
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button variant="contained" type="submit">
          Search
        </Button>
      </Box>

      {/* Show results only after search is performed */}
      {hasSearched && (
        <Paper elevation={2} sx={{ p: 2 }}>
          {/* Order Item Header */}
          {filteredOrderItem ? (
            <Box
              sx={{
                mb: 2,
                p: 1,
                border: "1px solid lightgray",
                borderRadius: 1,
              }}
            >
              <Typography variant="h6" fontWeight="bold">
                {filteredOrderItem.lineitemname}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Received: {filteredOrderItem.lineitemqtyreceived} | Remaining:{" "}
                {filteredOrderItem.lineitemqtypendingreceipt -
                  filteredOrderItem.lineitemqtyreceived}
              </Typography>
            </Box>
          ) : (
            <Typography fontWeight="bold">
              No matching order item found.
            </Typography>
          )}

          {/* Received Items List */}
          {filteredReceivedItems.length > 0 ? (
            <List>
              {filteredReceivedItems.map(
                (
                  {
                    item,
                    manualitem,
                    status,
                    timereceived,
                    receivedby,
                    type,
                    _id,
                  },
                  index
                ) => {
                  const dataItem = item || manualitem;
                  return (
                    <Box key={_id}>
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography variant="body1" fontWeight="bold">
                              {dataItem.lineitemname}{" "}
                              {type !== "inspection" ? " (Extra) " : null}
                            </Typography>
                          }
                          secondary={
                            <>
                              <Typography variant="body2">
                                <strong>Status:</strong>{" "}
                                {status?.replace("Bad-", "")}
                              </Typography>
                              <Typography variant="body2">
                                <strong>Received By:</strong> {receivedby}
                              </Typography>
                              <Typography variant="body2">
                                <strong>Time:</strong>{" "}
                                {new Date(timereceived).toLocaleString()}
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                      {index !== filteredReceivedItems.length - 1 && (
                        <Divider />
                      )}
                    </Box>
                  );
                }
              )}
            </List>
          ) : (
            <Typography pt={1} fontWeight="bold">
              No received items found.
            </Typography>
          )}
        </Paper>
      )}
    </Container>
  );
};

export default ReceiveInspectionSearchView;
