import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { Cancel, Search } from "@mui/icons-material";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import axios from "../../../axios/axios.config";
import FlexWrapper from "../../FlexWrapper";

const ReceiveInspectionItemForm = () => {
  const { orderItems, setLoading, orderData, handleStateUpdate } =
    useOutletContext();
  const { username, currentSubsidiary } = useSelector((state) => state.user);
  const { openAlert } = useGeneralAlert();
  const itemInputRef = useRef(null);

  const [itemInput, setItemInput] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    handleItemRefFocus();
  }, []);

  //handle input focus on render
  const handleItemRefFocus = () => {
    if (itemInputRef.current !== null) {
      itemInputRef.current.readOnly = true;
      itemInputRef.current.focus();
      setTimeout(() => {
        itemInputRef.current.focus();
        itemInputRef.current.readOnly = false;
      });
    }
  };

  const handleChanges = (event) => {
    setItemInput(event.target.value?.toUpperCase()?.trim());
  };
  //fn to submit item
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (Boolean(error)) {
      setError("");
    }

    try {
      const updatedItem = itemInput?.split("*")[0].trim();
      const foundItems = orderItems?.filter(
        (item) =>
          item.lineitemname === updatedItem ||
          item.lineitemupc === updatedItem ||
          item.lineitemaddtlupccodes?.includes(updatedItem)
      );

      if (!foundItems?.length) {
        //get item from mongo
        const itemResponse = await axios.get(
          `items/get/${updatedItem}?subsidiary=${currentSubsidiary}`
        );
        const item = itemResponse.data;
        // open modal to prompt user that the item does not exist and if he would like to add it to list
        setModalContent({
          type: "add-new",
          item: null,
          title: `Item (${item?.item}) not found in order`,
          isStatusFormOpen: false,
          ismanualaddition: true,
          manualitem: {
            lineitemboxsize: item?.boxsize,
            lineitemclass: item?.class,
            lineiteminternalid: parseInt(item?.internalid),
            lineitemname: item?.item,
            lineitemsubclass: item?.subclass,
            lineitemupc: item?.upc,
            lineitemdescription: item?.description,
            tranid: orderData?.tranid,
          },
        });
      } else {
        //match(es) found; check qty
        const foundItem = foundItems.find(
          (item) => item.lineitemqtyreceived < item.lineitemqtypendingreceipt
        );

        if (!foundItem) {
          return setModalContent({
            type: "add-existing",
            item: foundItems[0],
            title: `${foundItems[0]?.lineitemname} has already been completely received`,
            isStatusFormOpen: false,
            ismanualaddition: true,
            manualitem: null,
          });
        }

        //open the status modal to update item condition
        setModalContent({
          type: "inspection",
          item: foundItem,
          title: foundItem.lineitemname,
          isStatusFormOpen: true,
          ismanualaddition: false,
          manualitem: null,
        });
      }
    } catch (error) {
      setError(error.response?.data?.msg || error.message);
    } finally {
      setItemInput("");
      setLoading("");
    }
  };
  //fn to receive an item after status has been set
  const handleReceiveItemSubmit = async (event) => {
    event.preventDefault();
    setLoading("Receiving Item...");
    try {
      const formData = new FormData(event.target);
      const selectedCondition = formData.get("itemCondition");

      //update item quantities and status
      const response = await axios.patch(
        `receive-inspection/update/receive/item/${modalContent?.type}`,
        {
          itemId: modalContent?.item?._id || null,
          orderId: orderData?._id,
          qtyreceived: 1,
          status: selectedCondition,
          receivedby: username,
          ismanualaddition: modalContent?.ismanualaddition,
          manualitem: modalContent?.manualitem || null,
        }
      );
      handleStateUpdate(response.data);
      openAlert({
        type: "success",
        message: `Successfully received ${
          modalContent?.item?.lineitemname ||
          modalContent?.manualitem?.lineitemname
        }`,
        duration: 2000,
      });
    } catch (error) {
      setError(
        `Could not receive item: ${error.response?.data?.msg || error.message}`
      );
    } finally {
      setLoading("");
      handleItemRefFocus();
    }
  };

  return (
    <>
      {/* Scan Item Form */}
      <form onSubmit={handleSubmit}>
        <TextField
          autoComplete="off"
          error={Boolean(error)}
          helperText={error || null}
          required
          fullWidth={true}
          autoFocus={true}
          size="small"
          label="Scan Item"
          name="receive-input"
          value={itemInput}
          onChange={handleChanges}
          InputProps={{
            endAdornment: (
              <IconButton type="submit">
                <Search />
              </IconButton>
            ),
          }}
        />
      </form>

      {/* Form Submission Modal */}
      {Boolean(modalContent) && (
        <Dialog
          open={Boolean(modalContent)}
          disableEscapeKeyDown
          keepMounted={false}
        >
          <FlexWrapper>
            <IconButton size="small" onClick={() => setModalContent(null)}>
              <Cancel color="error" />
            </IconButton>
          </FlexWrapper>
          <DialogTitle textAlign="center" sx={{ py: 0 }}>
            {modalContent?.title}
          </DialogTitle>
          <DialogContent>
            {/* item found and can be inspected */}

            {modalContent?.isStatusFormOpen && (
              <form onSubmit={handleReceiveItemSubmit}>
                <FormControl required>
                  <FormLabel>Select Item Condition</FormLabel>
                  <RadioGroup name="itemCondition">
                    <FormControlLabel
                      value="Good"
                      control={<Radio required />}
                      label="Good"
                    />
                    <FormControlLabel
                      value="Bad-Rust"
                      control={<Radio required />}
                      label="Rust [PUT ON RUST PALLET]"
                    />
                    <FormControlLabel
                      value="RTCHINA-Missing Hardware"
                      control={<Radio required />}
                      label="Missing Hardware [RETURN TO VENDOR]"
                    />
                    <FormControlLabel
                      value="RTCHINA-Image Discrepancy"
                      control={<Radio required />}
                      label="Image Discrepancy [RETURN TO VENDOR]"
                    />
                    <FormControlLabel
                      value="Bad-Damaged Boot"
                      control={<Radio required />}
                      label="Damaged Boot"
                    />
                    <FormControlLabel
                      value="Bad-Damaged Dust Ring"
                      control={<Radio required />}
                      label="Damaged Dust Ring"
                    />
                    <FormControlLabel
                      value="Bad-Remanufactured"
                      control={<Radio required />}
                      label="Remanufactured"
                    />
                    <FormControlLabel
                      value="Bad-Used"
                      control={<Radio required />}
                      label="Used"
                    />
                    <FormControlLabel
                      value="Bad-Stiff or Loose Joint"
                      control={<Radio required />}
                      label="Stiff or Loose Joint"
                    />
                    <FormControlLabel
                      value="Bad-Non Matching Competitor Product"
                      control={<Radio required />}
                      label="Non Matching - Competitor Product"
                    />
                  </RadioGroup>
                </FormControl>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  Receive Item
                </Button>
              </form>
            )}

            {/* Promt user to add item */}
            {modalContent?.type !== "inspection" &&
              !modalContent?.isStatusFormOpen && (
                <Box>
                  <Typography>
                    Are you sure you want to add this item to current receiving
                    list?
                  </Typography>
                </Box>
              )}
          </DialogContent>
          {modalContent?.type !== "inspection" &&
            !modalContent?.isStatusFormOpen && (
              <DialogActions sx={{ justifyContent: "center", gap: 1, pb: 2 }}>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() =>
                    setModalContent({
                      ...modalContent,
                      isStatusFormOpen: true,
                      title: modalContent?.item
                        ? modalContent?.item?.lineitemname
                        : modalContent?.manualitem?.lineitemname,
                    })
                  }
                  sx={{ px: 4 }}
                >
                  Add Item
                </Button>
              </DialogActions>
            )}
        </Dialog>
      )}
    </>
  );
};
export default ReceiveInspectionItemForm;
