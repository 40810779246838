import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getPrinterObjectFromUser } from "../printers/printerSlice";

import axios from "../../../axios/axios.config";

const initialState = {
  _id: "",
  username: "",
  subsidiary: [],
  currentSubsidiary: "",
  timezone: "",
  rolelevel: "",
  firstname: "",
  lastname: "",
  location: [],
  currentLocation: "",
  printer: "",
  department: [],
  currentDepartment: "",
  isAuthenticated: false,
  currentNetsuiteLocationIds: [],
  pacejetstation: null,
};

export const register = createAsyncThunk(
  "user/register",
  async (userData, thunkAPI) => {
    try {
      await axios.post("users/register", userData);
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.msg || error.message
      );
    }
  }
);

export const login = createAsyncThunk(
  "user/login",
  async (userData, thunkAPI) => {
    try {
      const response = await axios.post("users/login", userData);
      return response.data;
    } catch (error) {
      localStorage.clear();
      if (!error.response.data.msg) {
        if (error.response.data) {
          return thunkAPI.rejectWithValue(error.response.data);
        }
        return thunkAPI.rejectWithValue("Server Error");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const loginLog = createAsyncThunk(
  "user/loginLog",
  async (data, thunkAPI) => {
    try {
      const deviceResponse = await axios.get("device/name");
      await axios.post("/login-log", {
        ...data,
        devicename: deviceResponse.data.deviceName,
      });
    } catch (error) {
      if (!error.response.data.msg) {
        return thunkAPI.rejectWithValue("Server Error");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "user/updatePassword",
  async ({ input, username }, thunkAPI) => {
    try {
      await axios.patch(
        "users/update-password",
        { newPassword: input, username },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
    } catch (error) {
      if (!error.response.data.msg) {
        return thunkAPI.rejectWithValue("Server Error");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const updatePrinter = createAsyncThunk(
  "user/updatePrinter",
  async ({ printerid, userid }, thunkAPI) => {
    const { user } = thunkAPI.getState();

    try {
      const response = await axios.patch(
        `users/${userid}/update-printer`,
        { printerid },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      await thunkAPI.dispatch(getPrinterObjectFromUser(printerid)).unwrap();

      return {
        ...response.data,
        currentDepartment: user.currentDepartment,
        currentLocation: user.currentLocation,
        currentSubsidiary: user.currentSubsidiary,
        isAuthenticated: true,
      };
    } catch (error) {
      if (!error.response.data.msg) {
        return thunkAPI.rejectWithValue("Server Error");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const updateWhseItemLabelPrinter = createAsyncThunk(
  "user/updateWhseItemLabelPrinter",
  async (printer, thunkAPI) => {
    const { user } = thunkAPI.getState();
    try {
      await axios.patch(`users/${user._id}/update-item-lebels-printer`, {
        printerId: printer._id,
      });
      return { newPrinter: printer };
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.msg || error.message
      );
    }
  }
);

export const updatePacejetStation = createAsyncThunk(
  "user/updatePacejetStation",
  async ({ stationid, userid }, thunkAPI) => {
    const { user } = thunkAPI.getState();
    try {
      const response = await axios.patch(
        `users/${userid}/update-pacejet-station`,
        { stationid }
      );

      return {
        ...response.data,
        currentDepartment: user.currentDepartment,
        currentLocation: user.currentLocation,
        currentSubsidiary: user.currentSubsidiary,
        isAuthenticated: true,
      };
    } catch (error) {
      if (!error.response.data.msg) {
        return thunkAPI.rejectWithValue("Server Error");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const updateLanguage = createAsyncThunk(
  "user/updateLanguage",
  async ({ language }, thunkAPI) => {
    const { _id, currentDepartment, currentLocation, currentSubsidiary } =
      thunkAPI.getState().user;

    try {
      const response = await axios.patch(`users/${_id}/update-language`, {
        language,
      });

      return {
        ...response.data,
        currentDepartment: currentDepartment,
        currentLocation: currentLocation,
        currentSubsidiary: currentSubsidiary,
        isAuthenticated: true,
      };
    } catch (error) {
      if (!error.response?.data?.msg) {
        return thunkAPI.rejectWithValue("Server Error");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);
//Will be called when user refreshes app
export const refreshUser = createAsyncThunk(
  "user/refreshUser",
  async (user, thunkAPI) => {
    try {
      const currentDepartment = localStorage.getItem("department");
      const currentSubsidiary = localStorage.getItem("subsidiary");
      const currentLocation = localStorage.getItem("location");
      const subsidiaryinternalid = parseInt(
        localStorage.getItem("subsidiaryinternalid")
      );
      const currentNetsuiteLocationIds = JSON.parse(
        localStorage.getItem("netsuitelocations")
      );

      return {
        ...user,
        currentDepartment: currentDepartment,
        currentLocation: currentLocation,
        currentSubsidiary: currentSubsidiary,
        subsidiaryinternalid,
        currentNetsuiteLocationIds,
        isAuthenticated: true,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.msg || error.message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUserFromStorage: () => {
      return {
        ...JSON.parse(localStorage.getItem("user")),
        currentLocation: localStorage.getItem("location"),
        currentLocationInternalId: parseInt(
          localStorage.getItem("locationinternalid")
        ),
        currentSubsidiary: localStorage.getItem("subsidiary"),
        currentSubsidiaryInternalId: parseInt(
          localStorage.getItem("subsidiaryinternalid")
        ),
        currentDepartment: localStorage.getItem("department"),
        currentNetsuiteLocationIds: JSON.parse(
          localStorage.getItem("netsuitelocations")
        ),
        isAuthenticated: true,
      };
    },
    resetUser: () => {
      localStorage.clear();
      return initialState;
    },
    setUserInfo: (state, { payload }) => {
      localStorage.setItem("location", payload.location);
      localStorage.setItem("subsidiary", payload.subsidiary);
      localStorage.setItem(
        "subsidiaryinternalid",
        payload.subsidiaryinternalid
      );
      localStorage.setItem("locationinternalid", payload.locationinternalid);
      localStorage.setItem("department", payload.department);
      localStorage.setItem(
        "netsuitelocations",
        JSON.stringify(payload.netsuiteLocationIds)
      );
      state.currentLocation = payload.location;
      state.currentSubsidiary = payload.subsidiary;
      state.currentSubsidiaryInternalId = payload.subsidiaryinternalid;
      state.currentLocationInternalId = payload.locationinternalid;
      state.currentDepartment = payload.department;
      state.currentNetsuiteLocationIds = payload.netsuiteLocationIds;
    },
    setCurrentDepartment: (state, { payload }) => {
      localStorage.setItem("department", payload);
      state.currentDepartment = payload;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(login.fulfilled, (state, { payload }) => {
        localStorage.clear();
        localStorage.setItem("token", payload.token);
        localStorage.setItem("user", JSON.stringify(payload.user));
        return { ...payload.user, isAuthenticated: true };
      })
      .addCase(updatePrinter.fulfilled, (state, { payload }) => {
        localStorage.setItem("user", JSON.stringify(payload));
        return { ...state, printer: payload.printer };
      })
      .addCase(updateWhseItemLabelPrinter.fulfilled, (state, { payload }) => {
        return { ...state, itemlabelprinter: payload.newPrinter };
      })
      .addCase(updatePacejetStation.fulfilled, (state, { payload }) => {
        localStorage.setItem("user", JSON.stringify(payload));
        return { ...state, pacejetstation: payload.pacejetstation };
      })
      .addCase(updateLanguage.fulfilled, (state, { payload }) => {
        localStorage.setItem("user", JSON.stringify(payload));
        return { ...state, language: payload.language };
      })
      .addCase(refreshUser.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(refreshUser.rejected, (state, action) => {
        return initialState;
      });
  },
});

export const {
  getUserFromStorage,
  resetUser,
  setUserInfo,
  setCurrentDepartment,
} = userSlice.actions;
export default userSlice.reducer;
