import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import { sendGeneralEmail } from "../../../utils/email/generalEmailFunction";
import { pdf } from "@react-pdf/renderer";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { ExitToApp, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import axios from "../../../axios/axios.config";
import BasicTable from "../../../components/BasicTable";
import LabelsAndBoxRequestBoxBreakdownPDF from "../../../components/PDF/sales/LabelsAndBoxRequestBoxBreakdownPDF";
import LabelsAndBoxRequestItemBreakdownPDF from "../../../components/PDF/sales/LabelsAndBoxRequestItemBreakdownPDF";
import Loading from "../../../components/Loading";
import LabelsAndBoxRequestsItemBreakdownV2 from "../../../components/PDF/multi-page/labels-box-requests/LabelsAndBoxRequestsItemBreakdownV2";

const LabelsAndBoxRequestCustomerServiceFormView = () => {
  const [formLoading, setFormLoading] = useState(true);
  const [input, setInput] = useState({
    date: new Date().toISOString().slice(0, 10),
    customer: "",
    customerEmail: "",
    address: "",
    shippingMethod: "Ground",
    neededBy: "",
    notes: "",
    boxes: false,
    reasonForLabels: "",
    file: null,
    status: "pending",
    carrier: "",
    tracking: "",
    totalBoxes: "",
    totalLabels: "",
    liftGateRequired: false,
    freightCost: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { formid } = useParams();
  const { form, url } = useLoaderData();

  const COLUMNS = [
    { id: "boxsize", label: "Box Size", minWidth: "50%", align: "center" },
    { id: "qty", label: "Quantity", minWidth: "50%", align: "center" },
  ];

  const ITEM_COLUMNS = [
    { id: "item", label: "Item", align: "center" },
    { id: "boxSize", label: "Box Size", align: "center" },
    { id: "qty", label: "Quantity", align: "center" },
    { id: "competitor", label: "Competitor", align: "center" },
  ];

  useEffect(() => {
    if (form) {
      setInput({
        ...form.versions[0],
        customerEmail: form.versions[0].customerEmail
          ? form.versions[0].customerEmail.join(",")
          : "",
      });
      setFormLoading(false);
    }
  }, []);

  const handleEditForm = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.patch(`/forms/update/no-version/${formid}`, {
        updateData: {
          versions: [
            {
              ...input,
              customerEmail: input.customerEmail.trim().split(","),
              shippedDate: input.shippedDate ? input.shippedDate : "",
            },
          ],
          updatedby: {
            username: user.username,
            department: user.currentDepartment,
            usertype: user.usertype,
            email: user.email,
          },
        },
      });

      setInput(response.data.versions[0]);
      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully updated form details",
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error updating form details: ${
            error.response?.data?.msg || error.message
          }`,
          duration: 3000,
        })
      );
    }
  };

  const handleEditShipping = async (event) => {
    event.preventDefault();
    try {
      if (!input.customerEmail) {
        return dispatch(
          openGeneralAlert({
            type: "error",
            message: "Please update customer email before updating shipping.",
            duration: 5000,
          })
        );
      }

      await axios.patch(`/forms/update/no-version/${formid}`, {
        updateData: {
          versions: [
            {
              ...input,
              status: "shipped",
              customerEmail: Array.isArray(input.customerEmail)
                ? input.customerEmail
                : input.customerEmail.trim().split(","),
              shippedDate: new Date().toISOString(),
            },
          ],
          updatedby: {
            username: user.username,
            department: user.currentDepartment,
            usertype: user.usertype,
            email: user.email,
          },
        },
      });

      let email = null;
      if (Array.isArray(input.customerEmail)) {
        email = input.customerEmail;
      } else {
        email = input.customerEmail.trim().split(",");
      }

      const attachments = [];
      if (form?.versions[0]?.analytics) {
        const itemBreakdownBlob = await pdf(
          <LabelsAndBoxRequestsItemBreakdownV2
            data={form.versions[0].analytics
              .map((el) => el)
              .sort((a, b) => a.item.localeCompare(b.item))}
            totalQty={form.versions[0].totalLabels}
            address={form.versions[0].address}
            date={form.versions[0].date}
            customer={form.versions[0].customer}
            title={`${form.versions[0].customer}_${form._id}_ITEM_BREAKDOWN.pdf`}
            notes={form.versions[0].notes}
            id={form._id}
          />
        ).toBlob();

        const itemBreakdownBase64 = await convertBlobToBase64(
          itemBreakdownBlob
        );

        attachments.push({
          content: itemBreakdownBase64,
          filename: `${form.versions[0].customer}_${form._id}_ITEM_BREAKDOWN.pdf`,
          type: "application/pdf",
          disposition: "attachment",
        });
      }

      await sendGeneralEmail({
        to: process.env.REACT_APP_TEST_EMAIL || email,
        cc: user.email,
        subject: "Order Has Shipped",
        templateId: "d-036fd43a273b4781961bfb19ea94d4ad",
        dynamicTemplateData: {
          _id: formid,
          shippingAddress: input.address,
          shippingMethod: input.shippingMethod,
          customer: input.customer,
          shippedDate: new Date().toLocaleDateString(),
          neededBy: input.neededBy,
          reason: input.reasonForLabels,
          notes: input.notes,
          boxes: input.boxes,
          totalBoxes: input.totalBoxes,
          totalLabels: input.totalLabels,
          status: input.status,
          shippingCarrier: input.carrier,
          trackingNumber: input.tracking,
          year: new Date().getFullYear(),
        },
        attachments,
        type: "Labels And Box Requests",
        currentDepartment: user.currentDepartment,
        currentSubsidiary: user.currentSubsidiary,
        username: user.username,
      });

      navigate(
        `/portal/${user.currentDepartment.toLowerCase()}/forms/labels-box-requests`
      );

      dispatch(
        openGeneralAlert({
          type: "success",
          message:
            "Successfully updating shipping information and emailed customer.",
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error updating shipping details: ${
            error.response?.data?.msg || error.message
          }`,
          duration: 5000,
        })
      );
    }
  };

  const convertBlobToBase64 = async (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(blob);
    });
  };

  const handleChanges = (event) => {
    if (event.target.name === "boxes") {
      setInput({ ...input, boxes: !input.boxes });
      return;
    }
    if (event.target.name === "liftGateRequired") {
      setInput({ ...input, liftGateRequired: !input.liftGateRequired });
      return;
    }
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  //Get the analytics data back from DB and format it to display on table
  const getDataRows = () => {
    const analyticsArr = form.versions[0].analytics;
    const objStorage = {};
    let totalQty = 0;

    for (let el of analyticsArr) {
      if (el.boxSize && el.boxSize !== "NOBOX") {
        if (!objStorage[el.boxSize]) {
          objStorage[el.boxSize] = 0;
        }
        objStorage[el.boxSize] += el.qty;
        totalQty += el.qty;
      }
    }

    const rows = Object.keys(objStorage)
      .sort()
      .map((key) => {
        return { boxsize: key, qty: objStorage[key] };
      });

    return rows;
  };

  if (formLoading) {
    return <Loading message="Loading Form..." />;
  }

  return (
    <Container maxWidth="md" sx={{ my: 2 }}>
      <Tooltip title="Back To Main Page" placement="top" arrow>
        <IconButton
          onClick={() =>
            navigate(
              `/portal/${user.currentDepartment.toLowerCase()}/forms/labels-box-requests`
            )
          }
        >
          <ExitToApp color="error" />
        </IconButton>
      </Tooltip>
      {form?.createdby && (
        <Typography variant="body1" gutterBottom fontWeight="bold">
          Created By: {form.createdby.username} ({form?.createdby.email || ""})
        </Typography>
      )}
      {form?.updatedby && (
        <Typography variant="body1" gutterBottom fontWeight="bold">
          Last Updated By: {form.updatedby.username} (
          {form?.updatedby.email || ""})
        </Typography>
      )}

      <Accordion elevation={1} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h5">Form Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={2}
            component="form"
            onSubmit={handleEditForm}
          >
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                type="date"
                label="Created Date"
                name="date"
                value={input.date}
                onChange={handleChanges}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                label="Customer"
                name="customer"
                value={input.customer}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                label="Customer Email"
                name="customerEmail"
                value={input.customerEmail}
                onChange={(event) =>
                  setInput({
                    ...input,
                    [event.target.name]: event.target.value.trim(),
                  })
                }
                helperText={
                  <Typography variant="caption" color="primary">
                    Please enter emails seperated by a comma (ex.
                    test1@email.com,test2@email.com)
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                multiline
                minRows={4}
                type="text"
                label="Shipping Address"
                name="address"
                value={input.address}
                onChange={handleChanges}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                label="Shipping Method"
                name="shippingMethod"
                value={input.shippingMethod}
                onChange={handleChanges}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Tooltip
                      placement="top"
                      title={
                        <Typography variant="body1">
                          For larger orders it may require shipping the order
                          via Freight. If so, does this Shipping Address require
                          a liftgate?
                        </Typography>
                      }
                    >
                      <Checkbox
                        name="liftGateRequired"
                        checked={input.liftGateRequired}
                        onChange={handleChanges}
                      />
                    </Tooltip>
                  }
                  label="Lift Gate Required"
                />
                <FormHelperText error>
                  {input.liftGateRequired
                    ? "An additional fee will be charged for liftgate delivery"
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="date"
                label="Needed By"
                name="neededBy"
                value={input.neededBy}
                onChange={handleChanges}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="select-reasons-for-labels">
                  Reason For Labels Needed
                </InputLabel>
                <Select
                  labelId="select-reasons-for-labels"
                  id="select-reasos-for-labels-id"
                  name="reasonForLabels"
                  value={input.reasonForLabels}
                  onChange={handleChanges}
                  label="Reason For Labels Needed"
                >
                  <MenuItem value="changeover">Changeover</MenuItem>
                  <MenuItem value="replacements">Replacements</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Tooltip
                      placement="top"
                      title={
                        <Typography variant="body1">
                          Check this box if you need physical boxes included
                          with each label. The corresponding box size for each
                          label will be included. Ex. 100 labels will include
                          100 boxes. If only a few boxes are needed, please
                          include in the additional notes section below instead.
                        </Typography>
                      }
                    >
                      <Checkbox
                        name="boxes"
                        checked={input.boxes}
                        onChange={handleChanges}
                      />
                    </Tooltip>
                  }
                  label="Boxes"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                minRows={4}
                type="text"
                label="Additional Notes"
                name="notes"
                value={input.notes}
                onChange={handleChanges}
              />
            </Grid>
            {/* We will put this in its own icon to look better */}
            <Grid item xs={12}>
              <Typography>
                {url ? (
                  <>
                    <span style={{ fontWeight: "bold" }}>File:</span>
                    <a href={url}>{input.file}</a>
                  </>
                ) : (
                  "No File"
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth type="submit" variant="contained">
                Edit Form Details
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={1} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h5">Shipping And Tracking</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Grid
            container
            spacing={2}
            component="form"
            onSubmit={handleEditShipping}
          >
            <Grid item xs={12}>
              <FormControl
                fullWidth
                required
                disabled={form.versions[0].status === "shipped"}
              >
                <InputLabel id="select-reasons-for-labels">Status</InputLabel>
                <Select
                  labelId="select-status"
                  id="select-status-id"
                  name="status"
                  value={input.status}
                  onChange={handleChanges}
                  label="Status"
                >
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="shipped">Shipped</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                label="Shipping Carrier"
                name="carrier"
                value={input.carrier}
                onChange={(e) =>
                  setInput({
                    ...input,
                    [e.target.name]: e.target.value.toUpperCase(),
                  })
                }
                disabled={form.versions[0].status === "shipped"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                label="Tracking Number"
                name="tracking"
                value={input.tracking}
                onChange={handleChanges}
                disabled={form.versions[0].status === "shipped"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="number"
                label="Freight Cost"
                name="freightCost"
                value={input.freightCost}
                onChange={handleChanges}
                disabled={form.versions[0].status === "shipped"}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                type="submit"
                disabled={form.versions[0].status === "shipped"}
                sx={{
                  display:
                    form.versions[0].status === "shipped" ? "none" : "block",
                }}
              >
                Edit Shipping Details
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {form?.versions[0]?.analytics && (
        <Grid container pt={2} spacing={3}>
          {input.boxes && (
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ textAlign: "center" }}
                >
                  Box Details
                </Typography>
                <LabelsAndBoxRequestBoxBreakdownPDF
                  data={getDataRows()}
                  totalQty={form.versions[0].totalBoxes}
                  address={form.versions[0].address}
                  date={form.versions[0].date}
                  customer={form.versions[0].customer}
                  title={`${form.versions[0].customer}_BOX_DETAILS_BREAKDOWN.pdf`}
                  notes={form.versions[0].notes}
                  id={form._id}
                />
              </Box>
              <BasicTable columns={COLUMNS} rows={getDataRows()} />
              <Typography variant="h6" sx={{ pt: 2 }}>
                Total Boxes: {form.versions[0].totalBoxes}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography
                variant="h4"
                gutterBottom
                sx={{ textAlign: "center" }}
              >
                Item Breakdown
              </Typography>
              <LabelsAndBoxRequestItemBreakdownPDF
                data={form.versions[0].analytics
                  .map((el) => el)
                  .sort((a, b) => a.item.localeCompare(b.item))}
                totalQty={form.versions[0].totalLabels}
                address={form.versions[0].address}
                date={form.versions[0].date}
                customer={form.versions[0].customer}
                title={`${form.versions[0].customer}_${form._id}_ITEM_BREAKDOWN.pdf`}
                notes={form.versions[0].notes}
                id={form._id}
              />
            </Box>
            <BasicTable
              columns={ITEM_COLUMNS}
              rows={form.versions[0].analytics
                .map((el) => el)
                .sort((a, b) => a.item.localeCompare(b.item))}
            />
            <Typography variant="h6" sx={{ pt: 2 }}>
              Total Labels: {form.versions[0].totalLabels}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default LabelsAndBoxRequestCustomerServiceFormView;
