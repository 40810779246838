import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Search } from "@mui/icons-material";
import { PICKTOONTOSOCOLUMNS } from "../../../utils/columns/pick/pickToOntoSoColumns";
import { toggleNav } from "../../../redux/features/nav/navBarSlice";
import { DataGrid } from "@mui/x-data-grid";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import Loading from "../../../components/Loading";
import axios from "../../../axios/axios.config";
import FlexWrapper from "../../../components/FlexWrapper";

const PickToOntoSoPage = () => {
  const [toNumber, setToNumber] = useState("");
  const [loading, setLoading] = useState("");
  const [error, setError] = useState({ type: "", message: "" });
  const [orderData, setOrderData] = useState([]);

  const {
    currentNetsuiteLocationIds,
    currentSubsidiary,
    username,
    pacejetstation,
  } = useSelector((state) => state.user);
  const { openAlert } = useGeneralAlert();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const originalPage = searchParams.get("original-page");

  useEffect(() => {
    if (searchParams.get("order")) {
      handleToSubmit(null, searchParams.get("order"));
    }
  }, []);

  useEffect(() => {
    if (orderData.length) {
      dispatch(toggleNav(true));
    } else {
      dispatch(toggleNav(false));
    }
    return () => {
      dispatch(toggleNav(false));
    };
  }, [orderData]);

  const handleResetdata = () => {
    setToNumber("");
    setError({ type: "", message: "" });
    setOrderData([]);
    setSearchParams({});
  };
  //can accept an event parameter or orderinput when called
  const handleToSubmit = async (event, orderInput) => {
    if (event) {
      event.preventDefault();
    }
    setLoading("Loading TO Data...");

    if (error.type === "search" && error.message !== "") {
      setError({ type: "", message: "" });
    }

    try {
      const orderResponse = await axios.post(
        `netsuite/post/pick-order/get-data/transfer-order/${
          orderInput ? orderInput : toNumber
        }`
      );

      const totalQtyReceived = orderResponse?.data?.reduce((acc, current) => {
        return acc + parseInt(current.quantityreceived);
      }, 0);

      const newRow = {
        links: [],
        customer: "O'REILLY AUTOMOTIVE LLC",
        datereceived: "10/28/2024",
        id: "47714586",
        itemclass: null,
        iteminternalid: `QTYROW-${totalQtyReceived}`,
        itemname: "TOTAL",
        quantityreceived: `${totalQtyReceived}`,
        receiptid: "IREC271809",
        sonumber: "SO804244",
        tonumber: "TO93220",
        totalqty: "8",
      };

      setOrderData([...orderResponse.data, newRow]);
    } catch (error) {
      setError({
        type: "search",
        message: error.response?.data?.msg || error.message,
      });
    } finally {
      setLoading("");
    }
  };

  const handleAutoPick = async () => {
    setLoading("Auto Picking TO onto SO...");

    try {
      const soNumber = orderData[0].sonumber;
      const toNumber = orderData[0].tonumber;
      const ToItemsInternalIds = orderData.map((order) => order.iteminternalid);
      //array that we will use to send the request to fulfill TO
      const fulfillmentItems = [];

      //get pick path
      const pathResponse = await axios.post(
        `netsuite/post/pick-order/order/${soNumber}`,
        {
          currentNetsuiteLocationIds,
          userSubsidiary: currentSubsidiary,
        }
      );
      //sort by OZ_Receiving_Bins
      const filteredPath = pathResponse.data?.filter(
        (order) =>
          order.binnumber?.includes("OZ_RECEIVING_") &&
          ToItemsInternalIds.includes(order.lineiteminternalid)
      );

      //internal id of sales order
      const internalid = filteredPath?.length
        ? parseInt(filteredPath[0]?.internalid)
        : null;

      //loop through TO items and match up with SO items and check quantities
      for (const item of orderData) {
        //check for quantities
        const matches = filteredPath.filter(
          (order) => order.lineiteminternalid === item.iteminternalid
        );

        if (matches.length === 0) {
          if (!item.iteminternalid?.includes("QTYROW-")) {
            //throw no item error
            throw new Error(
              `${item.itemname} is not fully committed on sales order or has already been picked`
            );
          }
        } else if (matches.length > 1) {
          //extra logic to check qty and responses
          //add up total order qty
          const totalLineItemQtyRemaining = matches.reduce(
            (accumulator, item) => {
              return accumulator + parseInt(item.lineitemqtyremaining);
            },
            0
          );

          //this check ensures there is enough quantity in matches for the item toQty
          if (
            parseInt(totalLineItemQtyRemaining) <
            parseInt(item.quantityreceived)
          ) {
            //throw error. Not enough quantity
            throw new Error(
              `${item.itemname} receipt quantity (${item.quantityreceived}) exceeds sales order quantity (${totalLineItemQtyRemaining})`
            );
          } else {
            let toQty = parseInt(item.quantityreceived);
            let index = 0;
            //we will deduct from toQty until its 0
            while (toQty !== 0) {
              const orderItem = matches[index];
              //if toQty is bigger, use order qty and then subtract
              if (toQty >= parseInt(orderItem.lineitemqtyremaining)) {
                fulfillmentItems.push({
                  itemreceive: true,
                  orderLine: parseInt(orderItem.lineid),
                  binnumbers: `OZ_RECEIVING_${orderItem.locationinternalid}`,
                  quantity: parseInt(orderItem.lineitemqtyremaining),
                });
                //subtract from toQty
                toQty -= parseInt(orderItem.lineitemqtyremaining);
              } else {
                //order qty is bigger. Use toQty and then set to 0
                fulfillmentItems.push({
                  itemreceive: true,
                  orderLine: parseInt(orderItem.lineid),
                  binnumbers: `OZ_RECEIVING_${orderItem.locationinternalid}`,
                  quantity: parseInt(toQty),
                });

                toQty = 0;
              }
              //increase index for matches array
              index++;
            }
          }
        } else {
          //eaxctly one match
          //check quantities and add to fulfillment items
          const orderItem = matches[0];

          if (
            parseInt(orderItem.lineitemqtyremaining) <
            parseInt(item.quantityreceived)
          ) {
            //throw error
            throw new Error(
              `${item.itemname} receipt quantity (${item.quantityreceived}) exceeds sales order quantity (${orderItem.lineitemqtyremaining})`
            );
          } else {
            fulfillmentItems.push({
              itemreceive: true,
              orderLine: parseInt(orderItem.lineid),
              binnumbers: `OZ_RECEIVING_${orderItem.locationinternalid}`,
              quantity: parseInt(item.quantityreceived),
            });
          }
        }
      }

      const firstpicktime = new Date();
      const lastpicktime = new Date();

      //use call to fulfill pick
      await axios.post(
        `netsuite/post/pick-order/order/${internalid}/partial-fulfill`,
        {
          externalid: `${soNumber}_${toNumber}_${orderData[0].receiptid}`,
          username,
          items: fulfillmentItems,
          trantype: "salesorder",
          tranid: soNumber,
          _id: null,
          lastpicktime,
          lastpickedby: username,
          firstpicktime,
          pacejetstation: pacejetstation ? pacejetstation : null,
          custbody_ait_so_to_related_fulfill: true,
        }
      );

      openAlert({
        type: "success",
        message: `Successfully Fulfilled SO`,
        duration: 5000,
      });
      //either navigates to origin page or resets data
      if (originalPage) {
        navigate(originalPage);
      } else {
        //reset data
        handleResetdata();
      }
    } catch (error) {
      //any errors then open error Modal
      setError({
        type: "pick",
        message: error?.response?.data?.msg || error.message,
      });
    } finally {
      setLoading("");
    }
  };

  if (Boolean(loading)) {
    return <Loading message={loading} />;
  }

  if (error.type === "pick" && Boolean(error.message)) {
    return (
      <Dialog open disableEscapeKeyDown>
        <DialogTitle sx={{ fontWeight: "bold" }}>Pick Error</DialogTitle>
        <DialogContent>
          <Box sx={{ p: 2, borderRadius: 1, backgroundColor: "#f9f9f9" }}>
            <Typography color="error" variant="h6">
              {error.message}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setError({ type: "", message: "" })}
            color="error"
            variant="contained"
            sx={{ fontWeight: "bold" }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Container maxWidth="md">
      <Typography
        variant="h4"
        textAlign="center"
        py={1}
        gutterBottom={!orderData.length}
      >
        Pick TO onto SO
      </Typography>

      {orderData?.length ? (
        <Box>
          {/* <Button
            size="small"
            variant="outlined"
            endIcon={<Refresh />}
            onClick={handleResetdata}
            sx={{ mb: 1, py: 0 }}
          >
            Reset Search
          </Button> */}
          <Stack gap={1}>
            <FlexWrapper justifyContent="space-between" alignItems="baseline">
              <Typography>
                <strong>TO#:</strong> {orderData[0].tonumber}
              </Typography>
              <Typography>
                <strong>SO#:</strong> {orderData[0].sonumber}
              </Typography>
            </FlexWrapper>
            <FlexWrapper justifyContent="space-between" alignItems="baseline">
              <Typography>
                <strong>Receipt:</strong> {orderData[0].receiptid} (
                {orderData[0].datereceived})
              </Typography>
            </FlexWrapper>
            <Typography gutterBottom>
              <strong>Customer:</strong> {orderData[0].customer}
            </Typography>
          </Stack>

          <DataGrid
            sx={{ height: "59vh" }}
            rows={orderData}
            columns={PICKTOONTOSOCOLUMNS}
            getRowId={(row) => row.iteminternalid}
            disableRowSelectionOnClick
            density="compact"
          />
          <Button
            onClick={handleAutoPick}
            fullWidth
            variant="contained"
            color="success"
            sx={{ mt: 1 }}
          >
            Confirm Details and Auto Pick
          </Button>
        </Box>
      ) : (
        <form onSubmit={(event) => handleToSubmit(event, null)}>
          <TextField
            autoFocus
            required
            fullWidth
            label="Enter TO Number"
            value={toNumber}
            onChange={(event) =>
              setToNumber(event.target.value?.trim()?.toUpperCase())
            }
            InputProps={{
              endAdornment: (
                <IconButton type="submit">
                  <Search />
                </IconButton>
              ),
            }}
            error={error.type === "search" && error.message !== ""}
            helperText={
              error.type === "search" && error.message !== ""
                ? error.message
                : ""
            }
          />
        </form>
      )}
    </Container>
  );
};

export default PickToOntoSoPage;
