import { useEffect, useState } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { PRINTNODELOGSCOLUMNS } from "../../../utils/columns/admin/adminColumns";
import {
  useLoaderData,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";

import axios from "../../../axios/axios.config";

const AdminPrintnodeLogsView = () => {
  const printers = useLoaderData();
  const [printnodeLogs, setPrintnodeLogs] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { loading, setLoading, handleAlert } = useOutletContext();

  const paramFilter = searchParams.get("filter") || "all"; //default to all if no params set

  useEffect(() => {
    getPrintnodeLogsData();
  }, [searchParams]);

  const getPrintnodeLogsData = async () => {
    setLoading(true);
    try {
      let printnodeResponse = null;

      if (!paramFilter || paramFilter === "all") {
        printnodeResponse = await axios.get(
          "https://api.printnode.com/printjobs?limit=250",
          {
            auth: { username: process.env.REACT_APP_PRINT_API_ID },
          }
        );
      } else {
        printnodeResponse = await axios.get(
          `https://api.printnode.com/printers/${paramFilter}/printjobs?limit=250`,
          {
            auth: { username: process.env.REACT_APP_PRINT_API_ID },
          }
        );
      }

      setPrintnodeLogs(handleAddPNPName(printnodeResponse?.data));
    } catch (error) {
      handleAlert({
        type: "error",
        message: `Could not get data: ${
          error?.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChanges = (event) => {
    setSearchParams({ filter: event.target.value });
  };

  const handleAddPNPName = (printnodeData) => {
    if (!printnodeData?.length) return printnodeData;

    const printersMap = Object.fromEntries(
      printers.map((printer) => [printer.printnodeid, printer.printername])
    );

    return printnodeData?.map((data) => ({
      ...data,
      pnpname: printersMap[data?.printer?.id],
    }));
  };

  return (
    <Box width="100%">
      <FormControl size="small">
        <InputLabel id="select-label-filter-printnode">Filter Jobs</InputLabel>
        <Select
          labelId="select-label-filter-printnode"
          id="simple-select-filter-printnode"
          value={paramFilter}
          onChange={handleFilterChanges}
          label="Filter Jobs"
        >
          <MenuItem value="all">All Jobs</MenuItem>
          {printers?.length > 0 &&
            printers.map((printer) => (
              <MenuItem key={printer?._id} value={printer?.printnodeid}>
                {printer?.printername}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <DataGrid
        sx={{ bgcolor: "white", height: "84vh", width: "100%", mt: 1 }}
        rows={printnodeLogs}
        columns={PRINTNODELOGSCOLUMNS}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 100 },
          },
        }}
        pageSizeOptions={[100, 150, 200]}
        density="compact"
        getRowId={(row) => row.id}
        slots={{ toolbar: GridToolbar }}
        disableDensitySelector
        disableColumnSelector
        slotProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true,
            csvOptions: {
              fileName: `printnode-logs-${Date.now()}`,
            },
          },
        }}
        loading={loading}
      />
    </Box>
  );
};
export default AdminPrintnodeLogsView;
