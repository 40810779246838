import { Link, Outlet, useLocation } from "react-router-dom";
import { APP_VERSION } from "../../utils/constants/version";
import { useEC2 } from "../../hooks/ec2/useEC2";
import { LockOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Container,
  Typography,
  Link as MaterialLink,
} from "@mui/material";

import AITLOGO from "../../assets/AIT-LOGO.png";

function Copyright({ instanceId }) {
  const awsEnvironmentMap = {
    "i-0f6812906cffee90f": "USW1",
    "i-017ecbd86a9d3fb34": "USW2",
    "i-028127630bceac6d7": "USE2",
    "i-0af4034ab35349955": "EUC1",
  };

  return (
    <Typography variant="body2" color="text.secondary" align="center">
      Copyright © Advanced Innovative Technology
      {" " + new Date().getFullYear()} <br />
      {APP_VERSION}
      {`${
        process.env.REACT_APP_NODE_ENV &&
        process.env.REACT_APP_NODE_ENV !== "PRODUCTION"
          ? ` - ${process.env.REACT_APP_NODE_ENV}`
          : ""
      }`}{" "}
      <br />
      {process.env.REACT_APP_NODE_ENV !== "LOCAL"
        ? `${instanceId} (${awsEnvironmentMap[instanceId]})`
        : ""}
    </Typography>
  );
}

const AuthLayout = () => {
  const location = useLocation();
  const { instanceId } = useEC2();

  return (
    <Container component="main" maxWidth="xs">
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "350px", md: "450px" },
          }}
        >
          <img
            src={AITLOGO}
            alt="Advanced Innovative Technology"
            width="100%"
          />
        </Box>
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlined />
        </Avatar>
      </Container>
      <Outlet />
      <Link to={location.pathname === "/" ? "register" : "/"}>
        <MaterialLink component="div">
          <Typography textAlign="right" variant="body2" gutterBottom>
            {location.pathname === "/"
              ? "Don't Have an Account? Register Here"
              : "Already Have an Account? Log In Here"}
          </Typography>
        </MaterialLink>
      </Link>
      <Copyright instanceId={instanceId} />
    </Container>
  );
};
export default AuthLayout;
