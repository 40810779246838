import { useState } from "react";
import { Cancel, RestartAlt, Search } from "@mui/icons-material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../../FlexWrapper";
import axios from "../../../../axios/axios.config";
import Loading from "../../../Loading";

const CreateCycleCountsFullscreenModal = ({
  open,
  handleClose,
  setJobsData,
}) => {
  const [itemInput, setItemInput] = useState("");
  const [itemData, setItemData] = useState([]);
  const [loading, setLoading] = useState("");
  const [submitError, setSubmitError] = useState("");
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [jobNameInput, setJobNameInput] = useState("");
  const [startingBin, setStartingBin] = useState("");
  const [endingBin, setEndingBin] = useState("");

  const apiRef = useGridApiRef();
  const user = useSelector((state) => state.user);
  const { openAlert } = useGeneralAlert();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (open === "Item") {
      handleItemSubmit();
    } else {
      handleBinSubmit();
    }
  };

  const handleItemSubmit = async () => {
    setLoading("Getting Data...");
    setSubmitError("");
    setSelectedJobs([]);

    try {
      const response = await axios.get(
        `netsuite/cycle-counts/get/item/cycle-counts?item=${itemInput}&nsLocations=${user.currentNetsuiteLocationIds.join(
          ","
        )}`
      );

      if (response.data?.length) {
        const formattedData = response.data.map((obj, index) => {
          return {
            id: index,
            itemData: {
              iteminternalid: parseInt(obj.iteminternalid),
              itemname: obj.itemname,
              itemdescription: obj.itemdescription,
              itemupc: obj.itemupc,
              itembinnumber: obj.binnumber,
              itemlocationinternalid: parseInt(obj.binlocationid),
              binlocationname: obj.binlocationname,
              itemaddtlupc: Boolean(obj.itemaddtlupc)
                ? obj.itemaddtlupc?.split("|")
                : [],
            },
          };
        });
        setItemData(formattedData);
        setItemInput("");
      }
    } catch (error) {
      setSubmitError(error.response?.data?.msg || error.message);
    } finally {
      setLoading("");
    }
  };

  const handleBinSubmit = async () => {
    setLoading("Getting Data...");
    setSubmitError("");
    setSelectedJobs([]);

    try {
      const response = await axios.get(
        `netsuite/cycle-counts/get/bin-range/cycle-counts?startingBin=${startingBin}&endingBin=${endingBin}&nsLocations=${user.currentNetsuiteLocationIds.join(
          ","
        )}`
      );

      if (response.data?.length) {
        const formattedData = response.data.map((obj, index) => {
          return {
            id: index,
            itemData: {
              iteminternalid: parseInt(obj.iteminternalid),
              itemname: obj.itemname,
              itemdescription: obj.itemdescription,
              itemupc: obj.itemupc,
              itembinnumber: obj.binnumber,
              itemlocationinternalid: parseInt(obj.binlocationid),
              binlocationname: obj.binlocationname,
              itemaddtlupc: Boolean(obj.itemaddtlupc)
                ? obj.itemaddtlupc?.split("|")
                : [],
            },
          };
        });
        setItemData(formattedData);
        setItemInput("");
      }
    } catch (error) {
      setSubmitError(error.response?.data?.msg || error.message);
    } finally {
      setLoading("");
    }
  };

  const handleCreateCycleCounts = async () => {
    try {
      const itemsSet = new Set();
      const binsSet = new Set();
      //create overall jobs data
      const jobsResponse = await axios.post("cycle-counts-jobs/create", {
        jobsData: {
          createdby: user._id,
          name: jobNameInput,
          whselocation: user.currentLocation,
          itemlocation: selectedJobs[0].itemData.itemlocationinternalid,
          creationtype: open === "Item" ? "item" : "bin",
        },
      });

      const individualJobArr = selectedJobs.map((data) => {
        //save item name and bin number to set to get unique count
        itemsSet.add(data.itemData["itemname"]);
        binsSet.add(data.itemData["itembinnumber"]);

        return {
          itemData: data.itemData,
          originaljob: jobsResponse.data._id,
          currentlinkedjob: jobsResponse.data._id,
        };
      });

      //create line item job data and get the ids
      const lineItemsResponse = await axios.post(
        "cycle-counts-line-items/create-many",
        {
          jobData: individualJobArr,
        }
      );

      //add individual job ids to overall jobs and sum unique bins/items
      await axios.patch(
        `cycle-counts-jobs/update/${jobsResponse.data._id}/jobs`,
        {
          jobsArray: lineItemsResponse.data, //array of job ids
          uniqueBins: binsSet.size, //count of unique bins to add
          uniqueItems: itemsSet.size, //count of unique items to add
        }
      );

      //get new jobs
      const finalResponse = await axios.get(
        `cycle-counts-jobs/get/status/new?location=${user.currentLocation}`
      );

      setJobsData(finalResponse.data);
      handleItemReset();
      handleClose();
      openAlert({
        type: "success",
        message: "Successfully Created Job",
        duration: 3000,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Create Job: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    }
  };

  const handleItemReset = () => {
    setItemData([]);
    setItemInput("");
    setJobNameInput("");
    setSelectedJobs([]);
    setEndingBin("");
    setStartingBin("");
    setSubmitError("");
  };

  return (
    <Dialog
      open={Boolean(open)}
      onClose={handleClose}
      fullScreen
      disableEscapeKeyDown
    >
      <Container maxWidth="md">
        <FlexWrapper justifyContent="flex-end">
          <IconButton
            onClick={() => {
              handleClose();
              handleItemReset();
            }}
          >
            <Cancel color="error" />
          </IconButton>
        </FlexWrapper>
        <DialogTitle textAlign="center">
          Create Job By {open === "Item" ? "Item" : "Bin Range"}
        </DialogTitle>
        {Boolean(open) && (
          <DialogContent>
            {itemData.length === 0 && (
              <FlexWrapper
                justifyContent="center"
                alignItems="center"
                gap={1}
                width="100%"
              >
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  {open === "Item" ? (
                    <TextField
                      autoFocus={true}
                      required
                      error={submitError}
                      helperText={submitError}
                      margin="dense"
                      label="Enter Item"
                      variant="outlined"
                      fullWidth
                      value={itemInput}
                      onChange={(event) => {
                        const itemSplit = event.target.value
                          ?.toUpperCase()
                          ?.split("*");
                        const item = itemSplit[0];
                        setItemInput(item);
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton type="submit">
                            <Search />
                          </IconButton>
                        ),
                      }}
                    />
                  ) : (
                    <>
                      <FlexWrapper
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        gap={1}
                      >
                        <TextField
                          autoFocus
                          required
                          margin="dense"
                          label="Enter Starting Bin"
                          value={startingBin}
                          onChange={(event) =>
                            setStartingBin(event.target.value.toUpperCase())
                          }
                          variant="outlined"
                        />

                        <TextField
                          required
                          margin="dense"
                          label="Enter Ending Bin"
                          value={endingBin}
                          onChange={(event) =>
                            setEndingBin(event.target.value.toUpperCase())
                          }
                          variant="outlined"
                        />
                        {Boolean(startingBin) && Boolean(endingBin) && (
                          <Button
                            type="submit"
                            variant="contained"
                            disabled={Boolean(loading)}
                          >
                            Submit
                          </Button>
                        )}
                        {submitError ? (
                          <Typography variant="caption" color="error">
                            {submitError}
                          </Typography>
                        ) : null}
                      </FlexWrapper>
                    </>
                  )}
                </form>
              </FlexWrapper>
            )}
            <Box>
              {loading && <Loading message={loading} height="50vh" />}

              {!loading && Boolean(itemData.length) && (
                <Box py={2}>
                  <FlexWrapper justifyContent="space-between">
                    <FlexWrapper alignItems="center" gap={1}>
                      <Tooltip title="Reset" arrow placement="top">
                        <IconButton onClick={handleItemReset}>
                          <RestartAlt color="primary" />
                        </IconButton>
                      </Tooltip>

                      <TextField
                        disabled={!selectedJobs.length}
                        label="Enter Job Name"
                        size="small"
                        type="string"
                        value={jobNameInput}
                        onChange={(event) =>
                          setJobNameInput(event.target.value.toUpperCase())
                        }
                      />
                    </FlexWrapper>

                    <Button
                      disabled={!Boolean(jobNameInput)}
                      color="success"
                      variant="contained"
                      sx={{ mb: 1 }}
                      onClick={handleCreateCycleCounts}
                    >
                      Create Job
                    </Button>
                  </FlexWrapper>

                  <DataGrid
                    apiRef={apiRef}
                    checkboxSelection
                    sx={{ height: "74vh" }}
                    rows={itemData}
                    columns={[
                      {
                        field: "bin",
                        headerName: "Bin",
                        type: "string",
                        headerAlign: "center",
                        align: "center",
                        flex: 1,
                        sortable: true,
                        valueGetter: (params) => {
                          return params.row?.itemData?.itembinnumber;
                        },
                      },
                      {
                        field: "name",
                        headerName: "Name",
                        type: "string",
                        headerAlign: "center",
                        align: "center",
                        flex: 1,
                        sortable: true,
                        valueGetter: (params) => {
                          return params.row?.itemData?.itemname;
                        },
                      },
                      {
                        field: "description",
                        headerName: "Description",
                        type: "string",
                        headerAlign: "center",
                        align: "center",
                        flex: 1,
                        sortable: false,
                        valueGetter: (params) => {
                          return params.row?.itemData?.itemdescription;
                        },
                      },
                      {
                        field: "upc",
                        headerName: "UPC",
                        type: "string",
                        headerAlign: "center",
                        align: "center",
                        flex: 1,
                        sortable: true,
                        valueGetter: (params) => {
                          return params.row?.itemData?.itemupc;
                        },
                      },
                      {
                        field: "location",
                        headerName: "Location",
                        type: "string",
                        headerAlign: "center",
                        align: "center",
                        flex: 1,
                        sortable: true,
                        valueGetter: (params) => {
                          return params.row?.itemData?.binlocationname;
                        },
                      },
                    ]}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 100 },
                      },
                      sorting: {
                        sortModel: [{ field: "bin", sort: "asc" }],
                      },
                    }}
                    pageSizeOptions={[25, 50, 100]}
                    disableDensitySelector
                    disableColumnSelector
                    disableRowSelectionOnClick
                    getRowId={(row) => row.id}
                    onRowSelectionModelChange={(rows) => {
                      const selectedRowsData = rows.map((id) =>
                        apiRef.current.getRow(id)
                      );

                      setSelectedJobs(selectedRowsData);
                    }}
                  />
                </Box>
              )}
            </Box>
          </DialogContent>
        )}
      </Container>
    </Dialog>
  );
};
export default CreateCycleCountsFullscreenModal;
