import { useEffect, useState } from "react";
import { Container, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { FULLBINSCONSOLIDATIONREPORTCOLUMNSV2 } from "../../../../utils/columns/reports/reportsColumns";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import { useSelector } from "react-redux";

import axios from "../../../../axios/axios.config";
import Loading from "../../../../components/Loading";

const FullPalletBinsConsolidationReportV2 = () => {
  const [loading, setLoading] = useState("Loading Report...");
  const [reportData, setReportData] = useState([]);

  const { currentNetsuiteLocationIds } = useSelector((state) => state.user);
  const { openAlert } = useGeneralAlert();

  useEffect(() => {
    getReportData();
  }, []);

  const getReportData = async () => {
    try {
      const response = await axios.get(
        `netsuite/inventory-reports/get/full-pallet-bins-consolidation-v2?nsLocations=${currentNetsuiteLocationIds?.join(
          ","
        )}`
      );

      setReportData(findBinPairs(response.data));
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not get report data: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 5000,
      });
    } finally {
      setLoading("");
    }
  };

  const findBinPairs = (binArray) => {
    const result = [];
    const processedItems = new Set();

    for (let i = 0; i < binArray.length; i++) {
      const startingBin = binArray[i];

      // Skip if this item has already been processed
      if (
        processedItems.has(`${startingBin.itemname}-${startingBin.binbrand}`)
      ) {
        continue;
      }

      let binAvailableQty = startingBin.binavailable;
      let currentBinPairs = [];
      let totalSpaceRemaining = 0;

      for (let j = i + 1; j < binArray.length; j++) {
        const potentialBin = binArray[j];

        // Only pair bins with the same itemname and brand
        if (
          potentialBin.itemname === startingBin.itemname &&
          potentialBin?.binbrand === startingBin.binbrand
        ) {
          const neededQty = Math.min(
            binAvailableQty,
            potentialBin.binspaceremaining
          );
          currentBinPairs.push(`${potentialBin.binnumber} (${neededQty})`);

          totalSpaceRemaining += potentialBin.binspaceremaining;
          binAvailableQty -= neededQty;

          // Stop adding bins once the total space matches or exceeds binAvailableQty
          if (binAvailableQty <= 0) {
            break;
          }
        }
      }

      // Ensure pairs meet the condition of totalSpaceRemaining >= startingBin.binavailable
      if (
        currentBinPairs.length > 0 &&
        totalSpaceRemaining >= startingBin.binavailable
      ) {
        // Construct the final object for this item
        result.push({
          brand: startingBin.binbrand,
          item: startingBin.itemname,
          numberOfBins: currentBinPairs.length + 1,
          palletQty: startingBin.itempalletqty,
          bins: `${startingBin.binnumber} (${
            startingBin.binavailable
          }) --> ${currentBinPairs?.join(" ~ ")}`,
          startingavailable: startingBin.binavailable,
        });
      }

      // Mark this item as processed
      processedItems.add(`${startingBin.itemname}-${startingBin.binbrand}`);
    }

    return result;
  };

  if (Boolean(loading)) {
    return <Loading message={loading} />;
  }

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" textAlign="center">
        Full Pallet Bins Consolidation
      </Typography>

      <DataGrid
        sx={{ height: "85vh" }}
        rows={reportData}
        columns={FULLBINSCONSOLIDATIONREPORTCOLUMNSV2}
        getRowId={(row) => row.bins}
        slots={{
          toolbar: GridToolbar,
        }}
        disableColumnSelector
        disableDensitySelector
        density="compact"
        slotProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true,
            csvOptions: {
              fileName: `full-pallet-bins-consolidation-${Date.now()}`,
            },
          },
        }}
      />
    </Container>
  );
};
export default FullPalletBinsConsolidationReportV2;
