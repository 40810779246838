import axios from "../../../axios/axios.config";

export const printnodeLogsLoader = async () => {
  try {
    const printerResponse = await axios.get("printers");
    const filteredPrinters = printerResponse.data.filter(
      (printer) => !printer.printername?.startsWith("USB")
    );
    return filteredPrinters;
  } catch (error) {
    return null;
  }
};
