import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { LOGINLOGCOLUMNS } from "../../../utils/columns/admin/adminColumns";
import { useOutletContext } from "react-router-dom";

import axios from "../../../axios/axios.config";

const AdminLoginLogsView = () => {
  const [loginLogs, setLoginLogs] = useState([]);
  const { loading, setLoading, handleAlert } = useOutletContext();

  useEffect(() => {
    getCronLogsData();
  }, []);

  const getCronLogsData = async () => {
    setLoading(true);
    try {
      const loginLogsResponse = await axios.get("login-log/all");
      setLoginLogs(loginLogsResponse.data);
    } catch (error) {
      handleAlert({
        type: "error",
        message: `Could not get data:${
          error?.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box width="100%">
      <DataGrid
        sx={{ bgcolor: "white", height: "91vh", width: "100%" }}
        rows={loginLogs}
        columns={LOGINLOGCOLUMNS}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 100 },
          },
        }}
        pageSizeOptions={[100, 150, 200]}
        density="compact"
        disableColumnMenu
        getRowId={(row) => row._id}
        slots={{ toolbar: GridToolbar }}
        disableDensitySelector
        disableColumnSelector
        slotProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true,
            csvOptions: {
              fileName: `loginlogs-${Date.now()}`,
            },
          },
        }}
        loading={loading}
      />
    </Box>
  );
};
export default AdminLoginLogsView;
