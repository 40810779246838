import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExitToApp, Person } from "@mui/icons-material";
import { sendGeneralEmail } from "../../../utils/email/generalEmailFunction";
import { pdf } from "@react-pdf/renderer";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../FlexWrapper";
import Loading from "../../Loading";
import GeneralModalV3 from "../../general/GeneralModalV3";
import axios from "../../../axios/axios.config";
import CreatedByLC from "./CreatedByLC";
import AttachmentsLC from "./AttachmentsLC";
import NotesLC from "./NotesLC";
import MarkAsPendingDocumentsButton from "./MarkAsPendingDocumentsButton";
import LaborClaimRMAPlacard from "../../PDF/multi-page/LaborClaimRMAPlacard";

const LaborClaimHeader = ({
  setLoading = null,
  form,
  setForm,
  fileURL,
  createdby,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { formid } = useParams();
  const user = useSelector((state) => state.user);

  const handleAlert = ({ type, message, duration }) => {
    dispatch(openGeneralAlert({ type, message, duration }));
  };

  const handleMarkAsNew = async () => {
    if (setLoading) {
      setLoading("Updating Status To New...");
    }
    try {
      await axios.patch(`forms/update/no-version/${formid}`, {
        updateData: {
          "form.status": "new",
          "form.rmanotes": `${form.rmanotes}\n${new Date().toLocaleString()} (${
            user.username
          }) - Marked As New`,
        },
      });

      navigate(
        `/portal/${user.currentDepartment.toLowerCase()}/forms/labor-claim`
      );

      handleAlert({
        type: "success",
        message: `Successfully updated labor claim`,
        duration: 5000,
      });
    } catch (error) {
      handleAlert({
        type: "error",
        message: `Could not update labor claim status: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 5000,
      });
    } finally {
      if (setLoading) {
        setLoading("");
      }
    }
  };

  const handleMarkAsIncomplete = async () => {
    if (setLoading) {
      setLoading("Updating Status To Incomplete...");
    }
    try {
      await axios.patch(`forms/update/no-version/${formid}`, {
        updateData: {
          "form.status": "incomplete",
          "form.closedreason": "Never received requested documents",
          "form.closedbyname": `${user.firstname} ${user.lastname}`,
          "form.closedbyusername": `${user.username}`,
          "form.closedbyemail": `${user.email}`,
          "form.closeddate": `${new Date().toLocaleString()}`,
          "form.rmanotes": `${form.rmanotes}\n${new Date().toLocaleString()} (${
            user.username
          }) - Marked As Incomplete`,
        },
      });

      navigate(
        `/portal/${user.currentDepartment.toLowerCase()}/forms/labor-claim`
      );

      handleAlert({
        type: "success",
        message: `Successfully updated labor claim`,
        duration: 5000,
      });
    } catch (error) {
      handleAlert({
        type: "error",
        message: `Could not update labor claim status: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 5000,
      });
    } finally {
      if (setLoading) {
        setLoading("");
      }
    }
  };

  const generateRma = async () => {
    if (setLoading) {
      setLoading("Generating RMA...");
    }

    try {
      const rmaIssueDate = new Date().toLocaleString();
      let additionalEmails = form.additionalEmails;
      //convert to an array if needed
      if (additionalEmails && !Array.isArray(additionalEmails)) {
        additionalEmails = additionalEmails.trim().split(",");
      }

      let partTotalQty = form.partNumbers.reduce(
        (total, item) => total + item.qty,
        0
      );

      const partNums = form.partNumbers
        .map((partObj) =>
          form.partNumbers.length > 1 && partTotalQty > 2
            ? `${partObj.partnumber}(${partObj.qty})`
            : partObj.partnumber
        )
        .join(" & ");

      const { rgaNum, totalClaim, customerinternalid } = form;

      //update notes to log rma issue
      const newNotes = form.rmanotes
        ? `${form.rmanotes}\n${rmaIssueDate} (${user.username}) - Issued RMA`
        : "";

      const laborClaimCreateRMAResponse = await axios.post(
        "netsuite/post/labor-claim/create/rma",
        {
          partNumbers: partNums,
          partTotalQty,
          externalid: `RMA-LC ${rgaNum}`,
          customerInternalId: customerinternalid,
          otherrefnum: `LC ${rgaNum}`,
          totalClaim: parseFloat(totalClaim),
          rmaNotes: newNotes || "",
          userNetsuiteInternalId: user.netsuiteinternalid,
        }
      );

      const { rmaNum, rmainternalid } = laborClaimCreateRMAResponse.data;

      //TODO write api to update the data on mongodb

      const updateData = {
        "form.rmaissuedby": user.username,
        "form.rmaNum": rmaNum,
        "form.rmainternalid": rmainternalid,
        "form.rmaissuedbyname": `${user.firstname} ${user.lastname}`,
        "form.rmaissuedbyemail": user.email,
        "form.rmaissuedate": rmaIssueDate,
        "form.rmaissueddepartment": user.currentDepartment,
        "form.status": "rmaissued",
      };

      const { data } = await axios.patch(
        `forms/update/labor-claim/${formid}/returns/issue-rma`,
        {
          updateData,
        }
      );

      handleSendRMAEmail(data);

      navigate(
        `/portal/${user.currentDepartment.toLowerCase()}/forms/labor-claim`
      );

      handleAlert({
        type: "success",
        message: `Successfully created RMA: ${data.form.rmaNum}`,
        duration: 3000,
      });
    } catch (error) {
      handleAlert({
        type: "error",
        message: `Could not update labor claim status: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 5000,
      });
    } finally {
      if (setLoading) {
        setLoading("");
      }
    }
  };

  const handleSendRMAEmail = async (data) => {
    try {
      const attachments = [];
      //if it has an internalid then its a netsuite RMA else its a WRMA
      if (data.form.rmainternalid) {
        //generate pdf from netsuite
        const nsPDFResponse = await axios.get(
          `netsuite/labor-claim/get/rma/internalid/${data.form.rmaNum}`
        );

        attachments.push({
          content: nsPDFResponse.data.data64,
          filename: `${data.form.rmaNum}-AUTH.pdf`, // Set the original file name as the attachment filename
          type: "application/pdf", // Set the file's mimetype
          disposition: "attachment", // Set the disposition as attachment
        });
      }

      const rmaPlacardBlob = await pdf(
        <LaborClaimRMAPlacard rmaNumber={data.form.rmaNum} />
      ).toBlob();

      // Convert the first blob to base64 string
      const rmaPlacardBase64 = await convertBlobToBase64(rmaPlacardBlob);

      // Now you have rmaPlacardBase64 and base64String2 for your attachments
      attachments.push({
        content: rmaPlacardBase64,
        filename: `LC-${data.form.rmaNum.replace("RMA", "RMA ")}-PLACARD.pdf`,
        type: "application/pdf",
        disposition: "attachment",
      });

      const fileKeys = data.form.file.map(
        (filename) => `forms/laborclaim/${formid}/${filename}`
      );
      const fileURLResponse = await axios.post("files/multi/signedURL", {
        options: { expiresIn: 3600 },
        fileKeys,
      });

      const fileURL = fileURLResponse.data;

      //get attachment objects from S3 files
      for (let i = 0; i < fileURL.length; i++) {
        const attachment = await getAttchmentObjects(fileURL[i], i);
        attachments.push(attachment);
      }

      await sendGeneralEmail({
        to: process.env.REACT_APP_TEST_EMAIL || data.form.warehouseEmail,
        from: "returns@trakmotive.com",
        fromname: "AIT Returns",
        cc: process.env.REACT_APP_TEST_EMAIL
          ? ""
          : data.form.additionalEmails || "",
        bcc: process.env.REACT_APP_TEST_EMAIL ? "" : "returns@trakmotive.com",
        subject: `${
          data.form.rmaNum.startsWith("W")
            ? `RMA# ${data.form.rmaNum} Issued`
            : data.form.rmaNum.replace("RMA", "RMA# ")
        } Issued`,
        body: `<p>***When shipping Labor Claims to our warehouse please use attached RMA Placard per each Box. If the box(es) do not include the attached placard they will be refused.</br></br>
          Please see the attached Return Material Authorization form, reference ${data.form.rmaNum.replace(
            "RMA",
            "RMA# "
          )} for the labor claim.</br></br>
          Have the part and the original documentation sent to the below address:</br></p>
          <address>Advanced Innovative Technology Corp.</br>
                  350 Nevada St.</br>
                  Redlands, CA 92373</br>
                  Attn: ${data.form.rmaNum.replace("RMA", "RMA# ")}-TECH</br>
                  Tel: (909) 253-0077 Fax: (909) 253-0080</br></br>
          </address>

          <p>Operating Hours: Mon - Fri – 7am to 2pm </br></br>
            If you have any questions, please do not hesitate to contact me.</br></br>
            Thank You,
          </p>
          <p class=MsoNormal><b><i><span style='font-size:12.0pt;font-family:"Georgia",serif;color:#1F3864;mso-ligatures:none'>${
            user.firstname
          } ${
          user.lastname
        }<o:p></o:p></span></i></b></p><p class=MsoNormal><i><span style='font-size:12.0pt;font-family:"Georgia",serif;color:#2F5496;mso-ligatures:none'>Returns Clerk<o:p>
          <p class=MsoNormal><b><span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>Advanced Innovative Technology Corp.</span></b><b>
          <span style='font-family:"Georgia",serif;color:#1F497D;mso-ligatures:none'><o:p></o:p></span></b></p><p class=MsoNormal><span style='font-size:10.0pt;font-family:"Georgia",serif;mso-ligatures:none'>350 Nevada St. Redlands, CA 92373<o:p></o:p></span></p>
          <p class=MsoNormal><b><span style='font-size:10.0pt;font-family:"Georgia",serif;color:#2F5496;mso-ligatures:none'>
          <a href="mailto:${user.email}"><span style='color:#2F5496'>${
          user.email
        }</span></a>
          <o:p></o:p></span></b></p><p class=MsoNormal><span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>O 909-253-0077 Ext:120<o:p></o:p></span></p>
          <p class=MsoNormal><span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>F 417-831-9447<o:p></o:p></span></p>
          <p class=MsoNormal><a href="http://www.trakmotive.com/"><span style='font-size:10.5pt;font-family:"Georgia",serif;mso-ligatures:none;text-decoration:none'><img border=0 width=183 height=53 style='width:1.9062in;height:.552in' id="Picture_x0020_11" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/trakmotive.png"></span></a>
          <span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'><o:p></o:p></span></p><p class=MsoNormal><b><i><span style='font-family:"Georgia",serif;mso-ligatures:none'>Our Job Is To Make Your Job Easier<o:p></o:p></span></i></b></p>
          <p class=MsoNormal><b><i><span style='font-family:"Georgia",serif;mso-ligatures:none'><o:p>&nbsp;</o:p></span></i></b></p><p class=MsoNormal><a href="https://www.facebook.com/trakmotive/"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_12" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/facebook.png"></span></a>
          <span style='font-family:"Georgia",serif;mso-ligatures:none'>&nbsp; </span><a href="https://twitter.com/TrakMotiveAxles"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_13" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/twitter.png"></span></a><span style='font-family:"Georgia",serif;mso-ligatures:none'>&nbsp;&nbsp;</span>
          <a href="https://www.linkedin.com/company/advanced-innovative-technology-corp-/"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_14" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/linkedin.png"></span></a><span style='font-family:"Georgia",serif;mso-ligatures:none'>&nbsp;&nbsp;</span>
          <a href="https://www.instagram.com/trakmotive/?hl=en"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_15" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/instagram.png"></span></a><span style='font-family:"Georgia",serif;mso-ligatures:none'><o:p></o:p></span></p><p class=MsoNormal><span style='font-family:"Georgia",serif;mso-ligatures:none'><o:p>&nbsp;</o:p></span></p>
          <p class=MsoNormal><b><span style='font-size:8.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>Community &#8211; Trust &#8211; Dedication &#8211; Responsibility &#8211; Innovation &#8211; Growth<o:p></o:p></span></b></p>
          `,
        isHtml: true,
        attachments,
        type: "Labor Claim",
        currentDepartment: user.currentDepartment,
        currentSubsidiary: user.currentSubsidiary,
        username: user.username,
      });
    } catch (error) {
      handleAlert({
        type: "error",
        message: `Could not send email: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 5000,
      });
    }
  };

  const getAttchmentObjects = async (url, index) => {
    try {
      const fileName = form.file[index];
      const fileExtension = fileName.split(".").pop();
      let fileType = "application/octet-stream";

      if (fileExtension === "pdf") {
        fileType = "application/pdf";
      }

      if (fileExtension === "png") {
        fileType = "image/png";
      }

      if (fileExtension === "jpeg") {
        fileType = "image/jpeg";
      }

      const fileResponse = await axios.get(url, {
        responseType: "blob",
      });
      const blob = fileResponse.data;

      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const string = reader.result.split(",")[1];
          resolve({
            content: string, // Convert the file buffer to base64 string
            filename: fileName, // Set the original file name as the attachment filename
            type: fileType, // Set the file's mimetype
            disposition: "attachment", // Set the disposition as attachment
          });
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      throw error;
    }
  };

  const convertBlobToBase64 = async (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(blob);
    });
  };

  const handleSendWarningEmail = async () => {
    try {
      const warningEmailDate = new Date().toLocaleString();

      const { data } = await axios.patch(`forms/update/no-version/${formid}`, {
        updateData: {
          "form.rmawarningemail": warningEmailDate,
          "form.rmanotes": `${
            form.rmanotes ? `${form.rmanotes}\n` : ""
          }${warningEmailDate} (${user.username}) - Closure Email Sent`,
        },
      });

      if (data.form?.rmainternalid) {
        //update Netsuite notes
        axios.patch(
          `netsuite/patch/rma/${data.form.rmainternalid}/update-notes`,
          { notes: data.form?.rmanotes }
        );
      }

      //send closure warning email to customer
      await sendGeneralEmail({
        to: process.env.REACT_APP_TEST_EMAIL || form.warehouseEmail,
        from: "returns@trakmotive.com",
        fromname: "AIT Returns",
        cc: process.env.REACT_APP_TEST_EMAIL
          ? ""
          : data.form.additionalEmails || "",
        bcc: process.env.REACT_APP_TEST_EMAIL ? "" : "returns@trakmotive.com",
        subject: `Closure Warning for RGS ${data.form.rgaNum}`,
        body: `
        <p class=MsoNormal><i><span style='font-family:"Georgia",serif'>After checking our records, we are still showing the following RMA open:</span></i></p>
        <p class=MsoNormal><i><span style='font-family:"Georgia",serif'>${data.form.rmaNum.replace(
          "RMA",
          "RMA #"
        )}</span></i></p>
        <p class=MsoNormal><i><span style='font-family:"Georgia",serif'>RGS ${
          data.form.rgaNum
        }</span></i></p>
        <p class=MsoNormal><i><span style='font-family:"Georgia",serif'>If you still want to move forward, we will need you to send the part(s) as well as all paperwork needed.</span></i></p>
        <p class=MsoNormal><i><span style='font-family:"Georgia",serif'>If you feel you have already sent this claim in please forward proof of delivery.</span></i></p>
        <p class=MsoNormal><i><span style='font-family:"Georgia",serif'>If we DO NOT have any contact pertaining the ${data.form.rmaNum.replace(
          "RMA",
          "RMA #"
        )} within the next 10 days, we will have no other choice but to close this RMA.</span></i></p>
       
        
        <p class=MsoNormal><i><span style='font-family:"Georgia",serif'><i><span style='font-family:"Georgia",serif'>Thank You,</span></i></br></p>
          <p class=MsoNormal><b><i><span style='font-size:12.0pt;font-family:"Georgia",serif;color:#1F3864;mso-ligatures:none'>${
            user.firstname
          } ${
          user.lastname
        }<o:p></o:p></span></i></b></p><p class=MsoNormal><i><span style='font-size:12.0pt;font-family:"Georgia",serif;color:#2F5496;mso-ligatures:none'>Returns Clerk<o:p>
          <p class=MsoNormal><b><span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>Advanced Innovative Technology Corp.</span></b><b>
          <span style='font-family:"Georgia",serif;color:#1F497D;mso-ligatures:none'><o:p></o:p></span></b></p><p class=MsoNormal><span style='font-size:10.0pt;font-family:"Georgia",serif;mso-ligatures:none'>350 Nevada St. Redlands, CA 92373<o:p></o:p></span></p>
          <p class=MsoNormal><b><span style='font-size:10.0pt;font-family:"Georgia",serif;color:#2F5496;mso-ligatures:none'>
          <a href="mailto:${user.email}"><span style='color:#2F5496'>${
          user.email
        }</span></a>
          <o:p></o:p></span></b></p><p class=MsoNormal><span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>O 909-253-0077 Ext:120<o:p></o:p></span></p>
          <p class=MsoNormal><span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>F 417-831-9447<o:p></o:p></span></p>
          <p class=MsoNormal><a href="http://www.trakmotive.com/"><span style='font-size:10.5pt;font-family:"Georgia",serif;mso-ligatures:none;text-decoration:none'><img border=0 width=183 height=53 style='width:1.9062in;height:.552in' id="Picture_x0020_11" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/trakmotive.png"></span></a>
          <span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'><o:p></o:p></span></p><p class=MsoNormal><b><i><span style='font-family:"Georgia",serif;mso-ligatures:none'>Our Job Is To Make Your Job Easier<o:p></o:p></span></i></b></p>
          <p class=MsoNormal><b><i><span style='font-family:"Georgia",serif;mso-ligatures:none'><o:p>&nbsp;</o:p></span></i></b></p><p class=MsoNormal><a href="https://www.facebook.com/trakmotive/"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_12" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/facebook.png"></span></a>
          <span style='font-family:"Georgia",serif;mso-ligatures:none'>&nbsp; </span><a href="https://twitter.com/TrakMotiveAxles"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_13" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/twitter.png"></span></a><span style='font-family:"Georgia",serif;mso-ligatures:none'>&nbsp;&nbsp;</span>
          <a href="https://www.linkedin.com/company/advanced-innovative-technology-corp-/"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_14" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/linkedin.png"></span></a><span style='font-family:"Georgia",serif;mso-ligatures:none'>&nbsp;&nbsp;</span>
          <a href="https://www.instagram.com/trakmotive/?hl=en"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_15" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/instagram.png"></span></a><span style='font-family:"Georgia",serif;mso-ligatures:none'><o:p></o:p></span></p><p class=MsoNormal><span style='font-family:"Georgia",serif;mso-ligatures:none'><o:p>&nbsp;</o:p></span></p>
          <p class=MsoNormal><b><span style='font-size:8.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>Community &#8211; Trust &#8211; Dedication &#8211; Responsibility &#8211; Innovation &#8211; Growth<o:p></o:p></span></b></p>
          `,
        isHtml: true,
        type: "Labor Claim",
        currentDepartment: user.currentDepartment,
        currentSubsidiary: user.currentSubsidiary,
        username: user.username,
      });

      navigate(
        `/portal/${user.currentDepartment.toLowerCase()}/forms/labor-claim`
      );

      dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully sent rma closure email`,
          duration: 5000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error: ${error.response?.data?.msg || error.message}`,
          duration: 5000,
        })
      );
    }
  };

  const handleReopenLaborClaim = async () => {
    try {
      const reopenDate = new Date().toLocaleString();

      const { data } = await axios.patch(`forms/update/no-version/${formid}`, {
        updateData: {
          "form.status": form.rmaNum ? "rmaissued" : "new",
          "form.reopenedbyname": `${user.firstname} ${user.lastname}`,
          "form.reopenedbyusername": user.username,
          "form.reopenedbyemail": user.email,
          "form.reopeneddate": reopenDate,
          "form.rmanotes": `${form.rmanotes}\n${reopenDate} (${user.username}) - Reopened`,
        },
      });

      if (data.form?.rmainternalid) {
        //update Netsuite notes and reopen rma
        axios.patch(
          `netsuite/patch/rma/${data.form.rmainternalid}/status/toggle`,
          {
            notes: data.form.rmanotes,
            isclosed: false,
          }
        );
      }

      navigate(
        `/portal/${user.currentDepartment.toLowerCase()}/forms/labor-claim`
      );

      dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully updated labor claim`,
          duration: 5000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could not reopen labor claim: ${
            error.response?.data?.msg || error.message
          }`,
          duration: 5000,
        })
      );
    }
  };

  const handleRMAClosureSubmit = async (event) => {
    event.preventDefault();
    try {
      const closedDate = new Date().toLocaleString();
      const formData = new FormData(event.target);
      const reasonInput = formData.get("closedreason");

      if (!reasonInput) {
        throw new Error("Reason is required to close rma");
      }

      const { data } = await axios.patch(`forms/update/no-version/${formid}`, {
        updateData: {
          "form.status": "incomplete",
          "form.closedreason": reasonInput,
          "form.closedbyname": `${user.firstname} ${user.lastname}`,
          "form.closedbyusername": `${user.username}`,
          "form.closedbyemail": `${user.email}`,
          "form.closeddate": closedDate,
          "form.rmanotes": `${
            form.rmanotes ? `${form.rmanotes}\n` : ""
          }${closedDate} (${user.username}) - Closed - ${reasonInput}`,
        },
      });

      if (data.form?.rmainternalid) {
        //update Netsuite notes and close rma if its a netsuite rma
        axios.patch(
          `netsuite/patch/rma/${data.form.rmainternalid}/status/toggle`,
          {
            notes: data.form.rmanotes,
            isclosed: true,
          }
        );
      }

      navigate(
        `/portal/${user.currentDepartment.toLowerCase()}/forms/labor-claim`
      );

      dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully closed rma`,
          duration: 5000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error, could not close rma:  ${
            error.response?.data?.msg || error.message
          }`,
          duration: 5000,
        })
      );
    }
  };

  if (navigation.state === "loading")
    return <Loading message="Loading Labor Claims..." />;

  return (
    <Box>
      <FlexWrapper justifyContent="space-between">
        <>
          <IconButton
            onClick={() =>
              navigate(
                `/portal/${user?.currentDepartment?.toLowerCase()}/forms/labor-claim`
              )
            }
          >
            <ExitToApp color="error" />
          </IconButton>

          <FlexWrapper>
            {user.currentDepartment === "RETURNS" && form?.status === "new" && (
              <>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  sx={{ mr: 1 }}
                  onClick={generateRma}
                >
                  Generate RMA
                </Button>
                <MarkAsPendingDocumentsButton
                  formid={formid}
                  notes={form.rmanotes}
                  setLoading={setLoading}
                />
                <Divider flexItem orientation="vertical" />
              </>
            )}
            {(form?.status === "pendingdocuments" ||
              form?.status === "lcreceived-pendingdocuments") && (
              <>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleMarkAsNew}
                  sx={{ mr: 1 }}
                >
                  Mark As New
                </Button>
                <Button
                  color="error"
                  variant="contained"
                  size="small"
                  onClick={handleMarkAsIncomplete}
                  sx={{ mr: 1 }}
                >
                  Mark As Incomplete
                </Button>
                <Divider flexItem orientation="vertical" />
              </>
            )}
            {form.status === "incomplete" && (
              <>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ mr: 2 }}
                  onClick={handleReopenLaborClaim}
                >
                  Reopen Labor Claim
                </Button>
                <Divider orientation="vertical" flexItem />
              </>
            )}

            {form.status === "rmaissued" && (
              <>
                {form.rmawarningemail ? (
                  <GeneralModalV3
                    openComponent={
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        sx={{ mr: 2 }}
                      >
                        Close RMA
                      </Button>
                    }
                  >
                    <>
                      <Typography variant="h4" textAlign="center" p={1}>
                        Close RMA?
                      </Typography>
                      <Divider />
                      <Box
                        p={2}
                        component="form"
                        onSubmit={handleRMAClosureSubmit}
                      >
                        <Typography textAlign="center" pb={2}>
                          Please provide a brief descrption for closing RMA:
                        </Typography>
                        <TextField
                          fullWidth
                          multiline
                          minRows={4}
                          name="closedreason"
                          label="Reason For Closure"
                        />
                        <Button
                          type="submit"
                          color="error"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 2 }}
                        >
                          Submit and close
                        </Button>
                      </Box>
                    </>
                  </GeneralModalV3>
                ) : (
                  <Button
                    size="small"
                    variant="contained"
                    color="error"
                    sx={{ mr: 2 }}
                    onClick={handleSendWarningEmail}
                  >
                    Send RMA Closure Warning Email
                  </Button>
                )}
                <Divider orientation="vertical" flexItem />
              </>
            )}

            <GeneralModalV3
              openComponent={
                <Tooltip arrow placement="top" title="Created By">
                  <IconButton>
                    <Person color="primary" />
                  </IconButton>
                </Tooltip>
              }
            >
              <CreatedByLC createdby={createdby} />
            </GeneralModalV3>

            <AttachmentsLC
              formid={formid}
              form={form}
              handleAlert={handleAlert}
              fileURL={fileURL}
            />

            <NotesLC
              rmaNotes={form.rmanotes}
              form={form}
              setForm={setForm}
              formid={formid}
              username={user.username}
              handleAlert={handleAlert}
            />
          </FlexWrapper>
        </>
      </FlexWrapper>

      <Typography textAlign="center" variant="h3">
        Labor Claim Form
      </Typography>
    </Box>
  );
};
export default LaborClaimHeader;
