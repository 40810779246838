import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    padding: "30px 30px",
    fontFamily: "Helvetica",
  },
  title: {
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
    fontSize: "20px",
    paddingBottom: "10px",
  },
  section: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "5px",
    fontSize: "11px",
    paddingHorizontal: "25px",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
    fontSize: "10px",
    textOverflow: "ellipsis",
  },
  tableColHeader: {
    flex: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
  },
  tableColHeaderDesc: {
    flex: 2,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
  },
  tableColHeaderSmall: {
    flex: 0.5,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
    fontSize: "12px",
  },
  tableCol: {
    flex: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "center",
  },
  tableColDesc: {
    flex: 2,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "center",
  },
  tableColSmall: {
    flex: 0.5,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "center",
  },
  headerFontSize: {
    fontSize: "12px",
  },
});

const CycleCountsReportPDF = ({
  status,
  name,
  createdOn,
  startedOn,
  countedBy,
  finalizedOn,
  finalizedBy,
  finishedOn,
  jobs,
  accuracy,
  recount,
  location,
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.title}>Inventory Scan Request Report</Text>

      <View style={styles.section}>
        <Text>Job Name: {name}</Text>
        <Text>Counted By: {countedBy}</Text>
      </View>
      <View style={styles.section}>
        <Text>
          Status: {status === "complete" ? "Complete" : "Pending Approval"}
        </Text>
        <Text>Start Count Date: {new Date(startedOn)?.toLocaleString()}</Text>
      </View>
      <View style={styles.section}>
        <Text>Created: {new Date(createdOn)?.toLocaleString()}</Text>
        <Text>Finish Count Date: {new Date(finishedOn)?.toLocaleString()}</Text>
      </View>
      {finalizedOn && finalizedBy && (
        <View style={styles.section}>
          <Text>Completed: {new Date(finalizedOn)?.toLocaleString()}</Text>
          <Text>Completed By: {finalizedBy}</Text>
        </View>
      )}
      <View style={styles.section}>
        <Text>
          Bin Accuracy:{" "}
          {accuracy === 0 || Boolean(accuracy) ? `${accuracy}%` : "N/A"}
        </Text>
        <Text>Recount Created: {recount}</Text>
      </View>
      {location && (
        <View style={styles.section}>
          <Text>Location: {location}</Text>
        </View>
      )}

      <View style={[styles.table, { marginTop: "10px" }]}>
        <View id="header" style={[styles.tableRow, styles.headerFontSize]}>
          <Text style={styles.tableColHeader}>BIN</Text>
          <Text style={styles.tableColHeaderDesc}>Description</Text>
          <Text style={styles.tableColHeader}>Name</Text>
          <Text style={styles.tableColHeaderSmall}>Count</Text>
          <Text style={styles.tableColHeaderSmall}>On-Hand</Text>
          <Text style={styles.tableColHeaderSmall}>+/-</Text>
        </View>
        {/* Sample data rows */}

        {Boolean(jobs?.length) &&
          jobs.map((job) => (
            <View key={job._id} style={styles.tableRow} wrap>
              <Text style={styles.tableCol}>{job.itemData.itembinnumber}</Text>
              <Text style={styles.tableColDesc}>
                {job.itemData.itemdescription}
              </Text>
              <Text style={styles.tableCol}>{job.itemData.itemname}</Text>
              <Text style={styles.tableColSmall}>
                {job.counts?.length
                  ? job.counts[job.counts.length - 1].physicalcount
                  : "0"}
              </Text>
              <Text style={styles.tableColSmall}>
                {job.counts?.length
                  ? job.counts[job.counts.length - 1].nsqty
                  : "0"}
              </Text>
              <Text style={styles.tableColSmall}>
                {job.counts?.length
                  ? job.counts[job.counts.length - 1].variance
                  : "0"}
              </Text>
            </View>
          ))}
      </View>
    </Page>
  </Document>
);

export default CycleCountsReportPDF;
