import { useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import { pdf } from "@react-pdf/renderer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { sendGeneralEmail } from "../../../utils/email/generalEmailFunction";

import axios from "../../../axios/axios.config";
import LaborClaimWRMAPDF from "../../PDF/multi-page/LaborClaimWRMAPDF";
import LaborClaimRMAPlacard from "../../PDF/multi-page/LaborClaimRMAPlacard";

const SubmitFormReturnsNewView = ({ setLoading = null, form, fileURL }) => {
  const user = useSelector((state) => state.user);
  const { formid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [rma, setRMA] = useState(form?.rmaNum || "");

  const handleChanges = (event) => {
    setRMA(event.target.value.toUpperCase());
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    if (setLoading) {
      setLoading("Submitting Labor Claim...");
    }

    try {
      let rmainternalid = null;

      if (!rma?.startsWith("W") && !rma?.startsWith("RMA")) {
        throw new Error(
          'RMA number must start with either a "W" for Ningbo or "RMA" for netsuite RMAs'
        );
      }

      if (rma && !rma?.startsWith("W")) {
        if (!rma?.startsWith("RMA")) {
          throw new Error("RMA Number must start with RMA");
        }
        //validate RMA Num
        const { data } = await axios.get(`netsuite/get/validate/rma/cm/${rma}`);
        rmainternalid = parseInt(data.items[0]?.id);
      }

      const { data } = await axios.patch(
        `forms/update/labor-claim/${formid}/returns/issue-rma`,
        {
          updateData: {
            "form.rmaissuedby": user.username,
            "form.rmainternalid": rmainternalid,
            "form.rmaissuedbyname": `${user.firstname} ${user.lastname}`,
            "form.rmaissuedbyemail": user.email,
            "form.rmaissuedate": new Date().toLocaleString(),
            "form.rmaissueddepartment": user.currentDepartment,
            "form.status": "rmaissued",
            "form.rmaNum": rma,
          },
        }
      );

      //Email is sent when its a WRMA as well as a regular RMA
      handleSendRMAEmail(data);

      navigate(
        `/portal/${user.currentDepartment.toLowerCase()}/forms/labor-claim`
      );

      dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully edited labor claim`,
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could not edit labor claim: ${
            error.response?.data?.msg === "Invalid number entered"
              ? "Invalid RMA Number"
              : error.response?.data?.msg || error.message
          }`,
          duration: 5000,
        })
      );
    } finally {
      if (setLoading) {
        setLoading("");
      }
    }
  };

  const handleSendRMAEmail = async (data) => {
    try {
      const attachments = [];
      //if it has an internalid then its a netsuite RMA else its a WRMA
      if (data.form.rmainternalid) {
        //generate pdf from netsuite
        const nsPDFResponse = await axios.get(
          `netsuite/labor-claim/get/rma/internalid/${data.form.rmaNum}`
        );

        attachments.push({
          content: nsPDFResponse.data.data64,
          filename: `${data.form.rmaNum}-AUTH.pdf`, // Set the original file name as the attachment filename
          type: "application/pdf", // Set the file's mimetype
          disposition: "attachment", // Set the disposition as attachment
        });
      } else {
        //create blob from pdf component
        const date = data.form.date;

        const rga = {
          number: data.form.rgaNum,
        };

        const productDetails = {
          products: data.form.partNumbers,
          total: data.form.totalClaim,
        };

        const rma = {
          number: data.form.rmaNum,
          issuedBy: data.form.rmaissuedbyname,
          issuedOn: new Date(data.form.rmaissuedate).toLocaleDateString(),
        };

        const wrmaBlob = await pdf(
          <LaborClaimWRMAPDF
            date={date}
            rga={rga}
            productDetails={productDetails}
            rma={rma}
          />
        ).toBlob();

        const wrmaBase64 = await convertBlobToBase64(wrmaBlob);

        attachments.push({
          content: wrmaBase64,
          filename: `RMA${data.form.rmaNum.replace("WRMA", "")}-AUTH.pdf`,
          type: "application/pdf",
          disposition: "attachment",
        });
      }

      const rmaPlacardBlob = await pdf(
        <LaborClaimRMAPlacard rmaNumber={data.form.rmaNum} />
      ).toBlob();

      // Convert the first blob to base64 string
      const rmaPlacardBase64 = await convertBlobToBase64(rmaPlacardBlob);

      // Now you have rmaPlacardBase64 and base64String2 for your attachments
      attachments.push({
        content: rmaPlacardBase64,
        filename: `LC-${data.form.rmaNum.replace("RMA", "RMA ")}-PLACARD.pdf`,
        type: "application/pdf",
        disposition: "attachment",
      });

      //get attachment objects from S3 files
      for (let i = 0; i < fileURL.length; i++) {
        const attachment = await getAttchmentObjects(fileURL[i], i);
        attachments.push(attachment);
      }

      await sendGeneralEmail({
        to: process.env.REACT_APP_TEST_EMAIL || data.form.warehouseEmail,
        from: "returns@trakmotive.com",
        fromname: "AIT Returns",
        cc: process.env.REACT_APP_TEST_EMAIL
          ? ""
          : data.form.additionalEmails || "",
        bcc: process.env.REACT_APP_TEST_EMAIL ? "" : "returns@trakmotive.com",
        subject: `${
          data.form.rmaNum.startsWith("W")
            ? `RMA# ${data.form.rmaNum} Issued`
            : data.form.rmaNum.replace("RMA", "RMA# ")
        } Issued`,
        body: `<p>***When shipping Labor Claims to our warehouse please use attached RMA Placard per each Box. If the box(es) do not include the attached placard they will be refused.</br></br>
            Please see the attached Return Material Authorization form, reference ${data.form.rmaNum.replace(
              "RMA",
              "RMA# "
            )} for the labor claim.</br></br>
            Have the part and the original documentation sent to the below address:</br></p>
            <address>Advanced Innovative Technology Corp.</br>
                    350 Nevada St.</br>
                    Redlands, CA 92373</br>
                    Attn: ${data.form.rmaNum.replace("RMA", "RMA# ")}-TECH</br>
                    Tel: (909) 253-0077 Fax: (909) 253-0080</br></br>
            </address>

            <p>Operating Hours: Mon - Fri – 7am to 2pm </br></br>
              If you have any questions, please do not hesitate to contact me.</br></br>
              Thank You,
            </p>
            <p class=MsoNormal><b><i><span style='font-size:12.0pt;font-family:"Georgia",serif;color:#1F3864;mso-ligatures:none'>${
              user.firstname
            } ${
          user.lastname
        }<o:p></o:p></span></i></b></p><p class=MsoNormal><i><span style='font-size:12.0pt;font-family:"Georgia",serif;color:#2F5496;mso-ligatures:none'>Returns Clerk<o:p>
            <p class=MsoNormal><b><span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>Advanced Innovative Technology Corp.</span></b><b>
            <span style='font-family:"Georgia",serif;color:#1F497D;mso-ligatures:none'><o:p></o:p></span></b></p><p class=MsoNormal><span style='font-size:10.0pt;font-family:"Georgia",serif;mso-ligatures:none'>350 Nevada St. Redlands, CA 92373<o:p></o:p></span></p>
            <p class=MsoNormal><b><span style='font-size:10.0pt;font-family:"Georgia",serif;color:#2F5496;mso-ligatures:none'>
            <a href="mailto:${user.email}"><span style='color:#2F5496'>${
          user.email
        }</span></a>
            <o:p></o:p></span></b></p><p class=MsoNormal><span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>O 909-253-0077 Ext:120<o:p></o:p></span></p>
            <p class=MsoNormal><span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>F 417-831-9447<o:p></o:p></span></p>
            <p class=MsoNormal><a href="http://www.trakmotive.com/"><span style='font-size:10.5pt;font-family:"Georgia",serif;mso-ligatures:none;text-decoration:none'><img border=0 width=183 height=53 style='width:1.9062in;height:.552in' id="Picture_x0020_11" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/trakmotive.png"></span></a>
            <span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'><o:p></o:p></span></p><p class=MsoNormal><b><i><span style='font-family:"Georgia",serif;mso-ligatures:none'>Our Job Is To Make Your Job Easier<o:p></o:p></span></i></b></p>
            <p class=MsoNormal><b><i><span style='font-family:"Georgia",serif;mso-ligatures:none'><o:p>&nbsp;</o:p></span></i></b></p><p class=MsoNormal><a href="https://www.facebook.com/trakmotive/"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_12" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/facebook.png"></span></a>
            <span style='font-family:"Georgia",serif;mso-ligatures:none'>&nbsp; </span><a href="https://twitter.com/TrakMotiveAxles"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_13" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/twitter.png"></span></a><span style='font-family:"Georgia",serif;mso-ligatures:none'>&nbsp;&nbsp;</span>
            <a href="https://www.linkedin.com/company/advanced-innovative-technology-corp-/"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_14" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/linkedin.png"></span></a><span style='font-family:"Georgia",serif;mso-ligatures:none'>&nbsp;&nbsp;</span>
            <a href="https://www.instagram.com/trakmotive/?hl=en"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_15" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/instagram.png"></span></a><span style='font-family:"Georgia",serif;mso-ligatures:none'><o:p></o:p></span></p><p class=MsoNormal><span style='font-family:"Georgia",serif;mso-ligatures:none'><o:p>&nbsp;</o:p></span></p>
            <p class=MsoNormal><b><span style='font-size:8.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>Community &#8211; Trust &#8211; Dedication &#8211; Responsibility &#8211; Innovation &#8211; Growth<o:p></o:p></span></b></p>
            `,
        isHtml: true,
        attachments,
        type: "Labor Claim",
        currentDepartment: user.currentDepartment,
        currentSubsidiary: user.currentSubsidiary,
        username: user.username,
      });
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could not send email: ${
            error.response?.data?.msg || error.message
          }`,
          duration: 5000,
        })
      );
    }
  };

  const convertBlobToBase64 = async (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(blob);
    });
  };

  const getAttchmentObjects = async (url, index) => {
    try {
      const fileName = form.file[index];
      const fileExtension = fileName.split(".").pop();
      let fileType = "application/octet-stream";

      if (fileExtension === "pdf") {
        fileType = "application/pdf";
      }

      if (fileExtension === "png") {
        fileType = "image/png";
      }

      if (fileExtension === "jpeg") {
        fileType = "image/jpeg";
      }

      const fileResponse = await axios.get(url, {
        responseType: "blob",
      });
      const blob = fileResponse.data;

      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const string = reader.result.split(",")[1];
          resolve({
            content: string, // Convert the file buffer to base64 string
            filename: fileName, // Set the original file name as the attachment filename
            type: fileType, // Set the file's mimetype
            disposition: "attachment", // Set the disposition as attachment
          });
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      throw error;
    }
  };

  return (
    <Grid
      container
      spacing={1}
      component="form"
      pt={1}
      onSubmit={handleEditSubmit}
    >
      <Grid item xs={12}>
        <Typography variant="h5" pt={2} gutterBottom>
          RMA
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          autoFocus
          required
          type="text"
          name="rmaNum"
          label="RMA Number"
          disabled={form.status === "pendingdocuments"}
          value={rma}
          onChange={handleChanges}
          helperText={
            <Typography color="error" variant="body2" component={"span"}>
              Enter RMA number manually for Ningbo labor claims or if RMA
              already exists in Netsuite. Otherwise use the "Generate RMA"
              Button
            </Typography>
          }
        />
      </Grid>
      {form.status === "new" && (
        <Grid item xs={12}>
          <Button fullWidth variant="contained" type="submit">
            Enter RMA Number and Submit
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
export default SubmitFormReturnsNewView;
