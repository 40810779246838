import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { pdf } from "@react-pdf/renderer";
import { sendGeneralEmail } from "../../../utils/email/generalEmailFunction";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../FlexWrapper";
import CreditMemoAndRMANetsuiteLinks from "../netsuite/CreditMemoAndRMANetsuiteLinks";
import axios from "../../../axios/axios.config";
import LaborClaimCreditMemoPDF from "../../PDF/multi-page/LaborClaimCreditMemoPDF";

const SubmitFormReturnsProcessedView = ({ form, fileURL, setLoading }) => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formid } = useParams();

  const [formDetails, setFormDetails] = useState(form);

  const handleChanges = (event) => {
    setFormDetails({
      ...formDetails,
      [event.target.name]: event.target.value.toUpperCase(),
    });
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    setLoading("Submitting Labor Claim...");
    try {
      const attachments = [];
      const completedDate = new Date().toLocaleString();
      let creditmemointernalid = null;

      if (!formDetails.creditmemonumber.startsWith("W")) {
        if (user.currentDepartment === "NINGBO") {
          throw new Error("Credit Memos must start with W for NINGBO.");
        }

        if (!formDetails.creditmemonumber.startsWith("CM")) {
          throw new Error("Credit Memo number must start with CM");
        }

        //validate credit memo number
        const { data } = await axios.get(
          `netsuite/get/validate/rma/cm/${formDetails.creditmemonumber}`
        );
        creditmemointernalid = parseInt(data.items[0]?.id);

        const nsPDFResponse = await axios.get(
          `netsuite/labor-claim/get/rma/internalid/${formDetails.creditmemonumber}`
        );

        attachments.push({
          content: nsPDFResponse.data.data64,
          filename: `${form.rmaNum}-${formDetails.creditmemonumber}.pdf`, // Set the original file name as the attachment filename
          type: "application/pdf", // Set the file's mimetype
          disposition: "attachment", // Set the disposition as attachment
        });
      }
      //transform state to send to backend
      const transformedObject = {};
      for (const key in formDetails) {
        if (Object.hasOwnProperty.call(formDetails, key)) {
          transformedObject[`form.${key}`] = formDetails[key];
        }
      }
      //update creditmemointernalid and status
      transformedObject["form.status"] = "complete";
      transformedObject["form.completedbyusername"] = user.username;
      transformedObject[
        "form.completedbyname"
      ] = `${user.firstname} ${user.lastname}`;
      transformedObject["form.completedbyemail"] = user.email;
      transformedObject["form.completeddate"] = completedDate;
      transformedObject["form.creditmemointernalid"] = creditmemointernalid;
      transformedObject["form.creditmemonumber"] = formDetails.creditmemonumber;
      transformedObject["form.creditamountfee"] = formDetails.creditamountfee;

      const { data } = await axios.patch(
        `forms/update/labor-claim/${formid}/ningbo/processed-view`,
        {
          updateData: transformedObject,
        }
      );

      //not a WRMA
      if (data.form.rmainternalid) {
        //update Netsuite notes
        axios.patch(
          `netsuite/patch/rma/${data.form.rmainternalid}/update-notes`,
          {
            notes: `${data.form.rmanotes}`,
          }
        );
      }

      const createdDate = data.form.date;
      const rma = {
        number: data.form.rmaNum.replace("RMA", ""),
        date: new Date(data.form.rmaissuedate).toLocaleDateString(),
        name: data.form.rmaissuedbyname,
      };
      const rga = {
        number: data.form.rgaNum.replace("RGA", ""),
      };
      const claimDetail = {
        date: new Date(data.form.processedbydate).toLocaleDateString(),
        reviewed: data.form.partNumbers
          .map((partObj) => `${partObj.partnumber} x ${partObj.qty}`)
          .join(", "),
        company: data.form.customer,
        by: "AIT",
      };
      const detail = {
        detail: data.form.detail,
        note: data.form.note,
      };
      const internalUse = {
        restockingFee: data.form.restockingfee,
        dateReceived: data.form.datereceived,
        receivedBy: data.form.receivedbyname,
        result: data.form.finalresponse,
        creditMemoNumber: data.form.creditmemonumber,
        creditIssuedDate: new Date(
          data.form.completeddate
        ).toLocaleDateString(),
      };

      const blob = await pdf(
        <LaborClaimCreditMemoPDF
          rma={rma}
          rga={rga}
          claimDetail={claimDetail}
          detail={detail}
          internalUse={internalUse}
          createdDate={createdDate}
        />
      ).toBlob();

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        attachments.push({
          content: base64String,
          filename: `${data.form.rmaNum.replace("RMA", "RMA ")}-RESPONSE.pdf`, // Set the original file name as the attachment filename
          type: "application/pdf", // Set the file's mimetype
          disposition: "attachment", // Set the disposition as attachment
        });
      };
      reader.readAsDataURL(blob);

      //get attachment objects from S3 files
      for (let i = 0; i < fileURL.length; i++) {
        const attachment = await getAttchmentObjects(fileURL[i], i);
        attachments.push(attachment);
      }

      await sendGeneralEmail({
        to: process.env.REACT_APP_TEST_EMAIL || data.form.warehouseEmail,
        from: "returns@trakmotive.com",
        fromname: "AIT Returns",
        cc: process.env.REACT_APP_TEST_EMAIL
          ? ""
          : data.form.additionalEmails || "",
        bcc: process.env.REACT_APP_TEST_EMAIL ? "" : "returns@trakmotive.com",
        subject: `Labor Claim Final Response ${data.form.rmaNum.replace(
          "RMA",
          "RMA# "
        )}`,
        body: `<p>Please find attached the response form and credit for ${data.form.rmaNum.replace(
          "RMA",
          "RMA# "
        )} for ${data.form.customer}.</p>
          <p class=MsoNormal><b><i><span style='font-size:12.0pt;font-family:"Georgia",serif;color:#1F3864;mso-ligatures:none'>${
            user.firstname
          } ${
          user.lastname
        }<o:p></o:p></span></i></b></p><p class=MsoNormal><i><span style='font-size:12.0pt;font-family:"Georgia",serif;color:#2F5496;mso-ligatures:none'>Returns Clerk<o:p>
          <p class=MsoNormal><b><span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>Advanced Innovative Technology Corp.</span></b><b>
          <span style='font-family:"Georgia",serif;color:#1F497D;mso-ligatures:none'><o:p></o:p></span></b></p><p class=MsoNormal><span style='font-size:10.0pt;font-family:"Georgia",serif;mso-ligatures:none'>350 Nevada St. Redlands, CA 92373<o:p></o:p></span></p>
          <p class=MsoNormal><b><span style='font-size:10.0pt;font-family:"Georgia",serif;color:#2F5496;mso-ligatures:none'>
          <a href="mailto:${user.email}"><span style='color:#2F5496'>${
          user.email
        }</span></a>
          <o:p></o:p></span></b></p><p class=MsoNormal><span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>O 909-253-0077 Ext:120<o:p></o:p></span></p>
          <p class=MsoNormal><span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>F 417-831-9447<o:p></o:p></span></p>
          <p class=MsoNormal><a href="http://www.trakmotive.com/"><span style='font-size:10.5pt;font-family:"Georgia",serif;mso-ligatures:none;text-decoration:none'><img border=0 width=183 height=53 style='width:1.9062in;height:.552in' id="Picture_x0020_11" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/trakmotive.png"></span></a>
          <span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'><o:p></o:p></span></p><p class=MsoNormal><b><i><span style='font-family:"Georgia",serif;mso-ligatures:none'>Our Job Is To Make Your Job Easier<o:p></o:p></span></i></b></p>
          <p class=MsoNormal><b><i><span style='font-family:"Georgia",serif;mso-ligatures:none'><o:p>&nbsp;</o:p></span></i></b></p><p class=MsoNormal><a href="https://www.facebook.com/trakmotive/"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_12" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/facebook.png"></span></a>
          <span style='font-family:"Georgia",serif;mso-ligatures:none'>&nbsp; </span><a href="https://twitter.com/TrakMotiveAxles"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_13" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/twitter.png"></span></a><span style='font-family:"Georgia",serif;mso-ligatures:none'>&nbsp;&nbsp;</span>
          <a href="https://www.linkedin.com/company/advanced-innovative-technology-corp-/"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_14" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/linkedin.png"></span></a><span style='font-family:"Georgia",serif;mso-ligatures:none'>&nbsp;&nbsp;</span>
          <a href="https://www.instagram.com/trakmotive/?hl=en"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_15" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/instagram.png"></span></a><span style='font-family:"Georgia",serif;mso-ligatures:none'><o:p></o:p></span></p><p class=MsoNormal><span style='font-family:"Georgia",serif;mso-ligatures:none'><o:p>&nbsp;</o:p></span></p>
          <p class=MsoNormal><b><span style='font-size:8.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>Community &#8211; Trust &#8211; Dedication &#8211; Responsibility &#8211; Innovation &#8211; Growth<o:p></o:p></span></b></p>
          `,
        isHtml: true,
        attachments,
        type: "Labor Claim",
        username: user.username,
        currentDepartment: user.currentDepartment,
        currentSubsidiary: user.currentSubsidiary,
      });

      navigate(
        `/portal/${user.currentDepartment.toLowerCase()}/forms/labor-claim`
      );

      dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully edited labor claim to complete`,
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could not edit labor claim: ${
            error.response?.data?.msg === "Invalid number entered"
              ? "Invalid Credit Memo"
              : error.response?.data?.msg || error.message
          }`,
          duration: 5000,
        })
      );
    } finally {
      setLoading("");
    }
  };

  const getAttchmentObjects = async (url, index) => {
    try {
      const fileName = form.file[index];
      const fileExtension = fileName.split(".").pop();
      let fileType = "application/octet-stream";

      if (fileExtension === "pdf") {
        fileType = "application/pdf";
      }

      if (fileExtension === "png") {
        fileType = "image/png";
      }

      if (fileExtension === "jpeg") {
        fileType = "image/jpeg";
      }

      const fileResponse = await axios.get(url, {
        responseType: "blob",
      });
      const blob = fileResponse.data;

      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const string = reader.result.split(",")[1];
          resolve({
            content: string, // Convert the file buffer to base64 string
            filename: fileName, // Set the original file name as the attachment filename
            type: fileType, // Set the file's mimetype
            disposition: "attachment", // Set the disposition as attachment
          });
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      throw error;
    }
  };

  return (
    <Grid
      container
      spacing={2}
      component="form"
      onSubmit={handleEditSubmit}
      pt={2}
    >
      <Grid item xs={12}>
        <FlexWrapper alignItems="center" gap={1}>
          <Typography variant="h6">RMA</Typography>
          {form.rmainternalid && (
            <CreditMemoAndRMANetsuiteLinks internalid={form.rmainternalid} />
          )}
        </FlexWrapper>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          type="text"
          name="rmaNum"
          label="RMA Number"
          InputProps={{ readOnly: true }}
          value={form.rmaNum}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="text"
          name="rmaissuedby"
          label="RMA Issued By"
          value={`${form.rmaissuedbyname} (${form.rmaissuedby})`}
          InputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="text"
          name="rmaissuedbyemail"
          label="RMA Issued By Email"
          value={form.rmaissuedbyemail}
          InputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          type="text"
          label="RMA Issued By Department"
          value={form.rmaissueddepartment}
          InputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="text"
          label="RMA Issued Date"
          value={new Date(form.rmaissuedate).toLocaleDateString()}
          InputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="text"
          label="RMA Issued Time"
          value={new Date(form.rmaissuedate).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}
          InputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">LC Received</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="text"
          name="datereceived"
          label="Date Received"
          value={form.datereceived}
          InputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="time"
          name="timereceived"
          label="Time Received"
          value={form.timereceived}
          InputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="text"
          name="receivedby"
          label="Received By"
          value={`${form.receivedbyname} (${form.receivedby})`}
          InputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="text"
          name="receivedbyemail"
          label="Received By Email"
          value={form.receivedbyemail}
          InputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          type="text"
          name="bin"
          label="Bin Number"
          value={formDetails.bin}
          onChange={handleChanges}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Processed</Typography>
      </Grid>
      {form.processedbydate && (
        <>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              label="Processed By Date"
              value={new Date(form.processedbydate).toLocaleDateString()}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              label="Processed By Time"
              value={new Date(form.processedbydate).toLocaleTimeString("en", {
                hour: "2-digit",
                minute: "2-digit",
              })}
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </>
      )}
      {form.processedby && (
        <Grid item xs={6}>
          <TextField
            fullWidth
            type="text"
            name="processedby"
            label="Processed By"
            value={`${form.processedbyname} (${form.processedby})`}
            InputProps={{ readOnly: true }}
          />
        </Grid>
      )}
      {form.processedbyemail && (
        <Grid item xs={6}>
          <TextField
            fullWidth
            type="text"
            name="processedbyemail"
            label="Processed By Email"
            value={formDetails.processedbyemail}
            InputProps={{ readOnly: true }}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          fullWidth
          type="text"
          name="lcresponse"
          label="Response"
          value={formDetails.lcresponse}
          onChange={handleChanges}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          minRows={4}
          type="text"
          name="detail"
          label="Detail"
          value={formDetails.detail}
          onChange={(event) =>
            setFormDetails({ ...formDetails, detail: event.target.value })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          type="text"
          name="note"
          label="Note"
          value={formDetails.note}
          onChange={handleChanges}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth required>
          <InputLabel id="select-label-lc-options">Result</InputLabel>
          <Select
            label="Result"
            name="finalresponse"
            value={formDetails.finalresponse}
            onChange={handleChanges}
          >
            <MenuItem value="VOID">VOID</MenuItem>
            <MenuItem value="DENIED">DENIED</MenuItem>
            <MenuItem value="PARTIALLY APPROVED">PARTIALLY APPROVED</MenuItem>
            <MenuItem value="FULLY APPROVED">FULLY APPROVED</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Internal Use Only</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          required
          type="number"
          name="creditamountfee"
          label="Credit Amount"
          value={formDetails.creditamountfee}
          onChange={handleChanges}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          required
          type="text"
          name="creditmemonumber"
          label="Credit Memo Number"
          value={formDetails.creditmemonumber}
          onChange={handleChanges}
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" fullWidth type="submit">
          Edit And Complete Labor Claim Form
        </Button>
      </Grid>
    </Grid>
  );
};

export default SubmitFormReturnsProcessedView;
