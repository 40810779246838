import { useEffect, useRef, useState } from "react";
import { useGeneralAlert } from "../../hooks/useGeneralAlert";
import { useDispatch, useSelector } from "react-redux";
import { updateWhseItemLabelPrinter } from "../../redux/features/user/userSlice";
import { printGeneralV2 } from "../../utils/printer-functions/printGeneralV2";
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import axios from "../../axios/axios.config";
import FlexWrapper from "../../components/FlexWrapper";

const PrintItemLabels = () => {
  const itemInputRef = useRef(null);
  const dispatch = useDispatch();
  const {
    currentSubsidiary,
    itemlabelprinter,
    currentLocation,
    username,
    currentDepartment,
  } = useSelector((state) => state.user);
  const { openAlert } = useGeneralAlert();

  const [loading, setLoading] = useState("");
  const [printerOptions, setPrinterOptions] = useState([]);
  const [input, setInput] = useState({
    item: "",
    brand: currentSubsidiary === "WGE" ? "TRAKMOTIVE-WGE" : "",
    printer: itemlabelprinter?._id || "",
    qty: 1,
    isBrownBox: true,
  });

  useEffect(() => {
    getPrinters();
  }, []);

  const getPrinters = async () => {
    try {
      const printerResponse = await axios.get(
        `printers?location=${currentLocation}`
      );

      if (!printerResponse.data?.length) {
        throw new Error("No printer options found for location");
      }

      setPrinterOptions(printerResponse.data);
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Get Priner Options: ${
          error?.response?.data?.msg || error.message
        }`,
        duration: 9000,
      });
    }
  };

  const handleChanges = (e) => {
    if (e.target.name === "item") {
      setInput({ ...input, [e.target.name]: e.target.value.toUpperCase() });
    } else {
      setInput({ ...input, [e.target.name]: e.target.value });
    }
  };

  const handlePinterChanges = async (event) => {
    setLoading("Setting Printer...");
    try {
      const response = await dispatch(
        updateWhseItemLabelPrinter(
          printerOptions.find((option) => option._id === event.target.value)
        )
      ).unwrap();

      setInput((prev) => {
        return { ...prev, printer: response.newPrinter?._id };
      });
      openAlert({
        type: "success",
        message: "Successfully updated printer",
        duration: 9000,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Update Priner: ${
          error?.response?.data?.msg || error.message
        }`,
        duration: 9000,
      });
    } finally {
      setLoading("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    openAlert({
      type: "success",
      message: "Printing...",
      duration: 1000,
    });
    submitRequest(input);
    setInput({
      item: "",
      brand: input.brand,
      printer: itemlabelprinter?._id || "",
      qty: 1,
      isBrownBox: input.isBrownBox,
    });

    itemInputRef.current.inputMode = "none";
  };

  const submitRequest = async (input) => {
    try {
      //gets template from netsuite response
      const response = await axios.post(
        "netsuite/print-labels/warehouse",
        input
      );

      await printGeneralV2({
        currentPrinter: itemlabelprinter,
        template: response.data.labelsToPrint || "",
        title: "Print Item Labels",
      });

      //logging
      const printLogsBody = {
        user: username,
        item: input.item,
        brand: input.brand,
        refnumber: input.item,
        qty: input.qty,
        type: "print-item-labels-warehouse",
        location: currentLocation,
        printer: itemlabelprinter?.printername,
        subsidiary: currentSubsidiary,
        department: currentDepartment,
      };
      await axios.post("print-logs", printLogsBody);

      openAlert({
        type: "success",
        message:
          response.data.message || `Successfully Printed Labels for ${input}`,
        duration: 2000,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message:
          error?.response?.data?.message || `Could Not Print: ${error.message}`,
        duration: 8000,
      });
    }
  };

  return (
    <Container sx={{ maxWidth: { xs: "xl", md: "md" } }}>
      <Typography component="h1" variant="h4" textAlign="center" py={2}>
        Print Item Labels
      </Typography>
      <form onSubmit={handleSubmit}>
        <FlexWrapper flexDirection="column" gap={2}>
          <TextField
            autoComplete="off"
            inputProps={{ autoComplete: "off" }}
            autoFocus
            fullWidth
            required
            inputRef={itemInputRef}
            inputMode="none"
            type="text"
            label="Item"
            name="item"
            value={input.item}
            onChange={handleChanges}
          />
          {currentSubsidiary === "WGE" ? (
            <FormControl fullWidth required>
              <InputLabel id="select-brand">Brand</InputLabel>
              <Select
                labelId="select-brand"
                id="select-brand-id"
                name="brand"
                label="brand"
                value={input.brand}
                onChange={handleChanges}
              >
                <MenuItem value="TRAKMOTIVE-WGE">TRAKMOTIVE</MenuItem>
                <MenuItem value="PLAIN-WGE">PLAIN</MenuItem>
                <MenuItem value="LPR">LPR</MenuItem>
                <MenuItem value="SKF">SKF</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <FormControl fullWidth required>
              <InputLabel id="select-brand">Brand</InputLabel>
              <Select
                labelId="select-brand"
                id="select-brand-id"
                name="brand"
                label="brand"
                value={input.brand}
                onChange={handleChanges}
              >
                <MenuItem value="TRAKMOTIVE">TRAKMOTIVE</MenuItem>
                <MenuItem value="OREILLY">OREILLY</MenuItem>
                <MenuItem value="AUTOZONE">AUTOZONE</MenuItem>
                <MenuItem value="NAPA">NAPA</MenuItem>
                <MenuItem value="PLAIN">PLAIN</MenuItem>
                <MenuItem
                  value=""
                  disabled
                  sx={{ bgcolor: "rgba(25, 118, 210, 0.06)" }}
                >
                  ------------------------
                </MenuItem>
                <MenuItem value="SET">SET</MenuItem>
                <MenuItem value="NISSAN">NISSAN</MenuItem>
                <MenuItem value="INFINITI">INFINITI</MenuItem>
                <MenuItem
                  value=""
                  disabled
                  sx={{ bgcolor: "rgba(25, 118, 210, 0.06)" }}
                >
                  ------------------------
                </MenuItem>
                <MenuItem value="FVP">FVP</MenuItem>
                <MenuItem value="PARTS MASTER">PARTS MASTER</MenuItem>
                <MenuItem value="AUTOMATIC DISTRIBUTORS">
                  AUTOMATIC DISTRIBUTORS
                </MenuItem>
                <MenuItem value="JM ATV">JM ATV</MenuItem>
                <MenuItem
                  value=""
                  disabled
                  sx={{ bgcolor: "rgba(25, 118, 210, 0.06)" }}
                >
                  ------------------------
                </MenuItem>
                <MenuItem value="TRAKMOTIVE-BW-LOGO">
                  TRAKMOTIVE-BW-LOGO
                </MenuItem>
                <MenuItem value="TRAKMOTIVE-W-CROSSREF">
                  TRAKMOTIVE-W-CROSSREF
                </MenuItem>
                <MenuItem value="ALTUS AUTOMOTIVE">ALTUS AUTOMOTIVE</MenuItem>
                <MenuItem value="PARTS MOUNTAIN">PARTS MOUNTAIN</MenuItem>
              </Select>
            </FormControl>
          )}

          {/* Label Printer */}
          <FormControl fullWidth required>
            <InputLabel id="select-printer">Label Printer</InputLabel>
            <Select
              value={input.printer}
              label="Label Printer"
              name="printer"
              onChange={handlePinterChanges}
            >
              {printerOptions?.length
                ? printerOptions.map((option) => (
                    <MenuItem key={option?._id} value={option._id}>
                      {option?.printername}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            required
            type="number"
            inputMode="numeric"
            label="Print Quantity"
            name="qty"
            value={input.qty}
            onChange={handleChanges}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={input.isBrownBox}
                onChange={() => {
                  setInput({ ...input, isBrownBox: !input.isBrownBox });
                }}
                inputProps={{ "aria-label": "brownbox checkbox" }}
              />
            }
            label="Brown Box"
          />
          <Button
            fullWidth
            disabled={!itemlabelprinter || Boolean(loading)}
            size="large"
            variant="contained"
            type="submit"
          >
            {Boolean(loading) ? loading : "Print Label"}
          </Button>
          {!itemlabelprinter && (
            <Typography
              textTransform="uppercase"
              component="p"
              color="error"
              variant="caption"
              alignSelf="center"
            >
              Please select a printer
            </Typography>
          )}
        </FlexWrapper>
      </form>
    </Container>
  );
};

export default PrintItemLabels;
