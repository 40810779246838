import { useState } from "react";
import { FixedSizeList } from "react-window";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";
import { useOutletContext } from "react-router-dom";
import { Delete, Warning } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../FlexWrapper";
import axios from "../../../axios/axios.config";

const ReceiveInspectionReceivedView = () => {
  const { receivedItems, setLoading, orderData, handleStateUpdate } =
    useOutletContext();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const { openAlert } = useGeneralAlert();

  // Filter items based on search term (by name or description)
  const filteredItems = receivedItems?.filter(({ item, manualitem }) => {
    const name = item?.lineitemname || manualitem?.lineitemname || "";
    const upc = item?.lineitemupc || manualitem?.lineitemupc || "";
    const parsedItem = searchTerm?.split("*")[0].trim();
    return (
      name.toLowerCase().includes(parsedItem.toLowerCase()) ||
      upc.toLowerCase().includes(parsedItem.toLowerCase())
    );
  });

  // Item Renderer for FixedSizeList
  const renderRow = ({ index, style }) => {
    const {
      item,
      _id,
      manualitem,
      timereceived,
      receivedby,
      status,
      ismanualaddition,
      qtyreceived,
    } = filteredItems[index];

    const statusColor = status === "Good" ? "green" : "error";

    return (
      <Box key={_id} style={style}>
        <Box
          display="flex"
          justifyContent="space-between"
          gap={1}
          alignItems="center"
        >
          <Typography pt={1} variant="body1" fontWeight="bold">
            {item ? item.lineitemname : manualitem?.lineitemname} ({qtyreceived}
            )
          </Typography>

          <IconButton
            size="small"
            disableRipple
            onClick={() =>
              setSelectedItem({
                item: item ? item.lineitemname : manualitem?.lineitemname,
                ismanualaddition,
                qtyreceived,
                receiveId: _id,
                lineItemId: ismanualaddition ? null : item?._id,
                orderId: orderData?._id,
              })
            }
          >
            <Delete color="error" fontSize="small" />
          </IconButton>
        </Box>

        <Box>
          {/* Status Indicator */}
          <Typography>
            <Typography
              fontWeight="bold"
              variant="caption"
              component="span"
              color={statusColor}
            >
              {status === "Good"
                ? "Good"
                : status?.replace("Bad-", "").replace("RTCHINA-", "")}
            </Typography>
            {ismanualaddition && (
              <Typography
                fontWeight="bold"
                variant="caption"
                component="span"
                color="primary"
              >
                {" "}
                - Extra
              </Typography>
            )}
          </Typography>

          {/* Line Item Class & ID */}
          <Typography variant="body2" color="text.secondary">
            {item?.lineitemclass || manualitem?.lineitemclass}{" "}
            {item?.lineid ? ` - Line ID: ${item?.lineid}` : null}
          </Typography>

          {/* Received By & Timestamp */}
          <Typography variant="caption" color="text.secondary">
            {receivedby} - {new Date(timereceived).toLocaleString()}
          </Typography>
        </Box>

        {index !== filteredItems.length - 1 && <Divider />}
      </Box>
    );
  };

  //delete item fn
  const handleDeleteItem = async () => {
    setLoading(`Deleting ${selectedItem.item}...`);
    try {
      const deleteResponse = await axios.patch(
        "receive-inspection/delete/receive-item",
        selectedItem
      );
      openAlert({
        type: "success",
        message: `Successfully deleted ${selectedItem.item}`,
        duration: 2000,
      });
      handleStateUpdate(deleteResponse.data);
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not delete item: ${
          error?.response?.data?.msg || error?.message
        }`,
        duration: 5000,
      });
    } finally {
      setLoading("");
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Paper
        elevation={1}
        sx={{
          p: 1,
          backgroundColor: "white",
        }}
      >
        {/* Search Field */}
        <TextField
          autoComplete="off"
          size="small"
          fullWidth
          placeholder="Search received items..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value?.toUpperCase())}
        />

        <Box height={"calc(100vh - 190px)"} sx={{ overflowY: "hidden" }}>
          {filteredItems.length === 0 ? (
            <Typography
              variant="body2"
              sx={{ textAlign: "center", my: 3, color: "gray" }}
            >
              No Received Items.
            </Typography>
          ) : (
            <FixedSizeList
              itemSize={100}
              itemCount={filteredItems.length}
              height={window.innerHeight - 190}
              width="100%"
            >
              {renderRow}
            </FixedSizeList>
          )}
        </Box>
      </Paper>

      {/* Delete Confirmation Modal */}
      <Dialog open={Boolean(selectedItem)}>
        <DialogTitle>
          Are you sure you want to delete <b>{selectedItem?.item}</b>?
        </DialogTitle>
        <DialogContent>
          <FlexWrapper alignItems="center" gap={1}>
            <Warning color="error" />
            <Typography>This action is irreversible</Typography>
          </FlexWrapper>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedItem(null)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteItem} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ReceiveInspectionReceivedView;
