import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { EMAILLOGSCOLUMNS } from "../../../utils/columns/admin/adminColumns";
import { useOutletContext } from "react-router-dom";

import axios from "../../../axios/axios.config";

const AdminEmailLogsView = () => {
  const [emailLogs, setEmailLogs] = useState([]);
  const { loading, setLoading, handleAlert } = useOutletContext();
  useEffect(() => {
    getEmailLogsData();
  }, []);

  const getEmailLogsData = async () => {
    setLoading(true);
    try {
      const emailLogsResponse = await axios.get("email-logs");
      setEmailLogs(emailLogsResponse.data);
    } catch (error) {
      handleAlert({
        type: "error",
        message: `Could not get data:${
          error?.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box width="100%">
      <DataGrid
        sx={{ bgcolor: "white", height: "91vh", width: "100%" }}
        rows={emailLogs}
        columns={EMAILLOGSCOLUMNS}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 100 },
          },
        }}
        pageSizeOptions={[100, 150, 200]}
        density="compact"
        getRowId={(row) => row._id}
        slots={{ toolbar: GridToolbar }}
        disableDensitySelector
        disableColumnSelector
        slotProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true,
            csvOptions: {
              fileName: `emaillogs-${Date.now()}`,
            },
          },
        }}
        loading={loading}
      />
    </Box>
  );
};
export default AdminEmailLogsView;
