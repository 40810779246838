import { useGeneralAlert } from "../../../hooks/useGeneralAlert";
import { Delete } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";

import GeneralModalV3 from "../../general/GeneralModalV3";
import FlexWrapper from "../../FlexWrapper";
import axios from "../../../axios/axios.config.js";

const ReceiveTransactionAdminDeleteModal = ({
  order,
  _id,
  setLoading,
  tabValue,
  getReceiveData,
}) => {
  const { openAlert } = useGeneralAlert();

  const handleDelete = async () => {
    setLoading("Deleting Order...");
    try {
      //delete order
      if (tabValue === 0) {
        await axios.delete(`receive-transaction/${_id}`);
      }

      if (tabValue === 1) {
        //write delete code
        await axios.delete(`receive-inspection/delete/order/${_id}`);
      }

      //get new orders
      await getReceiveData();
      openAlert({
        type: "success",
        message: `Successfully deleted ${order}`,
        duration: 3000,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Delete ${order}: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 10000,
      });
    } finally {
      setLoading("");
    }
  };

  return (
    <GeneralModalV3
      openComponent={
        <IconButton sx={{ ml: 1 }} size="small">
          <Delete color="error" fontSize="12px" />
        </IconButton>
      }
    >
      {(handleClose) => (
        <Box>
          <Typography variant="h4" textAlign="center" py={1}>
            Delete {order}?
          </Typography>
          <Divider />

          <Typography variant="h6" textAlign="center" py={2} fontWeight="bold">
            You will no longer have access to view deleted orders
          </Typography>
          <Divider />

          <FlexWrapper justifyContent="flex-end" p={1} gap={1}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                handleClose();
                handleDelete();
              }}
            >
              Delete
            </Button>
          </FlexWrapper>
        </Box>
      )}
    </GeneralModalV3>
  );
};
export default ReceiveTransactionAdminDeleteModal;
