import { Link, Tooltip } from "@mui/material";
import ReviewCycleCountsViewColumnCounts from "../../../components/warehouse/cycle-counts/review-cycle-counts/ReviewCycleCountsViewColumnCounts";
import { Check, CheckCircle, RestartAlt } from "@mui/icons-material";

export const CYCLECOUNTSNEWJOBSCOLUMNS = [
  {
    field: "status",
    headerName: "Status",
    type: "string",
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: false,
    valueGetter: (params) => {
      return params.row?.status?.toUpperCase();
    },
  },
  {
    field: "name",
    headerName: "Name",
    type: "string",
    headerAlign: "center",
    align: "center",
    flex: 3,
    valueGetter: (params) => {
      return params.row.name;
    },
  },
  // {
  //   field: "whselocation",
  //   headerName: "Location",
  //   type: "string",
  //   headerAlign: "center",
  //   align: "center",
  //   flex: 1,
  // },
  {
    field: "jobs",
    headerName: "Line Items",
    type: "string",
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (params) => {
      return params.row?.jobs?.length || 0;
    },
  },
  {
    field: "uniquebins",
    headerName: "Unique Bins",
    type: "string",
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (params) => {
      return params.row?.uniquebins || 0;
    },
  },
  {
    field: "uniqueitems",
    headerName: "Unique Items",
    type: "string",
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (params) => {
      return params.row?.uniqueitems || 0;
    },
  },
  {
    field: "createdby",
    headerName: "Created By",
    type: "string",
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (params) => {
      return params.row.createdby.username;
    },
  },
  {
    field: "createdAt",
    headerName: "Created At",
    type: "date",
    headerAlign: "center",
    align: "center",
    flex: 2,
    valueGetter: (params) => {
      return new Date(params.row.createdAt);
    },
    valueFormatter: (valueObj) => {
      return new Date(valueObj.value).toLocaleString();
    },
  },
];

export const REVIEWCYCLECOUNTSINDIVIDUALPAGECOLUMNS = [
  {
    field: "status",
    headerName: "Status",
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (params) => {
      if (params.value === "finished") return null;
      if (params.value === "approved")
        return (
          <Tooltip title="Approved" arrow placement="top">
            <CheckCircle color="success" />
          </Tooltip>
        );
      if (params.value === "needsrecount")
        return (
          <Tooltip title="Needs Recount" arrow placement="top">
            <RestartAlt color="error" />
          </Tooltip>
        );
    },
  },
  {
    field: "bin",
    headerName: "Bin",
    type: "string",
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: true,
    valueGetter: (params) => {
      return params.row?.itemData?.itembinnumber;
    },
  },
  {
    field: "description",
    headerName: "Description",
    type: "string",
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: false,
    valueGetter: (params) => {
      return params.row?.itemData?.itemdescription;
    },
  },
  {
    field: "name",
    headerName: "Name",
    type: "string",
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: true,
    valueGetter: (params) => {
      return params.row?.itemData?.itemname;
    },
  },
  {
    field: "isnewitem",
    headerName: "Manually Added",
    type: "boolean",
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: true,
    valueGetter: (params) => params.row?.itemData?.isnewitem || false,
    renderCell: (params) => (params.value ? <Check /> : null),
  },
  {
    field: "counted",
    headerName: "Counted",
    type: "number",
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (params) => {
      return params.row?.counts?.length
        ? params.row.counts[params.row.counts.length - 1].physicalcount
        : null;
    },
  },
  {
    field: "onhand",
    headerName: "On-Hand",
    type: "number",
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (params) => {
      return params.row?.counts?.length
        ? params.row.counts[params.row.counts.length - 1].virtualqty
        : null;
    },
  },
  {
    field: "variance",
    headerName: "Variance",
    type: "number",
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (params) => {
      return params.row?.counts?.length
        ? params.row.counts[params.row.counts.length - 1].variance
        : 0;
    },
  },
  {
    field: "counts",
    headerName: "Counts",
    headerAlign: "center",
    align: "center",
    flex: 1,
    type: "number",
    renderCell: (params) => (
      <ReviewCycleCountsViewColumnCounts params={params} />
    ),
    valueFormatter: (value) => {
      return value?.value?.length ? value.value.length : 0;
    },
  },
];
