import { useState } from "react";
import { useSelector } from "react-redux";
import { Info } from "@mui/icons-material";
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";

import GeneralModalV3 from "../../../general/GeneralModalV3";

const PackProcessItemInfoModal = ({ itemObj, tabValue }) => {
  const [searchResults, setSearchResults] = useState("");
  const { packdetails, items } = useSelector((state) => state.packProcessV2);
  const item = tabValue === 0 ? itemObj.item : itemObj;

  const handleSearch = (itemVal) => {
    const foundItems = items.filter((item) => {
      return (
        item.item.toUpperCase().includes(itemVal?.item) ||
        item.upc.toUpperCase().includes(itemVal?.item) ||
        item.vpn.toUpperCase().includes(itemVal?.item) ||
        item.addtlupc?.includes(itemVal?.item)
      );
    });

    let foundBoxes = [];

    for (
      let palletnumber = 0;
      palletnumber < packdetails.length;
      palletnumber++
    ) {
      const boxes = packdetails[palletnumber]?.boxes;
      const palletId = packdetails[palletnumber]?.palletId;

      for (const box of boxes) {
        const { items, boxId } = box;
        //search items arr for the item
        const found = items
          .filter(({ item }) => {
            return (
              item.item.toUpperCase().includes(itemVal.item) ||
              item.upc.toUpperCase().includes(itemVal.item) ||
              item.vpn.toUpperCase().includes(itemVal.item) ||
              item.addtlupc?.includes(itemVal.item)
            );
          })
          .map((boxObj) => {
            return {
              boxObj,
              palletNumber: palletId,
              boxNumber: boxId,
            };
          });

        //save them to foundBoxes if found
        if (found.length) {
          foundBoxes = [...foundBoxes, ...found];
        }
      }
    }

    const finalResults = {};
    foundItems.map((obj) => {
      if (!finalResults[obj._id]) {
        finalResults[obj._id] = { item: obj, pallets: [] };
      }
    });

    foundBoxes.map(({ boxObj, palletNumber, boxNumber }) => {
      finalResults[boxObj.item._id].pallets.push({
        palletNumber,
        palletPackedAmount: boxObj.palletpackedamount,
        boxNumber,
      });
    });

    for (const pallet of packdetails) {
      for (const itemObj of pallet.items) {
        if (finalResults[itemObj.item._id]) {
          finalResults[itemObj.item._id].pallets.push({
            palletNumber: pallet.palletId,
            palletPackedAmount: itemObj.palletpackedamount,
          });
        }
      }
    }

    setSearchResults(
      finalResults[itemVal?._id]?.pallets
        .map(
          (result) =>
            `Pallet ${result.palletNumber}${
              Boolean(result?.boxNumber) ? `-Box ${result.boxNumber}` : ""
            } (${result.palletPackedAmount})`
        )
        .join(" | ")
    );
  };

  return (
    <GeneralModalV3
      openComponent={
        <IconButton size="small">
          <Info fontSize="small" color="primary" />
        </IconButton>
      }
      onOpen={() => handleSearch(item)}
    >
      <Box p={1}>
        <Stack spacing={1}>
          <Typography>
            <strong>Item: </strong>
            {item.item}
          </Typography>
          <Typography>
            <strong>Line ID: </strong>
            {item.lineid}
          </Typography>
          <Typography>
            <strong>Bin Number: </strong>
            {item.binnumber}
          </Typography>
          <Typography>
            <strong>Box Size: </strong>
            {item.boxsize}
          </Typography>
          <Typography>
            <strong>Item Fulfillment: </strong>
            {item.iful}
          </Typography>
          <Typography>
            <strong>Memo: </strong> {item.memo}
          </Typography>
          <Typography>
            <strong>Total Fulfillment Qty:</strong> {item.quantity}
          </Typography>
          <Typography>
            <strong>Packed: </strong> {item.packedamount}
          </Typography>
          <Typography>
            <strong>Remaining: </strong> {item.quantity - item.packedamount}
          </Typography>

          {searchResults && (
            <>
              <Divider flexItem />
              <Box maxHeight={100}>
                <Typography fontWeight="bold" variant="h6">
                  Packed In:
                </Typography>
                <Typography>{searchResults}</Typography>
              </Box>
            </>
          )}
        </Stack>
      </Box>
    </GeneralModalV3>
  );
};
export default PackProcessItemInfoModal;
