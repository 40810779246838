import { useCallback, useState } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import axios from "../../../axios/axios.config";

const ReceiveProcessInspectionLayout = () => {
  const [orderData, setOrderData] = useState(null);
  const [orderItems, setOrderItems] = useState(null);
  const [receivedItems, setReceivedItems] = useState(null);
  const [loading, setLoading] = useState("Loading Order...");

  const { username, currentSubsidiaryInternalId } = useSelector(
    (state) => state.user
  );

  const handleStateUpdate = (newData) => {
    setOrderItems(
      newData?.items?.sort((a, b) =>
        a.lineitemname.localeCompare(b.lineitemname)
      )
    );
    setReceivedItems(newData?.receiveditems?.reverse());
    setOrderData({
      ...newData,
      items: undefined,
      receiveditems: undefined,
    });
  };

  const handleReceive = useCallback(async () => {
    try {
      if (!orderData?.tranref) {
        throw new Error(
          "Reference field must be updated before pushing up receipt"
        );
      }

      const inOrderItems = [];
      const extraItems = [];
      const nsStorage = {};

      // Separate received items into inOrderItems and extraItems
      for (const rItem of receivedItems) {
        (rItem.ismanualaddition ? extraItems : inOrderItems).push(rItem);
      }

      // Combine in-order items by line ID and sum received quantities
      const combinednOrderItems = Object.values(
        inOrderItems.reduce((acc, curr) => {
          const lineId = curr.item.lineid;
          if (!acc[lineId]) {
            acc[lineId] = { ...curr, qtyreceived: 0 };
          }
          acc[lineId].qtyreceived += curr.qtyreceived;
          return acc;
        }, {})
      );

      const combinedItems = {};
      let inventoryAdjustmentData = null;
      let totalExtraReceived = 0;

      // Process extra items for inventory adjustment
      if (extraItems.length) {
        extraItems.forEach(({ item, manualitem, status, qtyreceived }) => {
          totalExtraReceived += qtyreceived;
          const eItem = item || manualitem;
          const { lineiteminternalid } = eItem;

          // Determine bin assignment
          const bin =
            status === "Bad-Rust"
              ? "RUST"
              : status.startsWith("RTCHINA")
              ? "RTCHINA"
              : status.startsWith("Bad")
              ? "SCRAP"
              : orderData.trantype === "transferorder" &&
                !orderData.soreference?.trim()
              ? orderData.tranid
              : `OZ_RECEIVING_${orderData.locationinternalid}`;

          const key = `${lineiteminternalid}-${bin}`;

          // Initialize object if it doesn't exist
          if (!combinedItems[key]) {
            combinedItems[key] = {
              item: lineiteminternalid,
              adjustQtyBy: 0,
              binnumbers: bin,
              location: orderData.locationinternalid,
              linememo: {}, // Store status breakdown as an object first
            };
          }

          // Clean up the status text (remove "Bad-")
          const cleanStatus = status
            .replace("Bad-", "")
            ?.replace("RTCHINA-", "");

          // Track quantity per status
          combinedItems[key].linememo[cleanStatus] =
            (combinedItems[key].linememo[cleanStatus] || 0) + qtyreceived;

          // Accumulate total quantity
          combinedItems[key].adjustQtyBy += qtyreceived;
        });

        // Format linememo: Remove qty if there's only one status
        Object.values(combinedItems).forEach((item) => {
          const statusEntries = Object.entries(item.linememo);

          item.linememo = statusEntries
            .map(([status, qty]) => `${status} (${qty})`)
            .join(" | ");
        });

        // Prepare inventory adjustment data
        inventoryAdjustmentData = {
          subsidiaryinternalid: currentSubsidiaryInternalId,
          adjlocation: orderData.locationinternalid,
          memo: `RMA Extra Items: ${
            orderData.tranid
          } (${totalExtraReceived?.toLocaleString()}) - Received by: ${username}`,
          externalid: `IADJ_${orderData.tranid}_${orderData?.entity}_${orderData?.otherrefnum}_${orderData?._id}`,
          items: Object.values(combinedItems),
          custbody_ait_linked_transaction: parseInt(orderData?.internalid),
          custbody_ait_spec_hand_notes: orderData?.tranref,
        };
      }

      // Fetch order data from NetSuite
      const nsResponse = await axios.get(
        `netsuite/receive-transaction/${orderData?.tranid}`
      );
      nsResponse.data.forEach(
        ({ lineid, lineitemqtypendingreceipt, lineitemname }) => {
          nsStorage[lineid] = nsStorage[lineid] || {
            quantity: parseInt(lineitemqtypendingreceipt),
            item: lineitemname,
          };
        }
      );

      //Validate overages
      for (const {
        item: { lineid, lineitemqtyreceived, lineitemname },
      } of combinednOrderItems) {
        if (
          !nsStorage[lineid] ||
          parseInt(lineitemqtyreceived) > nsStorage[lineid].quantity
        ) {
          throw new Error(
            `Order Line ID: ${lineid} (${lineitemname}) contains an overage of ${
              parseInt(lineitemqtyreceived) - (nsStorage[lineid]?.quantity || 0)
            }\nQuantity Received: ${parseInt(
              lineitemqtyreceived
            )}\nQuantity Remaining: ${parseInt(
              nsStorage[lineid]?.quantity || 0
            )}`
          );
        }
      }

      // Assign bins
      const goodBin =
        orderData.trantype === "transferorder" && !orderData.soreference?.trim()
          ? orderData.tranid
          : `OZ_RECEIVING_${orderData.locationinternalid}`;
      const binCounts = { RUST: 0, SCRAP: 0, [goodBin]: 0 };
      const processedItems = {};

      [...inOrderItems, ...extraItems].forEach(
        ({ item, manualitem, status }) => {
          const eItem = item || manualitem;
          const { lineiteminternalid, lineid } = eItem;
          let bin =
            status === "Bad-Rust"
              ? "RUST"
              : status.startsWith("RTCHINA")
              ? "RTCHINA"
              : status.startsWith("Bad")
              ? "SCRAP"
              : goodBin;
          binCounts[bin] = (binCounts[bin] || 0) + 1;

          if (!processedItems[lineid]) {
            processedItems[lineid] = {
              item: lineiteminternalid,
              binnumbers: new Set(),
            };
          }
          processedItems[lineid].binnumbers.add(bin);
        }
      );

      // Format bin assignments
      const binAssignmentItems = Object.values(processedItems).map(
        ({ item, binnumbers }) => ({
          item,
          binnumbers: Array.from(binnumbers).join(" "),
        })
      );

      // Prepare receipt items
      const receiptItems = {};
      let totalInOrderItems = 0;
      inOrderItems.forEach(({ item, status, qtyreceived }) => {
        const { lineid } = item;
        totalInOrderItems += qtyreceived;
        const bin =
          status === "Bad-Rust"
            ? "RUST"
            : status.startsWith("RTCHINA")
            ? "RTCHINA"
            : status.startsWith("Bad")
            ? "SCRAP"
            : goodBin;
        receiptItems[lineid] = receiptItems[lineid] || {
          itemreceive: true,
          orderLine: lineid,
          quantity: 0,
          binnumbers: {},
          custcol_sps_gen_noteinformationfield: {},
        };
        receiptItems[lineid].quantity += qtyreceived;
        receiptItems[lineid].binnumbers[bin] =
          (receiptItems[lineid].binnumbers[bin] || 0) + qtyreceived;
        receiptItems[lineid].custcol_sps_gen_noteinformationfield[status] =
          (receiptItems[lineid].custcol_sps_gen_noteinformationfield[status] ||
            0) + qtyreceived;
      });

      const formattedReceiptItems = Object.values(receiptItems).map(
        ({
          binnumbers,
          quantity,
          custcol_sps_gen_noteinformationfield,
          ...entry
        }) => ({
          ...entry,
          quantity,
          binnumbers:
            Object.entries(binnumbers).length > 1
              ? Object.entries(binnumbers)
                  .map(([bin, qty]) => `${bin}(${qty})`)
                  .join(" ")
              : Object.keys(binnumbers).join(" "),
          custcol_sps_gen_noteinformationfield: Object.entries(
            custcol_sps_gen_noteinformationfield
          )
            .map(
              ([status, qty]) =>
                `${status
                  ?.replace("Bad-", "")
                  ?.replace("RTCHINA-", "")} (${qty})`
            )
            .join(" | "),
        })
      );

      // Submit the receipt
      return (
        await axios.post(
          "multi-actions/receive-inspection/post/fulfill-order",
          {
            orderData,
            username,
            isTOBin: !goodBin.startsWith("OZ"),
            receiptItems: formattedReceiptItems,
            binAssignmentItems,
            inventoryAdjustmentData,
            totalInOrderItems,
          }
        )
      ).data;
    } catch (error) {
      throw new Error(error?.response?.data?.msg || error.message);
    }
  }, [orderData, receivedItems]);

  return (
    <Outlet
      context={{
        orderData,
        setOrderData,
        orderItems,
        setOrderItems,
        receivedItems,
        setReceivedItems,
        loading,
        setLoading,
        handleStateUpdate,
        handleReceive,
      }}
    />
  );
};
export default ReceiveProcessInspectionLayout;
