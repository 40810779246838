import { equipmentQuestions } from "../../utils/equipment/equipmentQuestions";
import { store } from "../../redux/store";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";
import { sendGeneralEmail } from "../../utils/email/generalEmailFunction";

import axios from "../../axios/axios.config";

export const equipmentCheckListFormAction = async ({ request, params }) => {
  const { username, currentDepartment, currentLocation, currentSubsidiary } =
    store.getState().user;
  const { equipmentid } = params;

  try {
    const formData = Object.fromEntries(await request.formData());
    const questions = equipmentQuestions[formData.submit].map((question) => {
      return { question, value: formData[question] ? true : false };
    });
    const newTime = new Date().toISOString(); //new equipment updated time

    const result = Object.keys(formData).length - 2 === questions.length;

    //save to mongo checklist collection
    const newChecklistResponse = await axios.post("equipment-checklist", {
      user: username,
      department: currentDepartment,
      comments: formData.comments,
      questions,
      result,
      equipmentid,
    });

    //update lastinspected, status, and result fields on equipment collection using the id
    const updatedResponse = await axios.patch(`equipment/${equipmentid}`, {
      newTime,
      result: newChecklistResponse.data.result,
      status: result ? "active" : "out of order",
    });

    //if inspection failed send email
    if (!result) {
      let toEmail = "";

      if (currentLocation === "PAR") {
        toEmail = "equipmentchecklist-notifications-park@trakmotive.com";
      }

      if (currentLocation === "NEV") {
        toEmail = "equipmentchecklist-notifications-nevada@trakmotive.com";
      }

      sendGeneralEmail({
        to: process.env.REACT_APP_TEST_EMAIL || toEmail,
        subject: "Equipment Failed Inspection",
        body: `${updatedResponse.data.equipmentid} has failed inspection by ${
          newChecklistResponse.data.user
        } on ${new Date(
          updatedResponse.data.lastinspected
        ).toLocaleString()} and requires supervisor attention.`,
        type: "Equipment Checklist",
        currentDepartment,
        currentSubsidiary,
        username,
      });
    }

    return { mongoData: newChecklistResponse.data };
  } catch (error) {
    store.dispatch(
      openGeneralAlert({
        type: "error",
        message: `Could Not Submit Form: ${error.message}`,
        duration: 5000,
      })
    );

    return { error: error.response?.data?.msg || error.message };
  }
};
