import { useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleNav } from "../../../../redux/features/nav/navBarSlice";
import { openGeneralAlert } from "../../../../redux/features/alert/alertSlice";
import { useMediaQuery } from "@mui/material";

import axios from "../../../../axios/axios.config";
import ReceiveTransactionOverageErrorModal from "../../../../components/warehouse/receive-transaction/ReceiveTransactionOverageErrorModal";

const ReceiveTransactionLayout = () => {
  const [loading, setLoading] = useState("");
  const [overageError, setOverageError] = useState("");

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const matches = useMediaQuery("(max-width:600px)");
  const { username, currentDepartment, rolelevel } = useSelector(
    (state) => state.user
  );

  //hide nav when on results page
  useEffect(() => {
    if (location?.pathname?.split("/").length === 5) {
      dispatch(toggleNav(true));
    } else {
      dispatch(toggleNav(false));
    }
  }, [location]);

  const dispatchAlert = ({ type, message, duration }) => {
    dispatch(openGeneralAlert({ type, message, duration }));
  };

  const handleItemReceipt = async ({
    id,
    mongoid,
    receiveditems,
    locationinternalid,
    trantype,
    tranid,
    condition,
    reference,
    soreference,
  }) => {
    setLoading("Receiving Transaction...");

    try {
      let totalInOrderItems = 0;
      const storage = {};
      const nsStorage = {};
      const uniqueItems = new Set();
      const binAssignmentItems = [];
      const isTOBin =
        condition !== "scrap" &&
        condition !== "rust" &&
        trantype === "transferorder" &&
        !soreference?.trim();
      //bin number will be OZ_RECEIVING_{locationinternalid}, SCRAP, Rust or TO#
      const bin = isTOBin
        ? tranid
        : condition && condition === "scrap"
        ? "SCRAP"
        : condition === "rust"
        ? "RUST"
        : `OZ_RECEIVING_${locationinternalid}`;

      if (!bin) {
        throw new Error("Could Not Get Bin");
      }

      //make a call to NetSuite to get most current data
      const response = await axios.get(
        `netsuite/receive-transaction/${tranid?.trim()}`
      );

      for (const nsItem of response.data) {
        if (!nsStorage[nsItem.lineid]) {
          nsStorage[nsItem.lineid] = parseInt(nsItem.lineitemqtypendingreceipt);
        }
      }

      //save items by line ids
      for (const scanItem of receiveditems) {
        if (!storage[scanItem.lineid]) {
          storage[scanItem.lineid] = {
            itemreceive: true,
            orderLine: scanItem.lineid,
            quantity: 0,
            binnumbers: bin,
            itemname: scanItem.item,
          };

          //only assign unique item ids
          if (!uniqueItems.has(scanItem.internalid)) {
            binAssignmentItems.push({
              item: scanItem.internalid,
              binnumbers: bin,
            });

            //add internalid to set
            uniqueItems.add(scanItem.internalid);
          }
        }

        storage[scanItem.lineid].quantity += scanItem.qtyreceived;
        totalInOrderItems += scanItem.qtyreceived;
      }

      const newItems = Object.values(storage); //receiptItems
      const newItemKeys = Object.keys(storage);

      //compare received items with ns items and check for missing items and overages
      for (const key of newItemKeys) {
        if (!nsStorage[key]) {
          throw new Error(
            `Order Line ID: ${key} (${
              storage[key].itemname
            }) contains an overage of ${parseInt(
              storage[key].quantity
            )}\nQuantity Received: ${parseInt(
              storage[key].quantity
            )}\nQuantity Remaining: 0`
          );
        }
        //check for overages
        if (parseInt(nsStorage[key]) < parseInt(storage[key].quantity)) {
          throw new Error(
            `Order Line ID: ${key} (${
              storage[key].itemname
            }) contains an overage of ${
              parseInt(storage[key].quantity) - parseInt(nsStorage[key])
            }\nQuantity Received: ${parseInt(
              storage[key].quantity
            )}\nQuantity Remaining: ${parseInt(nsStorage[key])}`
          );
        }

        delete storage[key].itemname;
      }

      const fulfillmentResponse = await axios.post(
        `multi-actions/receive-inspection/post/fulfill-order`,
        {
          orderData: {
            tranid,
            locationinternalid,
            _id: mongoid,
            internalid: id,
            trantype,
            tranref: reference,
          },
          binAssignmentItems,
          username,
          totalInOrderItems,
          receiptItems: newItems,
          type: "transaction",
          isTOBin,
        }
      );

      navigate(
        `/portal/${currentDepartment?.toLowerCase()}/receive-transaction`,
        { replace: true }
      );
      dispatchAlert({
        type: "success",
        message: `Successfully Received Transaction. Receipt ID: ${fulfillmentResponse?.data?.itemreceiptinternalid}`,
        duration: 3000,
      });
    } catch (error) {
      if (error.message?.startsWith("Order Line ID:")) {
        setOverageError(error.message);
      } else {
        dispatchAlert({
          type: "error",
          message: `${error.response?.data?.msg || error.message}`,
          duration: 10000,
        });
      }
    } finally {
      setLoading("");
    }
  };

  const handleBackToSearch = () => {
    navigate("/portal/warehouse/receive-transaction", { replace: true });
  };

  return (
    <>
      <ReceiveTransactionOverageErrorModal
        open={overageError}
        setOpen={setOverageError}
      />

      <Outlet
        context={{
          dispatchAlert,
          loading,
          setLoading,
          username,
          handleItemReceipt,
          handleBackToSearch,
          matches,
          currentDepartment,
          navigate,
          navigation,
          rolelevel,
        }}
      />
    </>
  );
};

export default ReceiveTransactionLayout;
