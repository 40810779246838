import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";

import { sendGeneralEmail } from "../../../utils/email/generalEmailFunction";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";

import GeneralModal from "../../GeneralModal";
import axios from "../../../axios/axios.config";

const MarkAsPendingDocumentsButton = ({ formid, notes, setLoading = null }) => {
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMarkAsDocumentError = async (event) => {
    event.preventDefault();
    if (setLoading) {
      setLoading("Marking Labor Claim As Pending Documents...");
    }

    try {
      const formData = new FormData(event.target);
      const emailBody = formData.get("emailbody");
      const time = new Date().toLocaleString();
      let body = "";
      let emailSending = null;

      if (!emailBody) {
        throw new Error("Email body is required");
      }

      //update mongo
      const { data } = await axios.patch(
        `forms/update/labor-claim/${formid}/returns-product/pending`,
        {
          updateData: {
            "form.status":
              user.currentDepartment === "RETURNS"
                ? "pendingdocuments"
                : "lcreceived-pendingdocuments",
            "form.rmanotes": `${notes ? `${notes}\n` : ""}${time} (${
              user.username
            }) - Labor Claim Marked As ${
              user.currentDepartment === "RETURNS"
                ? "Pending Documents"
                : "LC Received-Pending Documents"
            }\n${emailBody}`,
          },
        }
      );

      if (user.currentDepartment === "RETURNS") {
        body = `
          <p class=MsoNormal><i><span style='font-family:"Georgia",serif'>Upon reviewing attached documents needed for a labor claim we noticed the following document(s) were missing. Can you  please contact the customer and request the document(s) and forward them to <a href="mailto:RETURNS@TRAKMOTIVE.COM"><span style='color:#0563C1'>RETURNS@TRAKMOTIVE.COM</span></a></span></i></p>
          <p class=MsoNormal><i><span style='font-family:"Georgia",serif'>${emailBody.replace(
            /\n/g,
            "<br>"
          )} </span></i></br></p>
          <p class=MsoNormal><i><span style='font-family:"Georgia",serif'><i><span style='font-family:"Georgia",serif'>Thank You,</span></i></br></p>
            <p class=MsoNormal><b><i><span style='font-size:12.0pt;font-family:"Georgia",serif;color:#1F3864;mso-ligatures:none'>${
              user.firstname
            } ${
          user.lastname
        }<o:p></o:p></span></i></b></p><p class=MsoNormal><i><span style='font-size:12.0pt;font-family:"Georgia",serif;color:#2F5496;mso-ligatures:none'>Returns Clerk<o:p>
            <p class=MsoNormal><b><span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>Advanced Innovative Technology Corp.</span></b><b>
            <span style='font-family:"Georgia",serif;color:#1F497D;mso-ligatures:none'><o:p></o:p></span></b></p><p class=MsoNormal><span style='font-size:10.0pt;font-family:"Georgia",serif;mso-ligatures:none'>350 Nevada St. Redlands, CA 92373<o:p></o:p></span></p>
            <p class=MsoNormal><b><span style='font-size:10.0pt;font-family:"Georgia",serif;color:#2F5496;mso-ligatures:none'>
            <a href="mailto:${user.email}"><span style='color:#2F5496'>${
          user.email
        }</span></a>
            <o:p></o:p></span></b></p><p class=MsoNormal><span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>O 909-253-0077 Ext:120<o:p></o:p></span></p>
            <p class=MsoNormal><span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>F 417-831-9447<o:p></o:p></span></p>
            <p class=MsoNormal><a href="http://www.trakmotive.com/"><span style='font-size:10.5pt;font-family:"Georgia",serif;mso-ligatures:none;text-decoration:none'><img border=0 width=183 height=53 style='width:1.9062in;height:.552in' id="Picture_x0020_11" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/trakmotive.png"></span></a>
            <span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'><o:p></o:p></span></p><p class=MsoNormal><b><i><span style='font-family:"Georgia",serif;mso-ligatures:none'>Our Job Is To Make Your Job Easier<o:p></o:p></span></i></b></p>
            <p class=MsoNormal><b><i><span style='font-family:"Georgia",serif;mso-ligatures:none'><o:p>&nbsp;</o:p></span></i></b></p><p class=MsoNormal><a href="https://www.facebook.com/trakmotive/"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_12" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/facebook.png"></span></a>
            <span style='font-family:"Georgia",serif;mso-ligatures:none'>&nbsp; </span><a href="https://twitter.com/TrakMotiveAxles"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_13" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/twitter.png"></span></a><span style='font-family:"Georgia",serif;mso-ligatures:none'>&nbsp;&nbsp;</span>
            <a href="https://www.linkedin.com/company/advanced-innovative-technology-corp-/"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_14" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/linkedin.png"></span></a><span style='font-family:"Georgia",serif;mso-ligatures:none'>&nbsp;&nbsp;</span>
            <a href="https://www.instagram.com/trakmotive/?hl=en"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_15" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/instagram.png"></span></a><span style='font-family:"Georgia",serif;mso-ligatures:none'><o:p></o:p></span></p><p class=MsoNormal><span style='font-family:"Georgia",serif;mso-ligatures:none'><o:p>&nbsp;</o:p></span></p>
            <p class=MsoNormal><b><span style='font-size:8.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>Community &#8211; Trust &#8211; Dedication &#8211; Responsibility &#8211; Innovation &#8211; Growth<o:p></o:p></span></b></p>
            `;

        emailSending = {
          to: process.env.REACT_APP_TEST_EMAIL || data.form.warehouseEmail,
          from: "returns@trakmotive.com",
          fromname: "AIT Returns",
          cc: process.env.REACT_APP_TEST_EMAIL
            ? ""
            : data.form.additionalEmails || "",
          bcc: process.env.REACT_APP_TEST_EMAIL ? "" : "returns@trakmotive.com",
          subject: `Updated Documents Requested For Labor Claim RGS ${data.form.rgaNum}`,
          type: "Labor Claim",
          currentDepartment: user.currentDepartment,
          currentSubsidiary: user.currentSubsidiary,
          username: user.username,
        };
        emailSending.body = body;
        emailSending.isHtml = true;
      } else {
        body = `
          <p class=MsoNormal><i><span style='font-family:"Georgia",serif'>Upon reviewing attached documents needed for a labor claim we noticed the following document(s) were missing. Can you  please contact the customer and request the document(s)</span></i></p>
          <p class=MsoNormal><i><span style='font-family:"Georgia",serif'>${emailBody.replace(
            /\n/g,
            "<br>"
          )} </span></i></p>
          <p>Thank You</p>
            `;

        emailSending = {
          to: process.env.REACT_APP_TEST_EMAIL || "returns@trakmotive.com",
          subject: `Product Requested Customer Documents for RGS ${data.form.rgaNum}/${data.form.rmaNum}`,
          body,
          cc: user.email,
          isHtml: true,
          type: "Labor Claim",
          currentDepartment: user.currentDepartment,
          currentSubsidiary: user.currentSubsidiary,
          username: user.username,
        };
      }

      await sendGeneralEmail(emailSending);
      setOpen(false);

      navigate(
        `/portal/${user.currentDepartment.toLowerCase()}/forms/labor-claim`
      );

      dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully sent email and updated Labor Claim`,
          duration: 3000,
        })
      );

      if (data.form.rmainternalid) {
        //update Netsuite notes
        axios.patch(
          `netsuite/patch/rma/${data.form.rmainternalid}/update-notes`,
          {
            notes: `${data.form.rmanotes}`,
          }
        );
      }
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error, could not send email:  ${
            error.response?.data?.msg || error.message
          }`,
          duration: 5000,
        })
      );
    } finally {
      if (setLoading) {
        setLoading("");
      }
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="error"
        size="small"
        sx={{ mr: 1 }}
        onClick={() => setOpen(true)}
      >
        Mark As pending documents
      </Button>

      {/* Document Error Modal */}
      <GeneralModal open={open} handleClose={() => setOpen(false)}>
        <Typography
          variant="h4"
          textTransform="capitalize"
          textAlign="center"
          py={1}
        >
          Pending Documents
        </Typography>
        <Divider />
        <Box
          px={3}
          pb={3}
          component="form"
          onSubmit={handleMarkAsDocumentError}
        >
          {user.currentDepartment === "RETURNS" ? (
            <Typography p={3} color="error">
              An email will be sent to inform customer that there was an issue
              with the provided documents and labor claim will be updated.
              Please write the body of the email below:
            </Typography>
          ) : (
            <Typography p={3} color="error">
              An email will be sent to inform the returns department that there
              was an issue with the customer provided documents and labor claim
              will be updated. Enter missing documents.
            </Typography>
          )}

          <TextField
            fullWidth
            multiline
            required
            name="emailbody"
            minRows={4}
            label="Email Body"
            defaultValue={`ORIGINAL RECEIPT
ORIGINAL WORK ORDER (INVOICE)
REPLACEMENT RECEIPT
REPLACEMENT WORK ORDER (INVOICE)`}
            sx={{ mb: 2 }}
          />

          <Divider />
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="error"
            sx={{ mt: 2 }}
          >
            Update and send
          </Button>
        </Box>
      </GeneralModal>
    </>
  );
};
export default MarkAsPendingDocumentsButton;
