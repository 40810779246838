import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import { CheckCircle, Email } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Icon,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import ItemBinTransferWarningModal from "./ItemBinTransferWarningModal";
import axios from "../../../axios/axios.config";
import Loading from "../../Loading";
import QtyErrorModal from "./QtyErrorModal";
import FlexWrapper from "../../FlexWrapper";
import GeneralModalV3 from "../../general/GeneralModalV3";
import InventoryMoveReportBinIssue from "./InventoryMoveReportBinIssue";

const ItemBinDataScanInventoryMove = ({
  itemBinData,
  transferFn,
  setMongoData,
  mongoData,
  binMoveError,
  setBinMoveError,
  setFilteredBins,
  filteredBins,
}) => {
  const [binInput, setBinInput] = useState(mongoData?.sourcebin || "");
  const [itemQtyInput, setItemQtyInput] = useState(mongoData?.qty || "");
  const [destinationBinInput, setDestinationBinInput] = useState("");
  const [open, setOpen] = useState(false);
  const [loadingBinSubmit, setLoadingBinSubmit] = useState(false);
  const [openQtyErrorModal, setOpenQtyErrorModal] = useState("");
  const [emptyBin, setEmptyBin] = useState(
    mongoData?.emptybin?.length
      ? mongoData?.emptybin[mongoData?.emptybin?.length - 1]
      : null
  );
  const [isEmptyBinLoading, setIsEmptyBinLoading] = useState("");
  const [emptyBinError, setEmptyBinError] = useState(null);

  const dispatch = useDispatch();
  const {
    username,
    currentSubsidiaryInternalId,
    currentSubsidiary,
    currentNetsuiteLocationIds,
  } = useSelector((state) => state.user);

  const existingBin = itemBinData.find((item) => item.binnumber === binInput);
  const isValidQty =
    itemQtyInput > 0 &&
    itemQtyInput <= parseInt(existingBin?.available) &&
    !itemQtyInput.toString().includes(".");
  const existingDestinationBin =
    existingBin && isValidQty && filteredBins
      ? filteredBins.find((item) => item.binnumber === destinationBinInput)
      : null;

  //first paramter is for the ns call and second parameter is for mongo call
  const handleSubmit = async () => {
    await transferFn(
      {
        username,
        currentSubsidiaryInternalId,
        fromBin: existingBin.binnumber,
        toBin: existingDestinationBin.binnumber,
        fromLocation: existingBin.binlocationid,
        toLocation: existingDestinationBin.binlocationid,
        item: itemBinData[0].iteminternalid,
        itemQty: itemQtyInput,
        externalid: `INVMOV_${mongoData?._id}`,
      },
      {
        binnumber: existingDestinationBin.binnumber,
        available: existingDestinationBin.available,
        binid: existingDestinationBin.binid,
        binlocation: existingDestinationBin.binlocation,
        binlocationid: existingDestinationBin.binlocationid,
        preferredbin: existingDestinationBin.preferredbin,
      }
    );
  };
  //submit the empty bin
  const handleSubmitEmptyBin = async () => {
    await transferFn(
      {
        username,
        currentSubsidiaryInternalId,
        fromBin: existingBin.binnumber,
        toBin: emptyBin.binnumber,
        fromLocation: existingBin.binlocationid,
        toLocation: emptyBin.binlocationid,
        item: itemBinData[0].iteminternalid,
        itemQty: itemQtyInput,
        externalid: `INVMOV_${mongoData?._id}`,
      },
      {
        binnumber: emptyBin.binnumber,
        available: 0,
        binid: emptyBin.bininternalid,
        binlocation: emptyBin.binlocationname,
        binlocationid: emptyBin.binlocationid,
        preferredbin: emptyBin.preferredbin || "",
      }
    );
  };

  const handleSubmitSourceBin = async () => {
    // setLoadingBinSubmit(true);
    try {
      const currentNSQuantity = parseInt(existingBin["available"]);
      const currentPickedQty = await getTotalItemQtyCurrentlyBeingPicked();
      const currentMoveQty = await getTotalItemQtyCurrentlyBeingMoved();
      const currentVirtualQty =
        currentNSQuantity - currentPickedQty - currentMoveQty;
      const userCount = parseInt(itemQtyInput);

      //throw error to open qty erro modal
      if (userCount > currentVirtualQty)
        throw new Error(
          `Quantity entered exceeds net available quantity (${currentVirtualQty})`
        );

      const mongoResponse = await axios.patch(
        `inventory-move/${mongoData._id}`,
        {
          sourcebin: {
            binnumber: existingBin.binnumber,
            available: existingBin.available,
            binid: existingBin.binid,
            binlocation: existingBin.binlocation,
            binboxsizetype: existingBin.binboxsizetype,
            binlocationid: existingBin.binlocationid,
            binbrand: existingBin.binbrand,
            preferredbin: existingBin.preferredbin,
            itembrand: existingBin.itembrand,
            itemboxsizetype: existingBin.itemboxsizetype,
          },
          qtytomove: parseInt(itemQtyInput),
          status: "incomplete",
        }
      );

      //filter by brand only
      setFilteredBins(
        itemBinData.filter((item) => {
          if (item.binnumber === existingBin.binnumber) return false;

          return (
            !item.binbrand ||
            !existingBin.itembrand ||
            !item.binboxsizetype ||
            !existingBin.itemboxsizetype ||
            item.binbrand?.includes(existingBin.itembrand)
          );
        })
      );

      //Filter by brand and bin type (old code)
      // setFilteredBins(
      //   itemBinData.filter((item) => {
      //     if (item.binnumber === existingBin.binnumber) return false;

      //     return (
      //       !item.binbrand ||
      //       !existingBin.itembrand ||
      //       !item.binboxsizetype ||
      //       !existingBin.itemboxsizetype ||
      //       (item.binbrand?.includes(existingBin.itembrand) &&
      //         item.binboxsizetype?.includes(existingBin.itemboxsizetype))
      //     );
      //   })
      // );

      setMongoData({
        ...mongoData,
        status: mongoResponse.data.status,
        sourcebin: mongoResponse.data.sourcebin.binnumber,
        qty: mongoResponse.data.qtytomove,
        emptybin: mongoResponse.data.emptybin,
      });
    } catch (error) {
      if (
        error.message.includes(
          "Quantity entered exceeds net available quantity"
        )
      ) {
        //set value to open modal
        setOpenQtyErrorModal(error.message);
      } else {
        dispatch(
          openGeneralAlert({
            type: "error",
            message: error.message,
            duration: 5000,
          })
        );
      }
    } finally {
      setLoadingBinSubmit(false);
    }
  };

  const getTotalItemQtyCurrentlyBeingPicked = async () => {
    try {
      const { data } = await axios.get(
        `pick-order/get/total-item-bin-qty?subsidiary=${currentSubsidiary}&locationinternalid=${parseInt(
          existingBin.binlocationid
        )}&lineiteminternalid=${parseInt(
          existingBin.iteminternalid
        )}&bininternalid=${parseInt(existingBin.binid)}`
      );

      if (data?.totalQty) {
        return data.totalQty;
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  };

  const getTotalItemQtyCurrentlyBeingMoved = async () => {
    try {
      const { data } = await axios.get(
        `pick-order/get/total-inventory-move-qty?subsidiary=${currentSubsidiary}&locationinternalid=${parseInt(
          existingBin.binlocationid
        )}&lineiteminternalid=${parseInt(
          existingBin.iteminternalid
        )}&bininternalid=${parseInt(existingBin.binid)}`
      );

      if (data?.totalQtyBeingMoved) {
        return data.totalQtyBeingMoved;
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  };

  const handleFindEmptyBin = async () => {
    const { itembrand, itemboxsizetype } = existingBin;
    setIsEmptyBinLoading("Loading Bin...");
    try {
      const newBinResponse = await axios.get(
        `inventory-move/empty-bins/v2?nsLocations=${currentNetsuiteLocationIds?.join(
          ","
        )}&itembrand=${itembrand}&itemboxsizetype=${itemboxsizetype}&username=${username}&_id=${
          mongoData?._id
        }`
      );

      setEmptyBin(newBinResponse.data);
    } catch (error) {
      setEmptyBinError(error.response?.data?.msg || error.message);
    } finally {
      setIsEmptyBinLoading("");
    }
  };

  if (loadingBinSubmit)
    return (
      <Box width="100%">
        <Loading message="Submitting Source Bin" />
      </Box>
    );

  return (
    <>
      <ItemBinTransferWarningModal
        open={open}
        setOpen={setOpen}
        destinationBin={destinationBinInput}
        setBinMoveError={setBinMoveError}
        existingBin={existingBin}
        itemQty={itemQtyInput}
        item={itemBinData[0].iteminternalid}
        itemName={itemBinData[0].itemname}
        transferFn={transferFn}
        setFilteredBins={setFilteredBins}
        mongo_id={mongoData?._id}
      />

      <Stack direction="column" spacing={1} width="100%">
        <TextField
          size="small"
          disabled={mongoData.status === "incomplete"}
          autoFocus={Boolean(!existingBin)}
          label="Source Bin"
          value={binInput}
          onChange={(event) => setBinInput(event.target.value.toUpperCase())}
          error={binInput !== "" && !existingBin}
          helperText={
            binInput !== "" && !existingBin
              ? "Please Scan A Valid Source Bin"
              : ""
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {binInput !== "" && existingBin ? (
                  <Icon>
                    <CheckCircle color="success" />
                  </Icon>
                ) : null}
              </InputAdornment>
            ),
          }}
        />
        {existingBin && (
          <>
            <QtyErrorModal
              openVal={openQtyErrorModal}
              setOpenQtyErrorModal={setOpenQtyErrorModal}
            />
            <TextField
              size="small"
              disabled={mongoData.status === "incomplete"}
              autoFocus={Boolean(existingBin)}
              type="number"
              inputMode="numeric"
              label="Item Quantity"
              fullWidth
              value={itemQtyInput}
              onChange={(event) => setItemQtyInput(event.target.value)}
              inputProps={{
                min: 1,
                max: parseInt(existingBin.available),
                step: 1,
                pattern: /^\d*$/,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isValidQty ? (
                      <Icon>
                        <CheckCircle color="success" />
                      </Icon>
                    ) : null}
                  </InputAdornment>
                ),
              }}
              error={itemQtyInput !== "" && !isValidQty}
              helperText={
                itemQtyInput !== "" && !isValidQty ? "Invalid Item Qty" : ""
              }
            />

            {existingBin && isValidQty && mongoData.status === "new" && (
              <Button variant="contained" onClick={handleSubmitSourceBin}>
                Continue
              </Button>
            )}

            {existingBin && isValidQty && mongoData.status === "incomplete" && (
              <>
                <TextField
                  size="small"
                  disabled={Boolean(isEmptyBinLoading)}
                  autoFocus={mongoData.status === "incomplete"}
                  fullWidth
                  label="Destination Bin"
                  value={destinationBinInput}
                  onChange={(event) => {
                    setDestinationBinInput(event.target.value.toUpperCase());
                    if (binMoveError) setBinMoveError("");
                  }}
                  error={Boolean(binMoveError)}
                  helperText={
                    emptyBin && !binMoveError
                      ? "Destination bin must match suggested empty bin"
                      : binMoveError
                      ? binMoveError
                      : destinationBinInput !== "" && !existingDestinationBin
                      ? "Bin is not in current item bins"
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {(destinationBinInput !== "" &&
                          !emptyBin &&
                          existingDestinationBin) ||
                        emptyBin?.binnumber === destinationBinInput ? (
                          <Icon>
                            <CheckCircle color="success" />
                          </Icon>
                        ) : null}
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
          </>
        )}
        {!emptyBin &&
          mongoData.status === "incomplete" &&
          destinationBinInput !== "" &&
          existingBin &&
          isValidQty &&
          !binMoveError &&
          (!existingDestinationBin ? (
            <Button
              variant="contained"
              onClick={() => {
                setOpen(true);
              }}
            >
              Complete Move
            </Button>
          ) : (
            <Button variant="contained" onClick={handleSubmit}>
              Complete Move
            </Button>
          ))}

        {emptyBin && emptyBin?.binnumber === destinationBinInput && (
          <Button variant="contained" onClick={handleSubmitEmptyBin}>
            Complete Move
          </Button>
        )}

        {parseInt(itemQtyInput) >= itemBinData[0].itempalletqty / 2 &&
          mongoData.status === "incomplete" &&
          existingBin &&
          isValidQty && (
            <Box>
              <Divider />

              <Typography py={0.5} variant="h6" textAlign="center">
                Or Find Empty Bin
              </Typography>

              <FlexWrapper gap={1} alignItems="center" justifyContent="center">
                <Typography color="text.secondary">
                  Suggested Empty Bin:{" "}
                </Typography>
                {emptyBin && !Boolean(isEmptyBinLoading) ? (
                  <>
                    <Typography color="green" fontWeight="bold">
                      {emptyBin?.binnumber}
                    </Typography>

                    <InventoryMoveReportBinIssue
                      emptyBin={emptyBin}
                      handleFindEmptyBin={handleFindEmptyBin}
                    />
                  </>
                ) : (
                  <>
                    {Boolean(isEmptyBinLoading) && (
                      <Typography>{isEmptyBinLoading}</Typography>
                    )}
                    {Boolean(emptyBinError) && (
                      <Typography color="error">{emptyBinError}</Typography>
                    )}
                    {!Boolean(isEmptyBinLoading) && !Boolean(emptyBinError) && (
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={handleFindEmptyBin}
                      >
                        Find Bin
                      </Button>
                    )}
                  </>
                )}
              </FlexWrapper>
            </Box>
          )}
      </Stack>
    </>
  );
};
export default ItemBinDataScanInventoryMove;
