import { useRef, useState } from "react";
import { InsertDriveFile, Close } from "@mui/icons-material";
import { resizeFile } from "../../../utils/files/resizeFile";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Grid,
  Tooltip,
  Divider,
} from "@mui/material";
import Loading from "../../Loading";

const GeneralFileUploadNative = ({
  multiple = false,
  disabled = false,
  required = false,
  capture = "environment",
  name = "native-file-input",
  onFilesSelected = null,
  maxFiles = 50,
}) => {
  const [files, setFiles] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // Ref for file input
  const fileInputRef = useRef(null);

  const handleFileChange = async (event) => {
    setLoading(true);
    if (error) setError("");

    try {
      const selectedFiles = Array.from(event.target.files);
      const updatedFiles = [];

      for (const f of selectedFiles) {
        if (f && f.type.startsWith("image/")) {
          const resizedFile = await resizeFile({
            file: new File([f], f.name, {
              type: f.type,
              lastModified: f.lastModified,
            }),
            maxWidth: 2500,
            maxHeight: 2500,
            compressFormat: "jpeg",
            quality: 80,
            outputType: "file",
          });
          updatedFiles.push(resizedFile);
        } else {
          updatedFiles.push(f);
        }
      }

      const finalFiles = multiple
        ? [...files, ...updatedFiles].slice(0, maxFiles)
        : updatedFiles.slice(0, maxFiles);

      if (onFilesSelected && typeof onFilesSelected === "function") {
        onFilesSelected(finalFiles);
      }
      setFiles(finalFiles);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
    setLoading(true);

    if (error) setError("");

    try {
      const droppedFiles = Array.from(event.dataTransfer.files);
      const updatedFiles = [];

      for (const f of droppedFiles) {
        if (f && f.type.startsWith("image/")) {
          const resizedFile = await resizeFile({
            file: new File([f], f.name, {
              type: f.type,
              lastModified: f.lastModified,
            }),
            maxWidth: 2500,
            maxHeight: 2500,
            compressFormat: "JPEG",
            quality: 80,
            outputType: "file",
          });
          updatedFiles.push(resizedFile);
        } else {
          updatedFiles.push(f);
        }
      }

      const finalFiles = multiple
        ? [...files, ...updatedFiles].slice(0, maxFiles)
        : updatedFiles.slice(0, maxFiles);

      if (onFilesSelected && typeof onFilesSelected === "function") {
        onFilesSelected(finalFiles);
      }
      setFiles(finalFiles);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (event) => {
    event.stopPropagation();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const removeFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    if (onFilesSelected) onFilesSelected(updatedFiles);
  };

  return (
    <Box
      sx={{
        position: "relative",
        border: dragging ? "2px dashed #1976d2" : "2px dashed #ccc",
        borderRadius: 2,
        p: 3,
        textAlign: "center",
        cursor: disabled ? "not-allowed" : "pointer",
        backgroundColor: dragging ? "#f0f7ff" : "#fafafa",
        "&:hover": { borderColor: "#1976d2" },
        overflow: "hidden",
      }}
      onDragOver={(e) => {
        e.preventDefault();
        setDragging(true);
      }}
      onDragLeave={() => setDragging(false)}
      onDrop={handleDrop}
      onClick={handleClick}
    >
      <Typography variant="body1" color="textSecondary">
        Drag & Drop files here or Click to Browse
      </Typography>

      <Box display="flex" flexDirection="column" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 1 }}
          disabled={disabled || files.length >= maxFiles}
        >
          {files.length >= maxFiles ? "Max Files Reached" : "Browse Files"}
        </Button>
        <input
          type="file"
          required={required}
          name={name}
          multiple={multiple}
          capture={capture}
          style={{ display: "none" }}
          ref={fileInputRef} // Using ref instead of document.getElementById
          onChange={handleFileChange}
          disabled={disabled}
        />
        {Boolean(error) && (
          <Typography pt={1} variant="caption" color="error">
            {error}
          </Typography>
        )}
      </Box>

      {loading ? (
        <Box maxHeight={150} pt={3}>
          <Loading height="100%" message="Loading Files..." />
        </Box>
      ) : null}

      {files.length > 0 && !loading && (
        <Grid
          container
          spacing={1}
          sx={{
            mt: 1,
            maxHeight: 250,
            px: 1,
            overflowY: "auto",
            borderRadius: 1,
            backgroundColor: "#f8f8f8",
          }}
        >
          <Grid item xs={12}>
            <Divider flexItem />
          </Grid>
          {files.map((file, index) => (
            <Grid item xs={3} sm={2} md={3} key={index}>
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  aspectRatio: "1 / 1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#e3f2fd",
                  borderRadius: 1,
                  boxShadow: 1,
                  textAlign: "center",
                  p: 1,
                }}
              >
                <Tooltip title={file.name}>
                  <InsertDriveFile fontSize="large" color="primary" />
                </Tooltip>
                <Typography
                  variant="caption"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "90%",
                  }}
                >
                  {file.name}
                </Typography>
                <IconButton
                  size="small"
                  sx={{
                    position: "absolute",
                    top: -5,
                    right: -5,
                    backgroundColor: "rgba(0,0,0,0.7)",
                    color: "white",
                    "&:hover": { backgroundColor: "black" },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    removeFile(index);
                  }}
                  disabled={disabled}
                >
                  <Close fontSize="small" />
                </IconButton>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default GeneralFileUploadNative;
