import { useState } from "react";
import { useSelector } from "react-redux";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import { handlePrintContentLabels } from "../../../../utils/printer-functions/printContentLabels";
import { printPickOrderPalletLabel } from "../../../../utils/printer-functions/printPickOrderPalletLabel";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import { printGeneralV2 } from "../../../../utils/printer-functions/printGeneralV2";
import {
  ArrowBackIos,
  ArrowForwardIos,
  ExitToApp,
  Print,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import Loading from "../../../Loading";
import axios from "../../../../axios/axios.config";
import FlexWrapper from "../../../FlexWrapper";
import EditDiscrepanciesModal from "../../../warehouse/pack-process/update-pack-order/EditDiscrepanciesModal";
import ViewAWSImages from "../../../warehouse/pack-process/update-pack-order/ViewAWSImages";
import GeneralModalV3 from "../../../general/GeneralModalV3";
import GeneralFileUploadNative from "../../../general/files/GeneralFileUploadNative";

const UpdatePackResultsV2 = ({ data }) => {
  const navigate = useNavigate();
  const navigation = useNavigation();
  const { openAlert } = useGeneralAlert();
  const { orderid } = useParams();
  const {
    username,
    currentDepartment,
    currentLocationInternalId,
    currentSubsidiary,
  } = useSelector((state) => state.user);
  const { currentPrinter } = useSelector((state) => state.printers);

  const [palletData, setPalletData] = useState(data?.packDetails);
  const [currentPalletIndex, setCurrentPalletIndex] = useState(0);
  const [currentBoxIndex, setCurrentBoxIndex] = useState(0);
  const [loading, setLoading] = useState("");
  const [discrepancies, setDiscrepanices] = useState(data?.discrepancies || 0);
  const [isOrderUpdated, setIsOrderUpdated] = useState(false);

  const currentPallet = palletData[currentPalletIndex];
  const currentImages = palletData[currentPalletIndex]?.images;
  const currentBox = currentPallet?.boxes?.length
    ? currentPallet?.boxes[currentBoxIndex]
    : null;

  if (navigation.state === "loading") {
    return <Loading message="Navigating..." />;
  }

  if (loading) {
    return <Loading message={loading} />;
  }

  const handleInputChanges = (event, type = "pallet") => {
    const newData = [...palletData];
    if (type === "pallet") {
      if (
        event.target.name === "discrepancies" ||
        event.target.name === "weight"
      ) {
        newData[currentPalletIndex][event.target.name] = event.target.value;
      } else {
        newData[currentPalletIndex][event.target.name] = event.target.value;
      }
    } else {
      //updating box
      if (event.target.name === "weight") {
        newData[currentPalletIndex].boxes[currentBoxIndex][event.target.name] =
          parseInt(event.target.value);
      } else {
        newData[currentPalletIndex].boxes[currentBoxIndex][event.target.name] =
          event.target.value;
      }
    }
    newData[currentPalletIndex].isUpdated = true;
    setPalletData(newData);
  };

  const handlePrevPallet = async () => {
    try {
      if (currentPalletIndex === 0) return;

      if (currentPallet?.isUpdated) {
        await handleUpdateSinglePalletData();
        setCurrentPalletIndex(currentPalletIndex - 1);
        setCurrentBoxIndex(0);
      } else {
        setCurrentPalletIndex(currentPalletIndex - 1);
        setCurrentBoxIndex(0);
      }
    } catch (error) {
      return;
    }
  };

  const handleNextPallet = async () => {
    try {
      if (currentPalletIndex === data?.packDetails?.length - 1) return;

      if (currentPallet?.isUpdated) {
        await handleUpdateSinglePalletData();
        setCurrentPalletIndex(currentPalletIndex + 1);
        setCurrentBoxIndex(0);
      } else {
        setCurrentPalletIndex(currentPalletIndex + 1);
        setCurrentBoxIndex(0);
      }
    } catch (error) {
      return;
    }
  };

  //will be called on save pallet button or when user clicks arrow
  const handleUpdateSinglePalletData = async () => {
    setLoading(`Updating Pallet ${currentPallet?.palletId}...`);
    try {
      //check for correct pallet weight and dimensions
      //we will ignore box checking
      if (currentPallet?.weight <= 1 || !currentPallet?.dimensions) {
        throw new Error(
          `Pallet ${currentPallet?.palletId} Weight or Dimensions are invalid `
        );
      }

      //Save File to S3
      const imageKeys = [];
      const formData = new FormData();
      const folder = `warehouse/packprocess/palletimages/${data?.order}/`;
      formData.append("folder", folder);

      //save files to S3 if new images are uploaded
      if (currentPallet?.newImages?.length) {
        //only errors out when images are required. Only checks pallets that are updated by user, others ignored
        if (
          data?.isImagesRequired === "T" &&
          currentPallet?.images?.length + currentPallet?.newImages?.length < 4
        ) {
          throw new Error(
            `Pallet ${currentPallet?.palletId} requires at least 4 images`
          );
        }

        currentPallet?.newImages?.forEach((file) => {
          const imageKey = `${file.name}`;
          //will store keys in mongo to generate URLs once saved
          imageKeys.push(folder + imageKey);
          formData.append("files", file);
        });

        //upload images to s3
        await axios.post("/files/upload/multi", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }

      //update mongo with updated pallet info
      const response = await axios.patch(
        `pack-process-v2/update-pack-order/update-single-pallet-details/${orderid}`,
        {
          palletId: currentPallet?._id,
          boxId: currentBox?._id || null,
          newPalletWeight: currentPallet?.weight,
          newPalletDimensions: currentPallet?.dimensions,
          newBoxWeight: currentBox?.weight || null,
          newBoxDimensions: currentBox?.dimensions || null,
          newImages: [...currentPallet?.images, ...imageKeys],
        }
      );
      setPalletData(
        response.data.packdetails.map((obj) => {
          return { ...obj, newImages: [], isUpdated: false };
        })
      );
      //marks order updated for tran packages
      if (isOrderUpdated === false) {
        setIsOrderUpdated(true);
      }
      openAlert({
        type: "success",
        message: `Successfully Updated Pallet`,
        duration: 1000,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Update Pallet ${currentPallet?.palletId}: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
      throw error;
    } finally {
      setLoading("");
    }
  };
  //prints single pallet label
  const printContentLabels = async (pallet = null) => {
    const currentPalletData = pallet ? pallet : currentPallet;

    //new template 300dpi
    const templateHeader = `^XA
^MMT^PW1200^LL1800^LS0^POI^LH0,0    
^FT0,125^FB1175,1,0,C,0^A0N,115^FDCONTENTS^FS
^FT0,325^FB1175,1,0,C,0^A0N,225^FD${data?.order}^FS
^FT0,443^FB1175,1,0,C,0^A0N,85^FDPO#: ${data?.ponumber}^FS
^FT15,554^FB1175,1,0,C,0^A0N,75^FDPALLET: ${
      currentPalletData?.palletId < 9
        ? `0${currentPalletData?.palletId}`
        : currentPalletData?.palletId
    }^FS
^FT0,591^GB1212,1,6,B^FS
^FT0,665^FB185,1,0,C,0^A0N,45^FDQuantity^FS
^FT185,665^FB443,1,0,L,0^A0N,45^FDPart Number^FS
^FT628,665^FB185,1,0,C,0^A0N,45^FDQuantity^FS
^FT813,665^FB443,1,0,L,0^A0N,45^FDPart Number^FS`;

    const euTemplateHeader = `^XA
^MMT^PW1770^LL2480^LS0    
^PON^LH0,0
^FT0,175^FB1770,1,0,C,0^A0N,140^FDCONTENTS^FS
^FT0,420^FB1770,1,0,C,0^A0N,270^FD${data?.order}^FS
^FT0,570^FB1770,1,0,C,0^A0N,120^FDPO#: ${data?.ponumber}^FS
^FT15,690^FB1770,1,0,C,0^A0N,90^FDPALLET: ${
      currentPalletData?.palletId < 9
        ? `0${currentPalletData?.palletId}`
        : currentPalletData?.palletId
    }^FS
^FT0,740^GB1800,1,6,B^FS
^FT50,800^FB185,1,0,C,0^A0N,55^FDQuantity^FS
^FT275,800^FB443,1,0,L,0^A0N,55^FDPart Number^FS
^FT850,800^FB185,1,0,C,0^A0N,55^FDQuantity^FS
^FT1075,800^FB443,1,0,L,0^A0N,55^FDPart Number^FS`;

    const currentPalletItems = currentPalletData?.items;
    const currentPalletBoxes = currentPalletData?.boxes;
    let finalItems = [];

    if (currentPalletItems.length) {
      finalItems = [...currentPalletItems];
    }

    if (currentPalletBoxes?.length) {
      currentPalletBoxes.map((boxObj) => {
        if (boxObj.items.length) {
          finalItems = [...finalItems, ...boxObj.items];
        }
      });
    }

    if (!pallet) {
      openAlert({
        type: "success",
        message: `Printing Pallet ${currentPalletData?.palletId} Content Labels...`,
        duration: 2000,
      });
    }

    try {
      const zpl = await handlePrintContentLabels({
        currentSubsidiary,
        colSize: currentSubsidiary === "WGE" ? 27 : 22,
        templateHeader:
          currentSubsidiary === "WGE" ? euTemplateHeader : templateHeader,
        currentPrinter,
        startingArr: finalItems,
        title: `Print Content Labels for Pallet ${currentPalletData?.palletId}-${data?.order}`,
        type: !pallet ? "print" : "zpl",
      });
      return zpl;
    } catch (error) {
      openAlert({
        type: "error",
        message: error?.response?.data?.msg || error.message,
        duration: 5000,
      });
    }
  };
  //print pick ticket first then print all pallet contents labels
  const printWholeOrderPallet = async () => {
    try {
      //final zpl code to send to printnode
      let finalZPLCode = "";

      openAlert({
        type: "success",
        message: "Printing All Labels...",
        duration: 2000,
      });

      //get data from pick order
      const response = await axios.get(
        `pick-order/get/order-number/${data.order}?shipmentsequence=${data.shipmentsequence}`
      );
      const pickOrder = response.data?.length ? response.data[0] : null;

      if (pickOrder) {
        //return pick label zpl first
        const pickZPL = await printPickOrderPalletLabel({
          currentPrinter,
          tranid: pickOrder.tranid,
          username,
          brand: pickOrder.brandpackaging,
          shippingAddress: pickOrder.shippingaddress,
          poNumber: pickOrder.ponumber,
          shipMethod: pickOrder.shipmethod,
          warehouseNotes: pickOrder.warehousenotes,
          alertFn: openAlert,
          title: "Printing Pick Label (From Update Pack)",
          currentLocationInternalId,
          pickQty: pickOrder.projectedpickqty,
          relatedtransferorders: pickOrder.relatedtransferorders,
          mustshipwith: pickOrder.mustshipwith,
          type: "zpl", //returns zpl code instead of printing
        });

        finalZPLCode += pickZPL;
      } else {
        openAlert({
          type: "warning",
          message: "Could Not Print Pick Label. Pick Order Not Found.",
          duration: 4000,
        });
      }

      //print pallet contents for each pallet
      for (const pallet of palletData) {
        const zplCode = await printContentLabels(pallet);
        finalZPLCode += zplCode;
      }

      await printGeneralV2({
        currentPrinter,
        template: finalZPLCode,
        title: `Print All Labels Fn ${data.order} - Update Pack Order Page`,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Print Order Labels: ${
          error?.response?.data?.msg || error.message
        }`,
        duration: 5000,
      });
    }
  };
  //creates tran packages in NetSuite
  const createTranPackages = async () => {
    try {
      if (currentPallet?.isUpdated) await handleUpdateSinglePalletData(); //save pallet if updated

      setLoading("Creating Transaction Packages...");
      const recordResponse = await axios.post(
        "netsuite/update-pack-details/create/transaction-packages",
        {
          username,
          createdfrom: data?.order,
          shipmentsequence: data?.shipmentsequence,
          discrepancies,
          packdetails: palletData,
        }
      );

      navigate(`/portal/${currentDepartment?.toLowerCase()}/update-pack-order`);
      openAlert({
        type: "success",
        message: `Successfully Created Transaction Packages: ${recordResponse.data?.recordid}`,
        duration: 5000,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not create transaction packages: ${
          error?.response?.data?.msg || error.message
        }`,
        duration: 5000,
      });
    } finally {
      setLoading("");
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h5" textAlign="center" gutterBottom>
        {data?.order} - {data?.brand}
      </Typography>

      <FlexWrapper alignItems="center" justifyContent="space-between" gap={1}>
        <FlexWrapper>
          <GeneralModalV3
            openComponent={
              <IconButton>
                <ExitToApp color="error" />
              </IconButton>
            }
          >
            {(handleClose) => (
              <Box>
                <Typography textAlign="center" variant="h5" p={1}>
                  {isOrderUpdated ||
                  parseInt(discrepancies) !== parseInt(data?.discrepancies)
                    ? "Update Transaction Packages"
                    : "Exit Page?"}
                </Typography>
                <Divider />
                <Typography fontWeight="bold" textAlign="center" p={1.5}>
                  {isOrderUpdated ||
                  parseInt(discrepancies) !== parseInt(data?.discrepancies)
                    ? "Transaction packages must be updated before exiting page."
                    : "Make sure you have submitted data before exiting. All unsaved data will be lost."}
                </Typography>
                <Divider />
                <FlexWrapper justifyContent="center">
                  {isOrderUpdated ||
                  parseInt(discrepancies) !== parseInt(data?.discrepancies) ? (
                    <Button
                      sx={{ mt: 1 }}
                      variant="contained"
                      color="success"
                      onClick={() => {
                        handleClose();
                        createTranPackages();
                      }}
                    >
                      Create Transaction Packages
                    </Button>
                  ) : (
                    <Button
                      disabled={isOrderUpdated}
                      sx={{ mt: 1, mr: 1 }}
                      size="large"
                      color="error"
                      variant="contained"
                      onClick={() => {
                        handleClose();
                        navigate(
                          `/portal/${currentDepartment?.toLowerCase()}/update-pack-order`
                        );
                      }}
                    >
                      Exit
                    </Button>
                  )}
                </FlexWrapper>
              </Box>
            )}
          </GeneralModalV3>
          <Divider flexItem orientation="vertical" />
          {/* Discrepanices Modal*/}
          <EditDiscrepanciesModal
            discrepancies={discrepancies}
            setDiscrepanices={setDiscrepanices}
            openAlert={openAlert}
            id={orderid}
            type="v2"
          />
        </FlexWrapper>

        <Button
          size="small"
          variant="contained"
          onClick={printWholeOrderPallet}
        >
          Print All Labels
        </Button>
      </FlexWrapper>

      {/* Pallet Changer goes here */}
      <Box display="flex" alignItems="center" justifyContent="center" py={2}>
        <IconButton
          disabled={currentPalletIndex === 0}
          onClick={handlePrevPallet}
        >
          <Tooltip title="Previous" arrow placement="top">
            <ArrowBackIos />
          </Tooltip>
        </IconButton>

        <FormControl variant="outlined" sx={{ minWidth: 200, mx: 1 }}>
          <Select
            labelId="pallet-select-label"
            value={currentPalletIndex}
            onChange={async (event) => {
              try {
                if (currentPallet?.isUpdated) {
                  await handleUpdateSinglePalletData();
                  setCurrentPalletIndex(event.target.value);
                  setCurrentBoxIndex(0);
                } else {
                  setCurrentPalletIndex(event.target.value);
                  setCurrentBoxIndex(0);
                }
              } catch (error) {
                return;
              }
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
          >
            {data?.packDetails?.map((pallet, index) => (
              <MenuItem key={pallet._id} value={index}>
                Pallet {pallet?.palletId}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <IconButton
          onClick={handleNextPallet}
          disabled={currentPalletIndex === data?.packDetails?.length - 1}
        >
          <Tooltip title="Next" arrow placement="top">
            <ArrowForwardIos />
          </Tooltip>
        </IconButton>
      </Box>

      <Box pt={1}>
        <FlexWrapper>
          <Typography variant="h5" gutterBottom pb={1}>
            Pallet Information{" "}
            <Typography
              component="span"
              variant="caption"
              fontWeight="bold"
              pr={1}
            >
              (Pallet Qty: {currentPallet.totalitemquantity} )
            </Typography>
          </Typography>

          <IconButton
            color="primary"
            disabled={!Boolean(currentPallet.totalitemquantity)}
            onClick={() => printContentLabels()}
          >
            <Tooltip title="Print Pallet Content Labels" arrow placement="top">
              <Print />
            </Tooltip>
          </IconButton>
        </FlexWrapper>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled
              required
              type="number"
              label="Pallet Number"
              InputLabelProps={{
                shrink: true,
              }}
              value={currentPallet?.palletId}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              label="Enter Weight"
              name="weight"
              type="number"
              inputMode="numeric"
              inputProps={{ min: 1 }}
              value={currentPallet?.weight}
              onChange={(event) => handleInputChanges(event)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              name="dimensions"
              label="Enter Dimensions"
              type="text"
              value={currentPallet?.dimensions}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => handleInputChanges(event)}
            />
          </Grid>
        </Grid>
      </Box>
      {/* Box Details */}
      {Boolean(currentBox) && (
        <Box pt={1}>
          <FlexWrapper>
            <Typography variant="h5" pb={1}>
              Box Information{" "}
              <Typography
                component="span"
                variant="caption"
                fontWeight="bold"
                pr={1}
              >
                (Box Qty: {currentBox?.boxpackedamount} )
              </Typography>
            </Typography>
          </FlexWrapper>
          <Stack pb={2} spacing={1} direction="row" flexWrap="wrap">
            {currentPallet?.boxes?.map((box, index) => (
              <Chip
                key={box._id}
                id={box.boxId}
                label={`Box ${box.boxId}`}
                color={"info"}
                variant={index === currentBoxIndex ? "filled" : "outlined"}
                onClick={async () => {
                  try {
                    if (currentPallet?.isUpdated) {
                      await handleUpdateSinglePalletData();
                      setCurrentBoxIndex(index);
                    } else {
                      setCurrentBoxIndex(index);
                    }
                  } catch (error) {
                    return;
                  }
                }}
              />
            ))}
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                required
                type="number"
                label="Box Number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={currentBox?.boxId}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                label="Enter Box Weight"
                name="weight"
                type="number"
                inputMode="numeric"
                inputProps={{ min: 1 }}
                value={currentBox?.weight}
                onChange={(event) => handleInputChanges(event, "box")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                name="dimensions"
                label="Enter Box Dimensions"
                type="text"
                value={currentBox?.dimensions || ""}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => handleInputChanges(event, "box")}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      <Box pt={2}>
        <FlexWrapper alignItems="center" gap={1}>
          <Typography variant="h5" gutterBottom>
            Images
          </Typography>
          {currentImages.length ? (
            <ViewAWSImages
              currentImages={currentImages}
              openAlert={openAlert}
            />
          ) : null}
        </FlexWrapper>

        {data?.isImagesRequired === "T" ? (
          <Typography color="error" variant="caption" pb={1} fontWeight="bold">
            Images are required for this order
          </Typography>
        ) : null}

        <>
          <GeneralFileUploadNative
            required={data?.isImagesRequired === "T"}
            multiple={true}
            onFilesSelected={(newFiles) => {
              const newData = [...palletData];
              newData[currentPalletIndex].isUpdated = true;
              newData[currentPalletIndex].newImages = newFiles.map(
                (f) =>
                  new File(
                    [f],
                    `Pallet_${currentPallet?.palletId}_${Date.now()}_${f.name}`,
                    {
                      type: f.type,
                      lastModified: f.lastModified,
                    }
                  )
              );
              setPalletData(newData);
            }}
          />
          <Typography
            variant="caption"
            pt={1}
            fontWeight="bold"
            textTransform="capitalize"
          >
            Upload images of each side of the pallet
          </Typography>
        </>
      </Box>

      <Box py={2}>
        <Button
          fullWidth
          variant="contained"
          size="large"
          onClick={createTranPackages}
        >
          Complete Pallet Updates
        </Button>
      </Box>
    </Container>
  );
};

export default UpdatePackResultsV2;
