import { useEffect, useRef, useState } from "react";
import { TextField } from "@mui/material";

const GeneralInput = ({
  muiInputProps = {},
  upperCase = false,
  trim = false,
  dataMatrix = false,
  name = "general-input",
}) => {
  const [input, setInput] = useState("");

  const itemInputRef = useRef(null);

  useEffect(() => {
    if (muiInputProps.autoFocus) {
      //autofocus fn
      handleItemRefFocus();
    }
  }, [muiInputProps.autoFocus]);

  const handleItemRefFocus = () => {
    if (itemInputRef.current) {
      itemInputRef.current.readOnly = true;
      itemInputRef.current.focus();
      setTimeout(() => {
        if (itemInputRef.current) {
          itemInputRef.current.focus();
          itemInputRef.current.readOnly = false;
        }
      }, 50); // Added a small delay to help the keyboard not appear
    }
  };

  return (
    <TextField
      {...muiInputProps}
      name={name}
      inputRef={itemInputRef}
      value={input}
      onChange={(event) => {
        let newVal = event.target.value;
        if (upperCase) {
          newVal = newVal.toUpperCase();
        }
        if (trim) {
          newVal = newVal.trim();
        }
        if (dataMatrix) {
          //write logic to parse the input from data matrix
          const itemSplit = newVal?.toUpperCase()?.split("*");
          newVal = itemSplit[0];
        }

        setInput(newVal);
      }}
    />
  );
};
export default GeneralInput;
