import { useState } from "react";
import { Search } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Container,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";

import GeneralInput from "../../../components/general/input/GeneralInput";
import Loading from "../../../components/Loading";
import axios from "../../../axios/axios.config";
import ReceiveInspectionActiveOrders from "../../../components/multi-page/receive-inspection/ReceiveInspectionActiveOrders";

const ReceiveProcessInspectionSearchPage = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");

  const { currentNetsuiteLocationIds, username } = useSelector(
    (state) => state.user
  );
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading("Searching...");
    try {
      const formData = new FormData(event.target);
      const order = formData.get("receive-inspection-order");

      if (!order) {
        throw new Error("Order number is required");
      }

      //get data from netsuite
      const response = await axios.get(
        `netsuite/receive-transaction/${order?.trim()}`
      );

      if (!response.data.length) {
        throw new Error("Order not found or empty");
      }

      const {
        subsidiary,
        trantype,
        internalid,
        date,
        tranid,
        nsstatus,
        brandpackaging,
        location,
        locationinternalid,
        entity,
        otherrefnum,
        soreference,
        memo,
        warehousenotes,
        rmatype,
        rmaspecialnotes,
      } = response.data[0];

      if (!currentNetsuiteLocationIds.includes(parseInt(locationinternalid))) {
        throw new Error("You do not have access to this order location");
      }

      const nsItems = response.data.map((item) => {
        return {
          lineid: item.lineid,
          lineitemaddtlupccodes: item.lineitemaddtlupccodes,
          lineitemboxsize: item.lineitemboxsize,
          lineitemclass: item.lineitemclass,
          lineiteminternalid: item.lineiteminternalid,
          lineitemname: item.lineitemname,
          lineitemqty: item.lineitemqty,
          lineitemqtypendingreceipt: item.lineitemqtypendingreceipt,
          lineitemqtyreceived: item.lineitemqtyreceived,
          lineitemsubclass: item.lineitemsubclass,
          lineitemupc: item.lineitemupc,
          lineitemaddtlupccodes: Boolean(item.lineitemaddtlupccodes)
            ? item.lineitemaddtlupccodes?.split("|")
            : [],
          lineitemdescription: item.lineitemdescription,
          tranid,
        };
      });

      const totalitemstoreceive = nsItems.reduce(
        (acc, current) => acc + parseInt(current.lineitemqtypendingreceipt),
        0
      );

      const nsHeaderData = {
        subsidiary,
        trantype,
        internalid,
        date,
        tranid,
        nsstatus,
        brandpackaging,
        location,
        locationinternalid,
        entity,
        otherrefnum,
        soreference,
        memo,
        warehousenotes,
        rmatype,
        rmaspecialnotes,
        createdby: username,
        totalitemstoreceive,
      };

      //write code to find or create receive order in db
      const receiveResponse = await axios.post(
        `receive-inspection/get-or-create/order/${tranid}`,
        { nsItems, nsHeaderData, username }
      );
      //navigate to results page
      navigate(receiveResponse.data?.orderId);
    } catch (error) {
      setError(error?.response?.data?.msg || error.message);
      setLoading("");
    }
  };

  if (Boolean(loading)) {
    return <Loading message={loading} />;
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" textAlign="center" gutterBottom>
        Receive Process Inspection
      </Typography>
      <form onSubmit={handleSubmit}>
        <GeneralInput
          name="receive-inspection-order"
          upperCase={true}
          trim={true}
          dataMatrix={true}
          muiInputProps={{
            error: Boolean(error),
            helperText: error || "",
            required: true,
            label: "Enter Order Number",
            autoFocus: true,
            fullWidth: true,
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
      </form>

      {/* Active User Orders */}
      <ReceiveInspectionActiveOrders />
    </Container>
  );
};
export default ReceiveProcessInspectionSearchPage;
