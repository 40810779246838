import { useRef, useState } from "react";
import { CheckCircle, Search } from "@mui/icons-material";
import {
  Box,
  Container,
  Drawer,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../FlexWrapper";

const ReceiveTransactionSearchModal = ({ items }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState("");

  const inputRef = useRef(null);

  const handleSearch = (event) => {
    event.preventDefault();
    setLoading("Searching...");

    const foundItems = items.filter((item) => {
      const newItem = searchInput?.split("*")[0];
      return (
        item.lineitemname?.includes(newItem) ||
        item.lineitemupc?.includes(newItem) ||
        item.lineitemdescription?.includes(newItem)
      );
    });

    setSearchResults(foundItems);
    setLoading("");
  };

  return (
    <>
      <IconButton
        onClick={() => {
          setIsDrawerOpen(true);
        }}
      >
        <Search />
      </IconButton>

      <Drawer
        anchor="top"
        open={isDrawerOpen}
        onClose={() => {
          setSearchInput("");
          setIsDrawerOpen(false);
          setSearchResults([]);

          if (inputRef.current) {
            inputRef.current.blur();
          }
        }}
        onAnimationEnd={() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
      >
        <Box
          component={"form"}
          onSubmit={handleSearch}
          sx={{
            backgroundColor: "#1976D2", // Primary blue color
            padding: "16px",
            color: "white",
          }}
        >
          <TextField
            inputRef={inputRef}
            required
            size="small"
            label="Search Items"
            variant="filled"
            type="text"
            fullWidth
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value.toUpperCase())}
            InputProps={{
              style: { color: "white" },
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton type="submit">
                    <Search sx={{ color: "white" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            FormHelperTextProps={{
              style: { color: "white" }, // Change helper text color to white
            }}
            InputLabelProps={{
              style: { color: "white" }, // Change label color to white
            }}
          />
        </Box>
        <Container
          maxWidth="lg"
          sx={{
            minHeight: "30vh",
            overflow: "scroll",
            maxHeight: "45vh",
          }}
        >
          {loading ? (
            <Typography variant="h6" textAlign="center">
              {loading}
            </Typography>
          ) : (
            <Stack paddingY={1} spacing={2}>
              {searchResults?.length ? (
                searchResults?.map((result) => {
                  return (
                    <FlexWrapper
                      key={result._id}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <FlexWrapper alignItems="center" gap={1}>
                        <FlexWrapper flexDirection="column">
                          <Typography variant="h6">
                            {result.lineitemname}
                          </Typography>
                          <Typography variant="caption" fontWeight="bold">
                            {result.lineitemdescription}
                          </Typography>
                        </FlexWrapper>

                        {result.lineitemqtypendingreceipt === 0 && (
                          <CheckCircle color="success" />
                        )}
                      </FlexWrapper>
                      <FlexWrapper flexDirection="column">
                        <Typography variant="body2">
                          <strong>Qty: </strong> {result.lineitemqty}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Received: </strong>
                          {result.lineitemqtyreceived}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Remaining: </strong>
                          {result.lineitemqtypendingreceipt}
                        </Typography>
                      </FlexWrapper>
                    </FlexWrapper>
                  );
                })
              ) : (
                <Typography variant="h6" textAlign="center">
                  No Results To Display
                </Typography>
              )}
            </Stack>
          )}
        </Container>
      </Drawer>
    </>
  );
};
export default ReceiveTransactionSearchModal;
