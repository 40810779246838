import {
  Article,
  Assessment,
  AssignmentReturned,
  AssignmentTurnedIn,
  Ballot,
  ConstructionOutlined,
  ContactMail,
  DatasetLinked,
  EngineeringOutlined,
  JoinFull,
  ManageSearch,
  MilitaryTech,
  OpenInNew,
  Print,
  PublishedWithChanges,
  QrCodeScanner,
  ReceiptLong,
  Restore,
  Scoreboard,
  PrecisionManufacturing,
  Inventory,
  Build,
  MultipleStop,
  ModelTraining,
  LocalShipping,
  Leaderboard,
  ViewAgenda,
  Preview,
  QueryStats,
  Search,
  Image,
} from "@mui/icons-material";

export const warehouseMenu = [
  {
    title: "Orders",
    description: "This system allows users to view and manage NetSuite orders.",
    icon: <ReceiptLong />,
    nested: true,
    nestedName: "orders",
    submenus: [
      {
        title: "Stock Orders",
        to: "/portal/warehouse/order-processing",
        target: "",
        admin: (rolelevel) => rolelevel <= 4,
      },
      {
        title: "Transfer Orders",
        to: "/portal/warehouse/transfer-orders",
        target: "",
        admin: (rolelevel) => rolelevel <= 3,
      },
      {
        title: "Special Orders",
        to: "/portal/warehouse/special-orders",
        target: "",
        admin: (rolelevel) => rolelevel <= 4,
      },
      {
        title: "Will Call Orders",
        to: "/portal/warehouse/will-call-orders",
        target: "",
        admin: (rolelevel) => rolelevel <= 4,
      },
      {
        title: "TO Linked To SO",
        to: "/portal/warehouse/to-linked-to-so",
        target: "",
      },
    ],
  },
  {
    title: "Picking",
    description: "This system allows users to manage picking processes.",
    icon: <PrecisionManufacturing />,
    nested: true,
    nestedName: "picking",
    submenus: [
      {
        title: "Pick Order",
        to: "/portal/warehouse/pick-order",
        target: "",
      },
      {
        title: "Pick TO onto SO",
        to: "/portal/warehouse/pick-to-so",
        target: "",
      },
      {
        title: "View Picking In Progress",
        to: "/portal/warehouse/pick-view-orders",
        target: "",
        admin: (rolelevel) => rolelevel <= 3,
      },
      {
        title: "Pick Fulfillment Upload Error",
        to: "/portal/warehouse/pick-fulfillment-upload-error",
        target: "",
        admin: (rolelevel) => rolelevel === 0,
      },
    ],
  },
  {
    title: "Packing",
    description: "This system allows users to manage packing processes.",
    icon: <QrCodeScanner />,
    nested: true,
    nestedName: "packing",
    submenus: [
      // {
      //   title: "Pack Order",
      //   to: "/portal/warehouse/pack-process",
      //   target: "",
      // },
      {
        title: "Pack Order",
        to: "/portal/warehouse/pack-process-v2",
        target: "",
      },
      {
        title: "Update Pallet Information",
        to: "/portal/warehouse/update-pack-order",
        target: "",
      },
      {
        title: "View Pallet Details",
        to: "/portal/warehouse/pack-process/details",
        target: "",
      },
      {
        title: "View Packing In Progress",
        to: "/portal/warehouse/pack-process-orders",
        target: "",
        admin: (rolelevel) => rolelevel <= 4,
      },
    ],
  },
  // {
  //   title: "Pack Order",
  //   description: "This system assists in pack process management.",
  //   to: "/portal/warehouse/pack-process",
  //   nested: false,
  //   icon: <QrCodeScanner />,
  // },
  // {
  //   title: "Update Pallet Information",
  //   description:
  //     "This system allows user to edit information and upload pictures for pack process pallets.",
  //   to: "/portal/warehouse/update-pack-order",
  //   nested: false,
  //   icon: <Widgets />,
  // },
  // {
  //   title: "View Pack Details",
  //   description: "This system lets you view mages and details for an order.",
  //   to: "/portal/warehouse/pack-process/details",
  //   nested: false,
  //   icon: <Preview />,
  // },
  // {
  //   title: "New Pack Process",
  //   description: "This will be the new pack process that improves scan speed.",
  //   to: "/portal/warehouse/new-pack-process",
  //   nested: false,
  //   icon: <QrCodeScanner />,
  // },
  {
    title: "Inventory",
    description: "This systems allows users to manange inventory processes.",
    icon: <Inventory />,
    nested: true,
    nestedName: "inventory",
    submenus: [
      {
        title: "Inventory Move",
        to: "/portal/warehouse/inventory-move",
        target: "",
      },
      {
        title: "Stock Locator",
        to: "/portal/warehouse/stock-locator",
        target: "",
      },
      {
        title: "Pick Discrepancies",
        to: "/portal/warehouse/pick-discrepancies",
        target: "",
        admin: (level) => level <= 3,
      },
      {
        title: "View Inventory Moves In Progress",
        to: "/portal/warehouse/view-inventory-moves",
        target: "",
        admin: (level) => level <= 3,
      },
    ],
  },
  {
    title: "Receiving",
    description: "This system allows users to manage receiving processes.",
    icon: <MultipleStop />,
    nested: true,
    nestedName: "receiving",
    submenus: [
      {
        title: "Receive Transaction",
        to: "/portal/warehouse/receive-transaction",
        target: "",
      },
      {
        title: "Container Log",
        to: "/portal/warehouse/container-log",
        target: "",
        admin: (rolelevel) => rolelevel <= 4,
      },
      {
        title: "View Receiving Transactions In Progress",
        to: "/portal/warehouse/view-received-transactions",
        target: "",
        admin: (level) => level <= 3,
      },
    ],
  },
  {
    title: "Shipping",
    admin: (rolelevel) => rolelevel <= 4,
    description: "This system allows users to manage shipping processes.",
    icon: <LocalShipping />,
    nested: true,
    nestedName: "shipping",
    submenus: [
      {
        title: "Ready To Ship Orders",
        to: "/portal/warehouse/ready-to-ship-orders",
        target: "",
      },
      {
        title: "Freight Shipped Today",
        to: "/portal/warehouse/freight-shipped-today",
        target: "",
      },
      {
        title: "Shipment History",
        to: "https://ship.pacejet.cc/TrakMotive/Content/Transactions/Shipments.aspx",
        target: "_blank",
      },
    ],
  },
  {
    title: "Cycle Counts",
    description: "This system allows users to count inventory.",
    icon: <ModelTraining />,
    nested: true,
    nestedName: "cycle-counts",
    submenus: [
      {
        title: "Create Cycle Counts",
        to: "/portal/warehouse/create-cycle-counts",
        target: "",
        admin: (level) => level <= 3,
      },
      {
        title: "Enter Cycle Counts",
        to: "/portal/warehouse/enter-cycle-counts",
        target: "",
      },
      {
        title: "Review Cycle Counts",
        to: "/portal/warehouse/review-cycle-counts",
        target: "",
        admin: (level) => level <= 3,
      },
      {
        title: "Pick Discrepancies Cycle Counts",
        to: "/portal/warehouse/pick-discrepancies-cycle-counts",
        target: "",
      },
    ],
  },
  {
    title: "Labels",
    description: "This system allows users to print labels.",
    icon: <Print />,
    nested: true,
    nestedName: "labels",
    submenus: [
      {
        title: "Print Item Labels",
        to: "/portal/warehouse/print-labels",
      },
      {
        title: "Print Generic Labels",
        to: "/portal/warehouse/print-generic-labels",
      },
      {
        title: "Print Order Labels",
        to: "/portal/warehouse/print-order-labels",
      },
      {
        title: "Print Bin Labels",
        to: "/portal/warehouse/print-bin-labels",
        admin: (level) => level <= 3,
      },
    ],
  },
  {
    title: "Equipment",
    description:
      "This systems allows users to inspect and manage equipment processes.",
    icon: <Build />,
    nested: true,
    nestedName: "equipment",
    submenus: [
      {
        title: "Equipment Checklist",
        to: "/portal/warehouse/equipment-checklist",
        target: "",
      },
      {
        title: "Equipment Reports",
        to: "/portal/warehouse/equipment-reports",
        target: "",
        admin: (level) => level <= 3,
      },
    ],
  },
  {
    title: "Reports",
    admin: (rolelevel) => rolelevel <= 3,
    description: "This systems allows users to view inventory reports.",
    icon: <Leaderboard />,
    nested: true,
    nestedName: "reports",
    submenus: [
      {
        title: "Empty Bins Report",
        to: "/portal/warehouse/empty-bins-report",
        target: "",
      },
      // {
      //   title: "Items To Consolidate Report",
      //   to: "/portal/warehouse/items-to-consolidate-report",
      //   target: "",
      // },
      {
        title: "Available Items Report",
        to: "/portal/warehouse/available-items-report",
        target: "",
      },
      {
        title: "Replenish From Overstock",
        to: "/portal/warehouse/replenish-from-overstock",
        target: "",
      },
      {
        title: "Full Pallet Bins Consolidation",
        to: "/portal/warehouse/full-pallet-bins-consolidation-v2",
        target: "",
      },
      {
        title: "Partial Bins w/ More Than Half Pallet",
        to: "/portal/warehouse/partial-bins-half-pallet",
        target: "",
      },
      {
        title: "Bins With More Than One Item",
        to: "/portal/warehouse/bins-with-more-than-one-item",
        target: "",
      },
      {
        title: "Inventory Transaction History by Part and/or Bin",
        to: "/portal/warehouse/inventory-transaction-history",
        target: "",
      },
    ],
  },
  {
    title: "Dashboard",
    admin: (rolelevel) => rolelevel <= 2,
    description: "This systems allows admins to view warehouse activity.",
    icon: <QueryStats />,
    nested: true,
    nestedName: "dashboard",
    submenus: [
      {
        title: "Warehouse Activity Report",
        to: "/portal/warehouse/warehouse-activity-report",
        target: "",
      },
      {
        title: "User Interactions",
        to: "/portal/warehouse/user-interactions",
        target: "",
      },
    ],
  },
];

export const productMenu = [
  {
    title: "Labor Claim Form",
    description: "This system allows users to view and manage labor claims.",
    icon: <ReceiptLong />,
    to: "/portal/product/forms/labor-claim",
  },
  {
    title: "Tote Inspection",
    description: "This system allows users to inspect tote items.",
    icon: <Search />,
    to: "/portal/product/tote-inspection",
  },
];

export const salesMenu = [
  {
    title: "Activity Dashboard",
    description: "This system lets users view past activity and make edits.",
    icon: <Ballot />,
    to: "/portal/sales/activity-dashboard",
    nested: false,
  },
  {
    title: "Labels and Box Requests",
    description:
      "This system allows users to make box requests and attach files.",
    to: "/portal/sales/forms/labels-box-requests",
    nested: false,
    icon: <DatasetLinked />,
  },
  {
    title: "Labor Claim Form",
    description: "This system allows users to view and manage labor claims.",
    to: "/portal/sales/forms/labor-claim",
    nested: false,
    icon: <ReceiptLong />,
  },
  {
    title: "Sales Forms",
    description: "This system allows users to configure and fill out forms.",
    icon: <Article />,
    nested: true,
    nestedName: "salesForms",
    submenus: [
      {
        title: "Proposal Forms",
        to: "/portal/sales/forms/proposal",
        target: "",
      },
      {
        title: "Deal Sheet Forms",
        to: "/portal/sales/forms/deal-sheet",
        target: "",
      },
    ],
  },
  {
    title: "External Sales Forms",
    description: "Access External Sales Forms",
    icon: <OpenInNew />,
    nested: true,
    nestedName: "externalSalesForms",
    submenus: [
      {
        title: "Existing Customer",
        target: "_blank",
        to: "https://forms.microsoft.com/Pages/ResponsePage.aspx?id=wINsmuyPl0KXZSGs9Gk3a0AmZDmkb7NDiubzTXygl4hUQUM0TUxJUTZMUTVRUEtUV0xUMEgyV1pUUS4u",
      },
      {
        title: "New Opportunity",
        target: "_blank",
        to: "https://forms.microsoft.com/Pages/ResponsePage.aspx?id=wINsmuyPl0KXZSGs9Gk3a0AmZDmkb7NDiubzTXygl4hUNk83NldEMUdHRDdFMlk0VTlYNTZDUFpaMy4u",
      },
    ],
  },
];

export const procurementMenu = [
  {
    title: "Replenishment Analysis",
    description: "This system provides item replenishment analytics.",
    icon: <PublishedWithChanges />,
    to: "/portal/procurement/replenishment-analysis",
  },
  {
    title: "Understock Analysis",
    description: "This system provides understock analytics.",
    icon: <AssignmentReturned />,
    to: "/portal/procurement/understock-analysis",
  },
  // {
  //   title: "Understock",
  //   description: "This system provides item understock analytics.",
  //   icon: <JoinLeftIcon />,
  //   to: "/procurement/understock",
  // },
  {
    title: "Overstock Analysis",
    description: "This system provides item overstock analytics.",
    icon: <Assessment />,
    to: "/portal/procurement/overstock-analysis",
  },
  {
    title: "All Items",
    description: "This system provides analytics for all items.",
    icon: <JoinFull />,
    to: "/portal/procurement/all-items",
  },
  {
    title: "Receiving",
    description: "This system provides analytics for receiving.",
    icon: <AssignmentTurnedIn />,
    to: "/portal/procurement/receiving",
  },
  {
    title: "Lost Sales",
    description:
      "This system provides analytics for backorders and lost sales.",
    icon: <Restore />,
    to: "/portal/procurement/lost-sales",
  },
  {
    title: "Ranking Process",
    description: "This system provides analytics to rank needed items.",
    icon: <MilitaryTech />,
    to: "/portal/procurement/ranking-process",
  },
  {
    title: "Scorecard",
    description: "This system provides analytics for scorecard.",
    icon: <Scoreboard />,
    to: "/portal/procurement/scoreboard",
  },
];

export const customerMenu = [
  {
    title: "Labels and Box Requests",
    description:
      "This system allows users to make box requests and attach files.",
    to: "/portal/customer/forms/labels-box-requests",
    nested: false,
    icon: <DatasetLinked />,
  },
  {
    title: "Labor Claim Forms",
    description: "This system allows users to view and manage labor claims.",
    to: "/portal/customer/forms/labor-claim",
    nested: false,
    icon: <ReceiptLong />,
  },
  {
    title: "Warranty Claim Form",
    description: "This system allows users to view and manage warranty claims.",
    to: "/portal/customer/forms/warranty",
    nested: false,
    icon: <ContactMail />,
  },
];

export const salesRepMenu = [
  {
    title: "Labels and Box Requests",
    description:
      "This system allows users to make box requests and attach files.",
    to: "/portal/sales-rep/forms/labels-box-requests",
    nested: false,
    icon: <DatasetLinked />,
  },
  {
    title: "Labor Claim Form",
    description: "This system allows users to view and manage labor claims.",
    to: "/portal/sales-rep/forms/labor-claim",
    nested: false,
    icon: <ReceiptLong />,
  },
];

export const returnsMenu = [
  {
    title: "Labor Claim Form",
    description: "This system allows users to view and manage labor claims.",
    to: "/portal/returns/forms/labor-claim",
    nested: false,
    icon: <ReceiptLong />,
  },
  {
    title: "Warranty Claim Form",
    description: "This system allows users to view and manage warranty claims.",
    to: "/portal/returns/forms/warranty",
    nested: false,
    icon: <ContactMail />,
  },
];

export const customerServiceMenu = [
  {
    title: "Labels and Box Requests",
    description:
      "This system allows users to make box requests and attach files.",
    to: "/portal/customer-service/forms/labels-box-requests",
    nested: false,
    icon: <DatasetLinked />,
  },
  {
    title: "Labor Claim Form",
    description: "This system allows users to view and manage labor claims.",
    to: "/portal/customer-service/forms/labor-claim",
    nested: false,
    icon: <ReceiptLong />,
  },
  {
    title: "View Pallet Details",
    to: "/portal/customer-service/pack-process/details",
    description: "This system allows users to view and export packing details.",
    target: "",
    icon: <Preview />,
  },
  {
    title: "Receiving",
    description: "This system provides analytics for receiving.",
    icon: <AssignmentTurnedIn />,
    to: "/portal/customer-service/receiving",
  },
];

export const reverseLogisticsMenu = [
  {
    title: "Item Disposition",
    description:
      "This system assists in finding the correct location for scanned items.",
    icon: <ConstructionOutlined />,
    to: "/portal/reverse-logistics/item-disposition",
  },
  {
    title: "Tote Manager",
    description:
      "This system assists in managing container quantaties and settings.",
    icon: <EngineeringOutlined />,
    to: "/portal/reverse-logistics/tote-manager",
  },
  {
    title: "Tote Search",
    description:
      "This system allows users to view all current totes and manage status.",
    icon: <ManageSearch />,
    to: "/portal/reverse-logistics/tote-search",
  },
  {
    title: "Packing",
    description: "This system allows users to manage packing processes.",
    icon: <QrCodeScanner />,
    nested: true,
    nestedName: "packing",
    submenus: [
      {
        title: "Pack Order",
        to: "/portal/reverse-logistics/pack-process-v2",
        target: "",
      },
      {
        title: "View Packing In Progress",
        to: "/portal/reverse-logistics/pack-process-orders",
        target: "",
        admin: (rolelevel) => rolelevel <= 4,
      },
    ],
  },
  {
    title: "Receiving",
    description: "This system allows users to manage receiving processes.",
    icon: <MultipleStop />,
    nested: true,
    nestedName: "receiving",
    submenus: [
      {
        title: "Receive Transaction",
        to: "/portal/reverse-logistics/receive-transaction",
        target: "",
      },
      {
        title: "Receive Transaction Inspection",
        to: "/portal/reverse-logistics/receive-transaction-inspection",
        target: "",
      },
      {
        title: "View Receiving Transactions In Progress",
        to: "/portal/reverse-logistics/view-received-transactions",
        target: "",
        admin: (level) => level <= 3,
      },
    ],
  },
  {
    title: "Inventory",
    description: "This systems allows users to manange inventory processes.",
    icon: <Inventory />,
    nested: true,
    nestedName: "inventory",
    submenus: [
      {
        title: "Inventory Move",
        to: "/portal/reverse-logistics/inventory-move",
        target: "",
      },

      {
        title: "View Inventory Moves In Progress",
        to: "/portal/reverse-logistics/view-inventory-moves",
        target: "",
        admin: (level) => level <= 3,
      },
    ],
  },
  {
    title: "Labels and Box Requests",
    description:
      "This system allows users to make box requests and attach files.",
    to: "/portal/reverse-logistics/forms/labels-box-requests",
    nested: false,
    icon: <DatasetLinked />,
  },
  {
    title: "Labels",
    description: "This system allows users to print labels.",
    icon: <Print />,
    nested: true,
    nestedName: "labels",
    submenus: [
      {
        title: "Print Item Labels",
        to: "/portal/reverse-logistics/print-labels",
      },
      {
        title: "Print Generic Labels",
        to: "/portal/reverse-logistics/print-generic-labels",
      },
      {
        title: "Print Tote Item Labels",
        to: "/portal/reverse-logistics/print-tote-items-labels",
      },
    ],
  },
  {
    title: "Equipment",
    description:
      "This systems allows users to inspect and manage equipment processes.",
    icon: <Build />,
    nested: true,
    nestedName: "equipment",
    submenus: [
      {
        title: "Equipment Checklist",
        to: "/portal/reverse-logistics/equipment-checklist",
        target: "",
      },
      {
        title: "Equipment Reports",
        to: "/portal/reverse-logistics/equipment-reports",
        target: "",
        admin: (level) => level <= 3,
      },
    ],
  },
  {
    title: "Images",
    description: "This systems allows users to upload and view images.",
    icon: <Image />,
    nested: true,
    nestedName: "images",
    submenus: [
      {
        title: "Image Upload",
        to: "/portal/reverse-logistics/image-upload",
        target: "",
      },
      {
        title: "View Images",
        to: "/portal/reverse-logistics/view-images",
        target: "",
        admin: (level) => level <= 3,
      },
    ],
  },
];

export const ningboMenu = [
  {
    title: "Labor Claim Form",
    description:
      "This system allows users to view and manage Ningbo labor claims.",
    to: "/portal/ningbo/forms/labor-claim",
    nested: false,
    icon: <ReceiptLong />,
  },
];

export const departmentMap = {
  WAREHOUSE: warehouseMenu,
  PRODUCT: productMenu,
  SALES: salesMenu,
  PROCUREMENT: procurementMenu,
  CUSTOMER: customerMenu,
  "SALES-REP": salesRepMenu,
  RETURNS: returnsMenu,
  "REVERSE-LOGISTICS": reverseLogisticsMenu,
  "CUSTOMER-SERVICE": customerServiceMenu,
  NINGBO: ningboMenu,
};
