import { Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "../../axios/axios.config";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Loading from "../../components/Loading";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";
import { useDispatch } from "react-redux";
import { LOSTSALESCOLUMS } from "../../utils/columns/procurement/procurementColumns";

const LostSales = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [lostSalesData, setLostSalesData] = useState([]);
  const [lastModifiedDate, setLastModifiedDate] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getLostSalesData();
  }, []);

  const getLostSalesData = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        "procurement/aws/tactical-connect/file/customsearch_ait_mern_lost_sales.csv"
      );
      setLostSalesData(data.data.slice(0, -1));
      setLastModifiedDate(data.LastModified);
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error getting data: ${error.message}`,
          duration: 5000,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loading message="Loading Lost Sales Data..." />;
  }

  return (
    <Container maxWidth="xl">
      <Typography component="h1" variant="h3" textAlign="center">
        Lost Sales
      </Typography>
      <Typography component="p" variant="caption" pt={0.5} textAlign="center">
        Date Range: Last Week
      </Typography>
      <Typography variant="body2" gutterBottom>
        {lastModifiedDate
          ? `Data Last Updated On: ${new Date(
              lastModifiedDate
            ).toLocaleString()}`
          : "No Date Available"}
      </Typography>

      <DataGrid
        rows={lostSalesData}
        columns={LOSTSALESCOLUMS}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            printOptions: {
              disableToolbarButton: true,
            },
            showQuickFilter: true,
            csvOptions: { fileName: `Lost-Sales-${Date.now()}` },
          },
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 100 },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        disableColumnSelector
        disableDensitySelector
        density="compact"
        getRowId={(row) => row.Item + row["Planning Location"]}
        sx={{
          maxHeight: { xs: "70vh", md: 750 },
          ".Mui-selected": {
            backgroundColor: "rgb(139 198 255) !important",
          },
        }}
      />
    </Container>
  );
};
export default LostSales;
