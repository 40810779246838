import axios from "../../../axios/axios.config";

export const userProfilePrintersLoader = async () => {
  try {
    const currentLocation = localStorage.getItem("location");
    const printersResponse = await axios.get(
      `printers?location=${currentLocation}`
    );

    return printersResponse.data;
  } catch (error) {
    throw new Response("Error", {
      status: error.response?.status || 500,
      statusText: error.response?.data?.msg || error.message,
    });
  }
};
