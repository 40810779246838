import { useRef, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { Cancel, MoreVert } from "@mui/icons-material";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../FlexWrapper";
import axios from "../../../axios/axios.config";

const ReceiveInspectionActions = () => {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const { currentDepartment } = useSelector((state) => state.user);
  const { orderData, setOrderData, setLoading, receivedItems, handleReceive } =
    useOutletContext();
  const { openAlert } = useGeneralAlert();

  const [isReferenceModalOpen, setIsReferenceModalOpen] = useState(false);
  const [referenceInput, setReferenceInput] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // Menu Functions
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handlePauseReceipt = () => {
    handleMenuClose();
    navigate(
      `/portal/${currentDepartment?.toLowerCase()}/receive-transaction-inspection`,
      { replace: true }
    );
  };

  const handleClickReference = () => {
    handleMenuClose();
    setIsReferenceModalOpen(true);
    setTimeout(() => inputRef?.current?.focus(), 0);
  };

  const handleUpdateReference = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.patch(
        "receive-inspection/update/header/reference",
        { orderId: orderData?._id, newReference: referenceInput }
      );

      //update header data
      setOrderData({ ...orderData, tranref: response?.data?.tranref });

      openAlert({
        type: "success",
        message: "Successfully Updated Reference",
        duration: 2000,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not update reference: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    } finally {
      setIsReferenceModalOpen(false);
      setReferenceInput("");
    }
  };

  const handlePartialReceive = async () => {
    handleMenuClose();
    setLoading("Partially Receiving Order...");
    try {
      const updatedOrder = await handleReceive();

      openAlert({
        type: "success",
        message: `Successfully received order ${
          updatedOrder?.itemreceiptinternalid
            ? `- Receipt ID: ${updatedOrder?.itemreceiptinternalid}`
            : ""
        }`,
        duration: 3000,
      });
      navigate(
        `/portal/${currentDepartment?.toLowerCase()}/receive-transaction-inspection`,
        { replace: true }
      );
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not fulfill order: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    } finally {
      setLoading("");
    }
  };

  return (
    <Box>
      {/* IconButton for Actions */}
      <IconButton size="small" onClick={handleMenuOpen}>
        <MoreVert />
      </IconButton>

      {/* Menu Dropdown */}
      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
        {Boolean(receivedItems?.length) && (
          <MenuItem onClick={handlePartialReceive}>Partial Receive</MenuItem>
        )}
        <MenuItem onClick={handleClickReference}>Update Reference</MenuItem>
        <MenuItem onClick={handlePauseReceipt}>Pause Receipt</MenuItem>
      </Menu>

      {/* Update Reference Modal */}
      <Dialog keepMounted fullWidth open={isReferenceModalOpen}>
        <FlexWrapper>
          <IconButton
            size="small"
            onClick={() => setIsReferenceModalOpen(false)}
          >
            <Cancel color="error" />
          </IconButton>
        </FlexWrapper>
        <Typography variant="h6" textAlign="center" gutterBottom>
          Update Reference
        </Typography>

        <Box px={1} component="form" onSubmit={handleUpdateReference}>
          <TextField
            required
            inputRef={inputRef}
            autoFocus
            label="Enter New Reference"
            multiline
            minRows={4}
            fullWidth
            value={referenceInput}
            onChange={(e) => setReferenceInput(e.target.value)}
            inputProps={{ maxLength: 300 }}
            helperText={
              <Typography variant="caption" color="error">
                Limit of 300 characters
              </Typography>
            }
          />
          <Button
            size="small"
            variant="contained"
            sx={{ my: 1 }}
            fullWidth
            color="primary"
            type="submit"
          >
            Submit
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};
export default ReceiveInspectionActions;
