import { useOutletContext } from "react-router-dom";
import { Info, Search } from "@mui/icons-material";
import { Box, Divider, IconButton, Typography } from "@mui/material";

import FlexWrapper from "../../FlexWrapper";
import ReceiveInspectionActions from "./ReceiveInspectionActions";

const ReceiveInspectionHeaderSection = ({
  setIsDetailsModalOpen,
  setIsSearchViewOpen,
}) => {
  const { orderData } = useOutletContext();

  return (
    <Box>
      {/* Order Title and Action Icons */}
      <FlexWrapper justifyContent="space-between" alignItems="center" gap={0.5}>
        <Typography
          textAlign="center"
          fontWeight="bold"
          sx={{ typography: { xs: "h5", md: "h4" } }}
        >
          {orderData.tranid}{" "}
          {orderData.brandpackaging && ` - ${orderData.brandpackaging}`}
        </Typography>

        <FlexWrapper gap={0.5}>
          <IconButton
            size="small"
            color="primary"
            onClick={() => setIsDetailsModalOpen(true)}
          >
            <Info />
          </IconButton>
          <IconButton size="small" onClick={() => setIsSearchViewOpen(true)}>
            <Search />
          </IconButton>
        </FlexWrapper>
      </FlexWrapper>

      <Divider />

      {/* Order Info and Actions Menu */}
      <Box py={0.5}>
        <FlexWrapper justifyContent="space-between" alignItems="center">
          <Typography variant="caption">
            Total: {orderData?.totalitemstoreceive?.toString()} | Received:{" "}
            {orderData?.totalreceived} | Remaining:{" "}
            {(
              orderData?.totalitemstoreceive - orderData?.totalreceived
            ).toString()}{" "}
            {orderData?.extrareceived ? (
              <Typography variant="caption" color="primary" component="span">
                | Extra: {orderData?.extrareceived}
              </Typography>
            ) : null}
          </Typography>

          <ReceiveInspectionActions />
        </FlexWrapper>
      </Box>
    </Box>
  );
};
export default ReceiveInspectionHeaderSection;
