import { useMemo, useState } from "react";
import { useLoaderData, useOutletContext } from "react-router-dom";
import {
  Description,
  ExitToApp,
  Link,
  PictureAsPdf,
} from "@mui/icons-material";
import { handleAdjustment } from "../../../../utils/helper-functions/pick-discrepancies/pickDiscrepanciesHelperFunctions";
import { utils, writeFile } from "xlsx";
import { REVIEWCYCLECOUNTSINDIVIDUALPAGECOLUMNS } from "../../../../utils/columns/cycle-counts/cycleCountsColumns";
import {
  Typography,
  Button,
  Container,
  IconButton,
  Box,
  Link as MUILink,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiContext,
} from "@mui/x-data-grid";

import FlexWrapper from "../../../../components/FlexWrapper";
import Loading from "../../../../components/Loading";
import axios from "../../../../axios/axios.config";
import CycleCountsReportPDF from "../../../../components/PDF/warehouse/cycle-counts/CycleCountsReportPDF";
import GeneralPDFDownloadV2 from "../../../../components/PDF/GeneralPDFDownloadV2";

const ExportPDF = ({ jobData, jobs, accuracy }) => {
  return (
    <GeneralPDFDownloadV2
      displayComponent={
        <IconButton>
          <PictureAsPdf color="primary" />
        </IconButton>
      }
      pdfComponent={
        <CycleCountsReportPDF
          status={jobData?.status}
          name={jobData?.name}
          createdOn={jobData?.createdAt}
          startedOn={jobData?.startedon}
          finishedOn={jobData?.finishedon}
          countedBy={jobData?.finishedby?.username}
          finalizedOn={jobData?.completedon}
          finalizedBy={jobData?.completedby?.username}
          accuracy={accuracy}
          recount={jobData?.recounts?.length ? "YES" : "NO"}
          location={jobData?.itemlocation}
          jobs={jobs?.sort((a, b) => {
            const binNumberA = a.itemData.itembinnumber.toUpperCase();
            const binNumberB = b.itemData.itembinnumber.toUpperCase();

            if (binNumberA < binNumberB) {
              return -1;
            }
            if (binNumberA > binNumberB) {
              return 1;
            }
            return 0;
          })}
        />
      }
      name={`${jobData?.name}-${Date.now()}.pdf`}
    />
  );
};

const ExportCSV = ({ jobData, jobs, accuracy }) => {
  const exportData = jobs.map((job) => {
    const hasCount = job.counts?.length > 0;
    const exportObj = {
      "Job Name": jobData.name,
      "Counted By": jobData.finishedby?.username,
      "Job Status": jobData.status,
      Created: new Date(jobData.createdAt).toLocaleString(),
      "Start Count Date": new Date(jobData.startedon).toLocaleString(),
      "Finish Count Date": new Date(jobData.finishedon).toLocaleString(),
      Completed: jobData.completedon
        ? new Date(jobData.completedon).toLocaleString()
        : "",
      "Completed By": jobData.completedby?.username,
      Status: job.status,
      Bin: job.itemData.itembinnumber,
      Description: job.itemData.itemdescription,
      Name: job.itemData.itemname,
      "Manually Added": job.itemData.isnewitem ? "Yes" : "No",
      Counted: hasCount ? job.counts[job.counts.length - 1].physicalcount : 0,
      "On-Hand": hasCount ? job.counts[job.counts.length - 1].virtualqty : 0,
      Variance: hasCount ? job.counts[job.counts.length - 1].variance : 0,
      Counts: job.counts.length,
      "Recount Created": jobData.recounts.length ? "YES" : "NO",
      Location: jobData.itemlocation,
    };

    if (accuracy) {
      exportObj["Bin Accuracy"] = `${accuracy}%`;
    }

    return exportObj;
  });

  const handleMenuItemClick = () => {
    handleExport();
  };

  const handleExport = () => {
    /* create worksheet */
    let ws = utils.json_to_sheet(exportData);

    /* create workbook and export */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Cylce Count Report");
    writeFile(wb, `${jobData.name}-${Date.now()}.csv`, { bookType: "csv" });
  };

  return (
    <IconButton onClick={handleMenuItemClick}>
      <Description color="primary" />
    </IconButton>
  );
};

const ViewLinks = ({ jobData }) => {
  return (
    <MUILink
      target="blank"
      href={`https://${process.env.REACT_APP_NS_ACCOUNT_KEY}.app.netsuite.com/app/accounting/transactions/invadjst.nl?id=${jobData.adjustmentid}`}
    >
      <IconButton>
        <Link color="primary" />
      </IconButton>
    </MUILink>
  );
};

const CustomToolbar = ({
  selected,
  setSelected,
  parentId,
  setJobs,
  openAlert,
  setLoading,
  status,
  jobData,
  jobs,
  accuracy,
}) => {
  const [currentFilter, setCurrentFilter] = useState("");

  const apiRef = useGridApiContext();
  const jobsCopy = useMemo(() => [...jobs], [jobs]);

  const handleChangeJobStatus = async (lineItemId, newStatus) => {
    try {
      const response = await axios.patch(
        `cycle-counts-line-items/update/${parentId}/change-line-status`,
        {
          newStatus,
          lineItemId,
          isReturnJobs: false,
        }
      );

      return response.data;
    } catch (error) {
      throw error.response?.data?.msg || error.message;
    }
  };

  const handleMultipleJobStatusChanges = async (newStatus) => {
    setLoading(`Updating Status${selected.length > 1 ? "es" : ""}...`);
    try {
      const updatePromises = selected.map((lineItemId) =>
        handleChangeJobStatus(lineItemId, newStatus)
      );

      //Update all line items
      await Promise.all(updatePromises);

      //get new jobs from mongo
      const response = await axios.get(
        `cycle-counts-jobs/get/jobs/${parentId}`
      );

      // Set the jobs with the updated statuses
      setJobs(response.data.jobs);

      openAlert({
        type: "success",
        message: `Successfully Changed Status${
          selected.length > 1 ? "es" : ""
        }`,
        duration: 2000,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: error.response?.data?.msg || error.message,
        duration: 8000,
      });
    } finally {
      setLoading("");
      setSelected([]);
    }
  };
  //filter dropdown change
  const handleFilterDropdown = (event) => {
    if (apiRef.current) {
      const filterMap = {
        None: [],
        Variance: [
          {
            field: "variance",
            operator: "!=",
            value: 0,
          },
        ],
        "No Variance": [
          {
            field: "variance",
            operator: "=",
            value: 0,
          },
        ],
      };

      apiRef.current.setFilterModel({ items: filterMap[event.target.value] });
      setCurrentFilter(event.target.value);
    }
  };

  return (
    <GridToolbarContainer>
      {status === "pendingapproval" && (
        <Box visibility={selected?.length ? "visible" : "hidden"}>
          <FlexWrapper width="100%" gap={1} p={1}>
            <Button
              size="small"
              variant="outlined"
              onClick={() => handleMultipleJobStatusChanges("approved")}
            >
              Approve
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="error"
              onClick={() => handleMultipleJobStatusChanges("needsrecount")}
            >
              Needs Recount
            </Button>
          </FlexWrapper>
        </Box>
      )}
      <FlexWrapper
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        <FlexWrapper gap={2} alignItems="center">
          <GridToolbarFilterButton />

          <ExportCSV jobData={jobData} jobs={jobs} accuracy={accuracy} />

          <ExportPDF jobData={jobData} jobs={jobsCopy} accuracy={accuracy} />

          {status === "complete" && Boolean(jobData.adjustmentid) && (
            <ViewLinks jobData={jobData} />
          )}

          <FormControl size="small">
            <InputLabel id="select-filter">Quick Filter</InputLabel>
            <Select
              sx={{ minWidth: 150 }}
              size="small"
              labelId="select-filter"
              id="select-filter"
              label="Quick Filter"
              value={currentFilter}
              onChange={handleFilterDropdown}
            >
              <MenuItem value="None">None</MenuItem>
              <MenuItem value="Variance">Variance</MenuItem>
              <MenuItem value="No Variance">No Variance</MenuItem>
            </Select>
          </FormControl>
        </FlexWrapper>

        <GridToolbarQuickFilter />
      </FlexWrapper>
    </GridToolbarContainer>
  );
};

const ReviewCycleCountsCountDetailsPage = () => {
  const jobData = useLoaderData();
  const { openAlert, user, navigate } = useOutletContext();

  const [jobs, setJobs] = useState(jobData?.jobs);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [loading, setLoading] = useState("");

  const remainingJobs = jobs?.filter((job) => job.status === "finished");
  const recountJobs = jobs?.filter((job) => job.status === "needsrecount");
  const approvedJobs = jobs?.filter((job) => job.status === "approved");
  let accuracy = null;

  if (jobData.status === "complete") {
    const numberOfCounts = jobData.jobs.length;

    const totalVarianceZeroCount = jobs.reduce((acc, item) => {
      // Get the last element in the counts array
      const lastCount = item.counts[item.counts.length - 1];

      // Check if the variance of the last count is zero
      const countWithZeroVariance =
        lastCount && lastCount.variance === 0 ? 1 : 0;

      // Accumulate the total count
      return acc + countWithZeroVariance;
    }, 0);

    accuracy = parseInt((totalVarianceZeroCount / numberOfCounts) * 100);
  }

  const handleCompleteCycleCount = async () => {
    setLoading("Completing Cycle Count...");
    try {
      let adjustmentid = null;
      const adjustmentJobs = approvedJobs
        .filter((job) => job.counts[job.counts.length - 1].variance !== 0)
        .map((adjJob) => {
          const currentData = adjJob.counts[adjJob.counts.length - 1];

          return {
            item: adjJob.itemData.iteminternalid,
            adjustQtyBy: currentData.variance,
            binnumbers: adjJob.itemData.itembinnumber,
            location: adjJob.itemData.itemlocationinternalid,
            linememo: jobData.name,
          };
        });

      //handleadjustment and get internal id if needed
      if (adjustmentJobs.length) {
        adjustmentid = parseInt(
          await handleAdjustment({
            subsidiaryinternalid: parseInt(user.currentSubsidiaryInternalId),
            username: user.username,
            items: adjustmentJobs,
            locationinternalid: jobs[0].itemData.itemlocationinternalid,
            _id: jobData._id,
            isPickShort: false,
          })
        );
      }

      if (recountJobs.length) {
        const binStorage = new Set();
        const itemStorage = new Set();
        //counts unique items and bins
        for (const job of recountJobs) {
          const { itemData } = job;

          binStorage.add(itemData.itembinnumber);
          itemStorage.add(itemData.itemname);
        }

        const newCycleCountData = {
          name: `${jobData.name}_RC${jobData.recounts.length + 1}`,
          createdby: user._id,
          uniqueitems: itemStorage.size,
          uniquebins: binStorage.size,
          whselocation: user.currentLocation,
          itemlocation: jobData.itemlocation,
        };

        const recountJobIds = recountJobs.map((job) => job._id);
        const approvedJobIds = approvedJobs.map((job) => job._id);

        await axios.patch(
          `cycle-counts-jobs/update/${jobData._id}/review/complete-recount`,
          {
            newCycleCountData,
            recountJobIds,
            approvedJobIds,
            userid: user._id,
            adjustmentid,
          }
        );
      } else {
        //no recount jobs left so all jobs approved
        await axios.patch(
          `cycle-counts-jobs/update/${jobData._id}/review/complete`,
          { userid: user._id, adjustmentid }
        );
      }

      navigate(
        `/portal/${user?.currentDepartment?.toLowerCase()}/review-cycle-counts`,
        { replace: true }
      );

      openAlert({
        type: "success",
        message: "Successfully Completed Cycle Count",
        duration: 2000,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not complete cycle count: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    } finally {
      setLoading("");
    }
  };

  if (loading) {
    return <Loading message={loading} />;
  }

  return (
    <Container maxWidth="xl" sx={{ pb: 2 }}>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Cycle Count Report
      </Typography>
      <FlexWrapper justifyContent="center">
        <FlexWrapper justifyContent="space-between" width="75%">
          <FlexWrapper flexDirection="column">
            <Typography
              textAlign="center"
              gutterBottom
              textTransform="capitalize"
            >
              <strong>Job Name:</strong> {jobData.name}
            </Typography>
            <Typography
              textAlign="center"
              gutterBottom
              textTransform="capitalize"
            >
              <strong>Status:</strong>{" "}
              {jobData.status === "pendingapproval"
                ? "Pending Approval"
                : jobData.status}
            </Typography>
            <Typography
              textAlign="center"
              gutterBottom
              textTransform="capitalize"
            >
              <strong>Created:</strong>{" "}
              {new Date(jobData.createdAt).toLocaleString()}
            </Typography>
            {jobData.completedon && (
              <Typography
                textAlign="center"
                gutterBottom
                textTransform="capitalize"
              >
                <strong>Completed:</strong>{" "}
                {new Date(jobData.completedon).toLocaleString()}
              </Typography>
            )}

            <Typography
              textAlign="center"
              gutterBottom
              textTransform="capitalize"
            >
              <strong>Bin Accuracy:</strong>{" "}
              {accuracy === 0 || Boolean(accuracy) ? `${accuracy}%` : "N/A"}
            </Typography>

            <Typography
              textAlign="center"
              gutterBottom
              textTransform="capitalize"
            >
              <strong>Location:</strong> {jobData.itemlocation}
            </Typography>
          </FlexWrapper>

          <FlexWrapper flexDirection="column">
            <Typography
              textAlign="center"
              gutterBottom
              textTransform="capitalize"
            >
              <strong>Counted By:</strong> {jobData.startedby?.username}
            </Typography>
            <Typography
              textAlign="center"
              gutterBottom
              textTransform="capitalize"
            >
              <strong>Start Count Date:</strong>{" "}
              {new Date(jobData.startedon).toLocaleString()}
            </Typography>
            <Typography
              textAlign="center"
              gutterBottom
              textTransform="capitalize"
            >
              <strong>Finish Count Date:</strong>{" "}
              {new Date(jobData.finishedon).toLocaleString()}
            </Typography>
            {jobData.completedby && (
              <Typography
                textAlign="center"
                gutterBottom
                textTransform="capitalize"
              >
                <strong>Completed By:</strong> {jobData.completedby?.username}
              </Typography>
            )}
            <Typography
              textAlign="center"
              gutterBottom
              textTransform="capitalize"
            >
              <strong>Recount Created:</strong>{" "}
              {jobData.recounts.length ? "YES" : "NO"}
            </Typography>
          </FlexWrapper>
        </FlexWrapper>
      </FlexWrapper>

      <FlexWrapper justifyContent="space-between" p="5px 0">
        <IconButton
          onClick={() =>
            navigate(
              `/portal/${user.currentDepartment?.toLowerCase()}/review-cycle-counts`
            )
          }
        >
          <ExitToApp color="error" />
        </IconButton>
        {jobData.status === "pendingapproval" &&
          Boolean(jobs?.length) &&
          remainingJobs?.length === 0 && (
            <Button
              variant="contained"
              color="success"
              onClick={handleCompleteCycleCount}
            >
              Complete Cycle Count
            </Button>
          )}
      </FlexWrapper>

      <DataGrid
        checkboxSelection={jobData.status === "pendingapproval"}
        sx={{ height: "60vh" }}
        rows={jobs}
        slots={{
          toolbar: () => (
            <CustomToolbar
              selected={selectedJobs}
              setSelected={setSelectedJobs}
              parentId={jobData._id}
              name={jobData.name}
              setJobs={setJobs}
              openAlert={openAlert}
              setLoading={setLoading}
              status={jobData.status}
              jobData={jobData}
              jobs={jobs}
              accuracy={accuracy}
            />
          ),
        }}
        columns={REVIEWCYCLECOUNTSINDIVIDUALPAGECOLUMNS}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 100 },
          },
          sorting: {
            sortModel: [{ field: "bin", sort: "asc" }],
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        disableDensitySelector
        disableColumnSelector
        disableRowSelectionOnClick
        getRowId={(row) => row._id}
        onRowSelectionModelChange={(rows) => setSelectedJobs(rows)}
      />
    </Container>
  );
};

export default ReviewCycleCountsCountDetailsPage;
