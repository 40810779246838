import { useEffect, useState } from "react";
import { CRONLOGCOLUMNS } from "../../../utils/columns/admin/adminColumns";
import { useDispatch } from "react-redux";
import { Close, Info } from "@mui/icons-material";
import { useOutletContext } from "react-router-dom";
import {
  closeFullScreenModal,
  openFullScreenModal,
} from "../../../redux/features/modal/modalSlice";
import {
  Box,
  IconButton,
  List,
  ListItem,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

import axios from "../../../axios/axios.config";
import FlexWrapper from "../../FlexWrapper";
import FullScreenModal from "../../FullScreenModal";

const AWSSCHEDULERS = [
  {
    name: "Item Last Order Dates",
    time: "Every Day at 3:30am PST",
    async: "itemlastorderdates",
  },
  {
    name: "Valogix SFTP To S3 File Uploads Job",
    time: "Every Day at 5:00am PST (PROD) or 5:10am(DEV)",
    async: "sftp",
  },
  {
    name: "Valogix History And Forecast Job",
    time: "Every Day at 5:15am PST",
    async: "historyandforecast",
  },
  {
    name: "Valogix Replenishments",
    time: "Every Day at 5:30am PST",
    async: "replenishments",
  },
  {
    name: "Open TO and PO By Item",
    time: "Every Day at 4:00am PST",
    async: "opentoandpobyitem",
  },
  {
    name: "Export Process: logomateexports",
    time: "N/A",
    async: "logomateexports",
  },
  {
    name: "Tactical Connect Item Cross Refs",
    time: "N/A",
    async: "itemcrossrefs",
  },
  {
    name: "Tactical Connect Items",
    time: "N/A",
    async: "items",
  },
];
const NSSCHEDULERS = [
  {
    name: "NS Understock Job",
    time: "Every day at 4 pm",
    async: "understock",
  },
  {
    name: "NS Open Purchase Orders By Item",
    time: "Every Day at 11PM",
    async: "openordersbyitem",
  },
  {
    name: "NS Items Job",
    time: "Every Sunday at 2AM PST",
    async: "items",
  },
  {
    name: "NS Item Cross Refs",
    time: "Every Sunday at 2:30AM PST",
    async: "itemcrossrefs",
  },
  {
    name: "NS Customer",
    time: "Every Sunday at 3AM PST",
    async: "customers",
  },
  {
    name: "NS Get Empty Bins",
    time: "Every 30 Minutes",
    async: "emptybins",
  },
];

function CustomToolbar() {
  const dispatch = useDispatch();

  return (
    <GridToolbarContainer>
      <FlexWrapper
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        <FlexWrapper gap={1} alignItems="flex-end">
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{
              fileName: `loginlogs-${Date.now()}`,
            }}
            printOptions={{
              disableToolbarButton: true,
            }}
          />
          <Tooltip title="View Schedule" arrow placement="top">
            <IconButton
              onClick={() => dispatch(openFullScreenModal())}
              disableRipple
            >
              <Info color="primary" fontSize="small" />
            </IconButton>
          </Tooltip>
        </FlexWrapper>
        <GridToolbarQuickFilter />
      </FlexWrapper>
    </GridToolbarContainer>
  );
}

const AdminCronLogsView = () => {
  const [cronLogs, setCronLogs] = useState([]);
  const dispatch = useDispatch();
  const { loading, setLoading, handleAlert } = useOutletContext();

  useEffect(() => {
    getCronLogsData();
  }, []);

  const getCronLogsData = async () => {
    setLoading(true);
    try {
      const cronLogsResponse = await axios.get("cron-logs/all");
      setCronLogs(cronLogsResponse.data);
    } catch (error) {
      handleAlert({
        type: "error",
        message: `Could not get data:${
          error?.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box width="100%">
      {/* displays cron log schedule modal*/}
      <FullScreenModal>
        <Box p={2}>
          <IconButton onClick={() => dispatch(closeFullScreenModal())}>
            <Close />
          </IconButton>
          <Typography variant="h3" textAlign="center" gutterBottom>
            Cron Schedules
          </Typography>
          <FlexWrapper gap={1} alignItems="stretch" justifyContent="center">
            <Paper elevation={3} sx={{ padding: "16px" }}>
              <Typography variant="h6" align="center" gutterBottom>
                Cron Aws Schedulers
              </Typography>
              <List>
                {AWSSCHEDULERS.map((scheduler) => (
                  <ListItem key={scheduler.name}>
                    <FlexWrapper flexDirection="column">
                      <Typography variant="h6">{scheduler.name}</Typography>
                      <Typography variant="body1" color="GrayText">
                        {scheduler.time}
                      </Typography>
                      <Typography variant="body1" color="GrayText">
                        Async: node aws-async-schedulers.js {scheduler.async}
                      </Typography>
                    </FlexWrapper>
                  </ListItem>
                ))}
              </List>
            </Paper>

            <Paper elevation={3} sx={{ padding: "16px" }}>
              <Typography variant="h6" align="center" gutterBottom>
                Cron Netsuite Schedulers
              </Typography>
              <List>
                {NSSCHEDULERS.map((scheduler) => (
                  <ListItem key={scheduler.name}>
                    <FlexWrapper flexDirection="column">
                      <Typography variant="h6">{scheduler.name}</Typography>
                      <Typography variant="body1" color="GrayText">
                        {scheduler.time}
                      </Typography>
                      <Typography variant="body1" color="GrayText">
                        Async: node netsuite-async-schedulers.js{" "}
                        {scheduler.async}
                      </Typography>
                    </FlexWrapper>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </FlexWrapper>
        </Box>
      </FullScreenModal>

      <DataGrid
        sx={{ bgcolor: "white", height: "91vh", width: "100%" }}
        rows={cronLogs}
        columns={CRONLOGCOLUMNS}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 100 },
          },
        }}
        pageSizeOptions={[100, 150, 200]}
        density="compact"
        disableColumnMenu
        getRowId={(row) => row._id}
        slots={{ toolbar: CustomToolbar }}
        disableDensitySelector
        disableColumnSelector
        loading={loading}
      />
    </Box>
  );
};
export default AdminCronLogsView;
