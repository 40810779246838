import { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box, Tab, Tabs } from "@mui/material";
import { CalendarMonth, Email, People, Print } from "@mui/icons-material";
import { useGeneralAlert } from "../../hooks/useGeneralAlert";

const Logs = () => {
  const { openAlert } = useGeneralAlert();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location?.pathname;

  const [tabValue, setTabValue] = useState(currentPath || "/admin/logs");
  const [loading, setLoading] = useState(false);

  const handleChange = (_, newValue) => {
    setTabValue(newValue);
    navigate(newValue);
  };

  const handleAlert = (alertObj) => {
    openAlert(alertObj);
  };

  return (
    <Box flex={1} px={2} py={1} overflow="hidden">
      <Tabs value={tabValue} onChange={handleChange} centered>
        <Tab label="Cron Logs" value="/admin/logs" icon={<CalendarMonth />} />
        <Tab
          label="Login Logs"
          value="/admin/logs/login-logs"
          icon={<People />}
        />
        <Tab
          label="PrintNode Logs"
          value="/admin/logs/printnode-logs"
          icon={<Print />}
        />
        <Tab
          label="Email Logs"
          value="/admin/logs/email-logs"
          icon={<Email />}
        />
      </Tabs>
      <Outlet context={{ loading, setLoading, handleAlert }} />
    </Box>
  );
};

export default Logs;
