import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import { Box, Typography } from "@mui/material";
import { REPLENISHFROMOVERSTOCKCOLUMNS } from "../../../../utils/columns/inventory-reports/inventoryReportsColumns";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

import axios from "../../../../axios/axios.config";
import Loading from "../../../../components/Loading";
import FlexWrapper from "../../../../components/FlexWrapper";

const ReplenishFromOverstockReport = () => {
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState("Loading Data...");

  const { currentNetsuiteLocationIds } = useSelector((state) => state.user);
  const { openAlert } = useGeneralAlert();

  useEffect(() => {
    handleGetData();
  }, []);

  const handleGetData = async () => {
    try {
      const response = await axios.get(
        `netsuite/inventory-reports/get/replenish-overstock?nsLocations=${JSON.stringify(
          currentNetsuiteLocationIds
        )}`
      );

      setReportData(response.data);
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not load data: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    } finally {
      setLoading("");
    }
  };

  if (Boolean(loading)) {
    return <Loading message={loading} />;
  }

  return (
    <Box sx={{ mt: 1, px: 2, width: "100" }}>
      <Typography variant="h4" align="center" gutterBottom>
        Replenish From Overstock Report
      </Typography>

      {reportData.length > 0 ? (
        <Box sx={{ width: "100%" }}>
          <DataGrid
            sx={{ height: "82vh", mt: 1 }}
            rows={reportData}
            columns={REPLENISHFROMOVERSTOCKCOLUMNS}
            disableRowSelectionOnClick
            getRowId={(row) => row["Rownum"]}
            density="compact"
            slots={{
              toolbar: () => (
                <GridToolbarContainer>
                  <FlexWrapper width="100%" justifyContent="space-between">
                    <Box>
                      <GridToolbarExport
                        sx={{ mr: 2 }}
                        csvOptions={{
                          fileName: `replenish-from-overstock-${Date.now()}`,
                        }}
                        printOptions={{ disableToolbarButton: true }}
                      />
                      <GridToolbarFilterButton />
                    </Box>

                    <GridToolbarQuickFilter />
                  </FlexWrapper>
                </GridToolbarContainer>
              ),
            }}
          />
        </Box>
      ) : (
        <Typography pt={3} variant="h5" textAlign="center">
          No Data To Display
        </Typography>
      )}
    </Box>
  );
};

export default ReplenishFromOverstockReport;
