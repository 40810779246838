import { Divider, IconButton, Stack, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";

import GeneralModalV3 from "../../general/GeneralModalV3";

const ReceiveInspectionItemInfoModal = ({ item }) => {
  return (
    <GeneralModalV3
      openComponent={
        <IconButton size="small">
          <Info color="primary" fontSize="small" />
        </IconButton>
      }
    >
      <Typography variant="h4" textAlign="center" py={1}>
        {item.lineitemname}
      </Typography>
      <Divider />
      <Stack p={1}>
        <Typography variant="h6">
          <strong>Qty: </strong> {item.lineitemqtypendingreceipt}
        </Typography>
        <Typography variant="h6">
          <strong>Received: </strong> {item.lineitemqtyreceived}
        </Typography>
        <Typography variant="h6">
          <strong>Remaining: </strong>{" "}
          {item.lineitemqty - item.lineitemqtyreceived}
        </Typography>
        {item.lineitemqtyreceived !== 0 && (
          <Typography variant="h6">
            <strong>last Updated : </strong>{" "}
            {new Date(item?.updatedAt)?.toLocaleString()}
          </Typography>
        )}
      </Stack>
    </GeneralModalV3>
  );
};
export default ReceiveInspectionItemInfoModal;
