import { Button, Container, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";

import FlexWrapper from "../../../../components/FlexWrapper";

const InventoryTransactionHistory = () => {
  const [partNumber, setPartNumber] = useState("");
  const [binNumber, setBinNumber] = useState("");

  const handleViewInNetsuite = () => {
    const nsSite =
      process.env.REACT_APP_NODE_ENV === "PRODUCTION"
        ? "4058904"
        : "4058904-sb1";

    let link = `https://${nsSite}.app.netsuite.com/app/common/search/searchresults.nl?searchid=customsearch_mern_findinvtrans${
      partNumber ? `&IT_Item_NAME=${partNumber}&IT_Item_NAMEtype=IS` : ""
    }${
      binNumber
        ? `&Transaction_BINNUMBER=${binNumber}&Transaction_BINNUMBERtype=IS`
        : ""
    }&style=NORMAL&report=&grid=&csv=HTML&OfficeXML=F&pdf=&size=300`;

    window.open(link, "_blank"); // Open in a new tab
  };

  return (
    <Container maxWidth="sm">
      <Typography textAlign="center" variant="h4" gutterBottom>
        Inventory Transaction History by Part and/or Bin
      </Typography>
      <Stack spacing={2} sx={{ pb: 1 }}>
        <TextField
          label="Part Number"
          value={partNumber}
          onChange={(event) =>
            setPartNumber(event.target.value?.toUpperCase()?.trim())
          }
        />
        <TextField
          label="Bin Number"
          value={binNumber}
          onChange={(event) =>
            setBinNumber(event.target.value?.toUpperCase()?.trim())
          }
        />
      </Stack>

      <FlexWrapper justifyContent="center">
        <Button
          disabled={!partNumber && !binNumber}
          variant="contained"
          onClick={handleViewInNetsuite}
        >
          View in NetSuite
        </Button>
      </FlexWrapper>
    </Container>
  );
};
export default InventoryTransactionHistory;
