import { handleBrowserPrintToPrinter } from "./browserPrint";
import { scaleZPL } from "./scaleZPL";

import axios from "../../axios/axios.config";

export const handlePrintContentLabels = async ({
  startingArr,
  colSize = 22,
  currentPrinter = null,
  templateHeader = "",
  title,
  type = "print",
  currentSubsidiary = "AIT",
}) => {
  if (!currentPrinter) {
    throw new Error(
      "No Printer is assigned. Please select a printer in your settings."
    );
  }

  const finalTemplates = []; //will store the completed templates
  let totalQty = 0;
  let totalLines = 0;
  let chunks = chunkArrayIntoObjects(
    [...startingArr].sort((a, b) => {
      const itemA = a.item.item.toUpperCase(); // convert to uppercase to ensure case-insensitive sorting
      const itemB = b.item.item.toUpperCase();

      if (itemA < itemB) {
        return -1; // itemA comes first
      }
      if (itemA > itemB) {
        return 1; // itemB comes first
      }
      return 0; // items are equal
    }),
    colSize
  ); //transforms given array into an array of arrays seperated by the given column size

  //generate templates by looping through array chunks
  for (const chunk of chunks) {
    const { col1, col2 } = chunk;
    totalLines += col1.length + col2.length;
    let templateBody = "";

    if (col1.length) {
      let col1QtyString = `${
        currentSubsidiary === "AIT"
          ? "^FT0,1663^FB185,22,0,C,0^A0N,45^FD"
          : "^FT40,2040^FB185,22,0,C,0^A0N,55^FD"
      }${col1
        .map((obj, index) => {
          //add up col1 qty
          totalQty += obj.palletpackedamount;

          return index === col1.length - 1
            ? `${obj.palletpackedamount}^FS`
            : `${obj.palletpackedamount}\\&`;
        })
        .join("")}`;

      let col1ItemsString = `${
        currentSubsidiary === "AIT"
          ? "^FT185,1663^FB443,22,0,L,0^A0N,45^FD"
          : "^FT275,2040^FB443,22,0,L,0^A0N,55^FD"
      }${col1
        .map((obj, index) => {
          return index === col1.length - 1
            ? `${obj.item.item}^FS`
            : `${obj.item.item}\\&`;
        })
        .join("")}`;

      templateBody += col1QtyString;
      templateBody += col1ItemsString;
    }

    if (col2.length) {
      let col2QtyString = `${
        currentSubsidiary === "AIT"
          ? "^FT628,1663^FB185,22,0,C,0^A0N,45^FD"
          : "^FT850,2040^FB185,22,0,C,0^A0N,55^FD"
      }${col2
        .map((obj, index) => {
          //add up col2 qty
          totalQty += obj.palletpackedamount;

          return index === col2.length - 1
            ? `${obj.palletpackedamount}^FS`
            : `${obj.palletpackedamount}\\&`;
        })
        .join("")}`;

      let col2ItemsString = `${
        currentSubsidiary === "AIT"
          ? "^FT813,1663^FB443,22,0,L,0^A0N,45^FD"
          : "^FT1075,2040^FB443,22,0,L,0^A0N,55^FD"
      }${col2
        .map((obj, index) => {
          return index === col2.length - 1
            ? `${obj.item.item}^FS`
            : `${obj.item.item}\\&`;
        })
        .join("")}`;

      templateBody += col2QtyString;
      templateBody += col2ItemsString;
    }

    finalTemplates.push({
      template: templateHeader + templateBody,
    });
  }

  let finalZPL = "";

  //loop through final templates and print pages
  for (let i = 0; i < finalTemplates.length; i++) {
    const { template } = finalTemplates[i];
    const templateFooter = `
    ${
      currentSubsidiary === "AIT"
        ? "^FT0,1700^GB1212,1,6,B^FS"
        : "^FT0,2350^GB1800,1,6,B^FS"
    }
  ${
    currentSubsidiary === "AIT"
      ? "^FT30,1759^FB443,1,0,L,0^A0N,40^FD"
      : "^FT100,2440^FB500,1,0,L,0^A0N,50^FD"
  }Page ${i + 1} of ${finalTemplates.length}^FS
    ${
      currentSubsidiary === "AIT"
        ? "^FT591,1759^FB185,1,0,L,0^A0N,40^FD"
        : "^FT750,2440^FB250,1,0,L,0^A0N,50^FD"
    }${totalLines} Lines^FS
    ${
      currentSubsidiary === "AIT"
        ? "^FT887,1759^FB185,1,0,L,0^A0N,40^FD"
        : "^FT1250,2440^FB250,1,0,L,0^A0N,50^FD"
    }${totalQty} Units^FS
    ^PQ1,0,1,Y
    ^XZ`;

    finalZPL += template + templateFooter;
  }

  //either print or return the final zpl code generated generated
  if (type === "print") {
    let templateType =
      currentPrinter.printerresolution !== "300dpi"
        ? scaleZPL(finalZPL)
        : finalZPL;
    let printJobUrl = "https://api.printnode.com/printjobs";
    let apiID = process.env.REACT_APP_PRINT_API_ID;
    let body = {
      printerId: currentPrinter.printnodeid,
      title,
      contentType: "raw_base64",
      content: btoa(templateType),
      source: "MERN > Pack Process > printContentLabels",
    };

    if (
      currentPrinter.printername?.startsWith("USB") &&
      currentPrinter.printnodeid === 0
    ) {
      return handleBrowserPrintToPrinter({
        currentPrinter,
        template: finalZPL,
      });
    } else {
      return axios.post(printJobUrl, body, {
        headers: {
          Authorization: "Basic " + btoa(apiID),
          "Content-Type": "application/json",
        },
      });
    }
  } else {
    //returns final zpl code
    return finalZPL;
  }
};

//returns new array split up depending on chunk size
const chunkArrayIntoObjects = (arr, colSize) => {
  let result = [];
  let numChunks = Math.ceil(arr.length / (colSize * 2)); // Each object has two columns with colSize items each

  for (let i = 0; i < numChunks; i++) {
    let start = i * (colSize * 2); // Start index for each chunk
    let middle = Math.min(start + colSize, arr.length); // Middle point to separate col1 and col2
    let end = Math.min(start + colSize * 2, arr.length); // End point for the chunk

    // Create an object with col1 and col2
    result.push({
      col1: arr.slice(start, middle), // First 22 items for col1
      col2: arr.slice(middle, end), // Next 22 items for col2
    });
  }

  return result;
};
