import { useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useGeneralAlert } from "../../hooks/useGeneralAlert";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { PRINTERCOLUMS } from "../../utils/columns/admin/adminColumns";
import { Add, Close, Edit, Link, LinkOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {
  closeFullScreenModal,
  openFullScreenModal,
} from "../../redux/features/modal/modalSlice";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../components/FlexWrapper";
import FullScreenModal from "../../components/FullScreenModal";
import axios from "../../axios/axios.config";

const PrinterForm = ({ selectedPrinter, setSelectedPrinter, setPrinters }) => {
  const dispatch = useDispatch();
  const { openAlert } = useGeneralAlert();
  const [printerInput, setPrinterInput] = useState({
    printername: "",
    printerdescription: "",
    printermodel: "",
    printermanufacturer: "",
    printerlocation: "",
    printerresolution: "",
    printeripaddress: "",
    printnodeid: "",
    printerwhselocation: "",
  });
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    getLocations();
    if (selectedPrinter) {
      setPrinterInput({
        printername: selectedPrinter.printername,
        printerdescription: selectedPrinter.printerdescription,
        printermodel: selectedPrinter.printermodel,
        printermanufacturer: selectedPrinter.printermanufacturer,
        printerlocation: selectedPrinter.printerlocation,
        printerresolution: selectedPrinter.printerresolution,
        printeripaddress: selectedPrinter.printeripaddress,
        printnodeid: selectedPrinter.printnodeid,
        printerwhselocation: selectedPrinter.printerwhselocation,
      });
    } else {
      setPrinterInput({
        printername: "",
        printerdescription: "",
        printermodel: "",
        printermanufacturer: "",
        printerlocation: "",
        printerresolution: "",
        printeripaddress: "",
        printnodeid: "",
        printerwhselocation: "",
      });
    }
  }, [selectedPrinter]);

  const getLocations = async () => {
    try {
      //get all locations from db
      const locationResponse = await axios.get("locations?type=shortname");
      setLocations(["ALL", ...locationResponse.data.sort()]);
    } catch (error) {
      openAlert({
        type: "error",
        message: `location fetch error: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let newPrinters;
      if (!selectedPrinter) {
        //create path
        const response = await axios.post("printers", printerInput);
        newPrinters = response.data;
      } else {
        //edit path
        const response = await axios.patch(
          `printers/${selectedPrinter._id}`,
          printerInput
        );
        newPrinters = response.data;
      }

      setPrinterInput({
        printername: "",
        printerdescription: "",
        printermodel: "",
        printermanufacturer: "",
        printerlocation: "",
        printerresolution: "",
        printeripaddress: "",
        printnodeid: "",
      });
      setPrinters(newPrinters);
      setSelectedPrinter(null);
      dispatch(closeFullScreenModal());

      openAlert({
        type: "success",
        message: `Successfully ${
          selectedPrinter ? "updated" : "created"
        } printer`,
        duration: 3000,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: `Error: ${error.response?.data?.msg || error.message}`,
        duration: 5000,
      });
    }
  };

  const handleChanges = (event) => {
    setPrinterInput({
      ...printerInput,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <FullScreenModal>
      <Box p={1}>
        <FlexWrapper>
          <IconButton
            onClick={() => {
              dispatch(closeFullScreenModal());
              setSelectedPrinter(null);
            }}
          >
            <Close />
          </IconButton>
        </FlexWrapper>

        <FlexWrapper justifyContent="center">
          <Container component="form" maxWidth="sm" onSubmit={handleSubmit}>
            <Typography variant="h3" textAlign="center" gutterBottom>
              {selectedPrinter ? "Update" : "Create"} Printer
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  fullWidth
                  required
                  type="text"
                  name="printername"
                  label="Printer Name"
                  value={printerInput.printername}
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="printerdescription"
                  label="Printer Description"
                  value={printerInput.printerdescription}
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="printermodel"
                  label="Printer Model"
                  value={printerInput.printermodel}
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="printermanufacturer"
                  label="Printer Manufacturer"
                  value={printerInput.printermanufacturer}
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="printerlocation"
                  label="Printer Location"
                  value={printerInput.printerlocation}
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="printerresolution"
                  label="Printer Resolution"
                  value={printerInput.printerresolution}
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  name="printeripaddress"
                  label="Printer IP Address"
                  value={printerInput.printeripaddress}
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  type="number"
                  name="printnodeid"
                  label="Printnode ID"
                  value={printerInput.printnodeid}
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  <InputLabel id="select-brand">
                    Printer Warehouse Location
                  </InputLabel>
                  <Select
                    labelId="select-whse-location"
                    id="select-whse-location"
                    name="printerwhselocation"
                    label="Printer Warehouse Location"
                    value={printerInput.printerwhselocation}
                    onChange={handleChanges}
                  >
                    {locations.map((location) => (
                      <MenuItem key={location} value={location}>
                        {location}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" type="submit" fullWidth>
                  {selectedPrinter ? "Update" : "Create"} Printer
                </Button>
              </Grid>
            </Grid>
          </Container>
        </FlexWrapper>
      </Box>
    </FullScreenModal>
  );
};

const Printers = () => {
  const printerData = useLoaderData();
  const [printers, setPrinters] = useState(printerData);
  const [selectedPrinter, setSelectedPrinter] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Box flex={1} px={2} py={1} overflow="hidden">
      <PrinterForm
        setPrinters={setPrinters}
        selectedPrinter={selectedPrinter}
        setSelectedPrinter={setSelectedPrinter}
      />

      <FlexWrapper flexDirection="column" gap={1}>
        <FlexWrapper
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <FlexWrapper alignItems="center" gap={1}>
            <Typography variant="h3" component="h1" gutterBottom>
              Printers
            </Typography>
            <a href="https://api.printnode.com/app/devices" target="_blank">
              <Link color="primary" />
            </a>
          </FlexWrapper>

          <FlexWrapper gap={1}>
            {selectedPrinter &&
              !selectedPrinter?.printername?.startsWith("USB") && (
                <Button
                  variant="contained"
                  startIcon={<LinkOutlined />}
                  onClick={() =>
                    navigate(
                      `/admin/logs/printnode-logs?filter=${selectedPrinter?.printnodeid}`
                    )
                  }
                >
                  View Logs
                </Button>
              )}
            {selectedPrinter && (
              <Button
                variant="contained"
                startIcon={<Edit />}
                onClick={() => dispatch(openFullScreenModal())}
              >
                Edit Printer
              </Button>
            )}

            <Button
              variant="contained"
              color="success"
              startIcon={<Add />}
              onClick={() => {
                dispatch(openFullScreenModal());
                setSelectedPrinter(null);
              }}
            >
              Create Printer
            </Button>
          </FlexWrapper>
        </FlexWrapper>

        <DataGrid
          sx={{ maxHeight: "90vh", width: "100%" }}
          rows={printers}
          columns={PRINTERCOLUMS}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
          }}
          pageSizeOptions={[100, 150, 200]}
          density="compact"
          disableColumnMenu
          getRowId={(row) => row._id}
          slots={{ toolbar: GridToolbar }}
          disableDensitySelector
          disableColumnSelector
          slotProps={{
            toolbar: {
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              csvOptions: { fileName: `printers-${Date.now()}` },
            },
          }}
          onRowSelectionModelChange={(selection) => {
            setSelectedPrinter(
              printers.find((printer) => printer._id === selection[0])
            );
          }}
        />
      </FlexWrapper>
    </Box>
  );
};
export default Printers;
