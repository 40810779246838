import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";

import axios from "../../../axios/axios.config";
import Loading from "../../Loading";

const ReceiveInspectionActiveOrders = () => {
  const navigate = useNavigate();
  const { username } = useSelector((state) => state.user);
  const { openAlert } = useGeneralAlert();

  const [userOrders, setUserOrders] = useState([]);
  const [loading, setLoading] = useState("");

  useEffect(() => {
    getUserOrders();
  }, []);

  const getUserOrders = async () => {
    setLoading("Loading Your Orders...");
    try {
      const response = await axios.get(
        `receive-inspection/get/order/active-orders/${username}`
      );

      setUserOrders(response.data);
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not get orders: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 5000,
      });
    } finally {
      setLoading("");
    }
  };

  if (Boolean(loading)) {
    return <Loading message={loading} />;
  }

  return (
    <Box py={2}>
      <Typography variant="h6" textAlign="center">
        Your Orders
      </Typography>

      <Box>
        <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          pb={1}
          gap={1}
        >
          {userOrders?.length ? (
            userOrders.map((order) => (
              <Grid key={order._id} item xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    height: "100%",
                    bgcolor: "#f5f5f5",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                  variant="outlined"
                  onClick={() => navigate(order._id)}
                >
                  <CardContent sx={{ pb: "16px" }}>
                    <Typography variant="subtitle1" textAlign="center">
                      {order.tranid}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Craeted Date:</strong>{" "}
                      {new Date(order.createdAt).toLocaleDateString()}
                    </Typography>

                    {order.entity && (
                      <Typography variant="body2">
                        <strong>Customer:</strong> {order.entity}
                      </Typography>
                    )}
                    {order.location && (
                      <Typography variant="body2">
                        <strong>Location:</strong> {order.location}
                      </Typography>
                    )}
                    <Typography variant="body2">
                      <strong>Total To Receive:</strong>{" "}
                      {order.totalitemstoreceive}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Total Received :</strong> {order.totalreceived}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography textAlign="center">No orders to display</Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
export default ReceiveInspectionActiveOrders;
