import { useEffect, useState } from "react";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Search } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  InputAdornment,
  LinearProgress,
  Link,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../../../components/FlexWrapper";
import Loading from "../../../../components/Loading";
import ReceiveTransactionAdminDeleteModal from "../../../../components/warehouse/receive-transaction-admin/ReceiveTransactionAdminDeleteModal";
import axios from "../../../../axios/axios.config";

const ViewReceivedTransactionsPage = () => {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [receiveData, setReceiveData] = useState([]);

  const user = useSelector((state) => state.user);
  const { openAlert } = useGeneralAlert();

  useEffect(() => {
    getReceiveData();
  }, [tabValue]);

  const getReceiveData = async () => {
    try {
      let response = null;
      if (tabValue === 0) {
        response = await axios.get(
          `receive-transaction/get/status/inprogress?locations=${JSON.stringify(
            user.currentNetsuiteLocationIds
          )}`
        );
      }

      if (tabValue === 1) {
        response = await axios.get(
          `receive-inspection/get/order/status/inprogress?locations=${JSON.stringify(
            user.currentNetsuiteLocationIds
          )}`
        );
      }

      if (!response) {
        throw new Error("Could not get data for tab value");
      }

      setReceiveData(response.data);
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not get data: ${
          error.response?.data?.msg || error.message
        }`,
      });
    }
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (loading) {
    return <Loading message={loading} />;
  }

  const filteredData = search
    ? receiveData.filter(
        (item) =>
          item.tranid?.toUpperCase()?.includes(search) ||
          item.createdby?.toUpperCase()?.includes(search)
      )
    : receiveData;

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" textAlign="center" gutterBottom>
        Receive Transactions In Progress
      </Typography>

      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Tabs
            centered
            value={tabValue}
            onChange={handleChange}
            aria-label="Change receive type tabs"
            sx={{ mb: 1 }}
          >
            <Tab value={0} label="Standard" />
            <Tab value={1} label="Inspection" />
          </Tabs>
        </Grid>

        <Grid item xs={12} md={6} pb={1}>
          <TextField
            fullWidth
            size="small"
            label="Search"
            value={search}
            onChange={(event) => setSearch(event.target.value.toUpperCase())}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} pb={1}>
          <Typography textAlign="right">Total: {receiveData.length}</Typography>
        </Grid>
      </Grid>

      <Box sx={{ height: "75vh", overflow: "scroll", py: 1 }}>
        <Stack spacing={2} flexWrap="wrap" alignItems="center">
          {filteredData.length ? (
            filteredData.map((order) => (
              <Card variant="outlined" key={order._id} sx={{ width: "100%" }}>
                <CardContent>
                  <FlexWrapper
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <FlexWrapper alignItems="center">
                      {user.rolelevel <= 1 && (
                        <RouterLink
                          to={
                            tabValue === 0
                              ? `/portal/${user?.currentDepartment.toLowerCase()}/receive-transaction/${
                                  order._id
                                }`
                              : `/portal/${user?.currentDepartment.toLowerCase()}/receive-transaction-inspection/${
                                  order._id
                                }`
                          }
                          style={{ color: "#1976d2" }}
                          target="_blank"
                        >
                          <Typography variant="caption">{order._id}</Typography>
                        </RouterLink>
                      )}
                      {user.rolelevel <= 1 && order.totalreceived === 0 && (
                        <span>
                          <ReceiveTransactionAdminDeleteModal
                            order={order.tranid}
                            _id={order._id}
                            setLoading={setLoading}
                            setReceiveData={setReceiveData}
                            tabValue={tabValue}
                            getReceiveData={getReceiveData}
                          />
                        </span>
                      )}
                    </FlexWrapper>
                    <Box>
                      <Typography
                        variant="h6"
                        gutterBottom
                        textAlign="center"
                        fontWeight="bold"
                      >
                        <Link
                          href={`https://${process.env.REACT_APP_NS_ACCOUNT_KEY}.app.netsuite.com/app/accounting/transactions/transaction.nl?id=${order.internalid}`}
                          target="_blank"
                        >
                          {order.tranid} - {order.brandpackaging}
                        </Link>
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        textAlign="center"
                      >
                        {order.entity}
                      </Typography>
                    </Box>

                    <Typography
                      variant="caption"
                      gutterBottom
                      textAlign="right"
                    >
                      {order.status?.toUpperCase() === "INPROGRESS"
                        ? "IN PROGRESS"
                        : order.status?.toUpperCase()}
                    </Typography>
                  </FlexWrapper>

                  <Typography variant="body1" color="textSecondary">
                    <strong>Started By:</strong> {order.createdby}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    <strong>Total Quantity to Receive:</strong>{" "}
                    {order.totalitemstoreceive?.toLocaleString()}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    <strong>
                      Total {tabValue === 1 ? "Order" : ""} Quantity Received:
                    </strong>{" "}
                    {order.totalreceived?.toLocaleString()}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    <strong>Remaining Quantity To Receive:</strong>{" "}
                    {(
                      order.totalitemstoreceive - order.totalreceived
                    )?.toLocaleString()}
                  </Typography>
                  {tabValue === 1 && (
                    <>
                      <Typography variant="body1" color="textSecondary">
                        <strong>Extra Items Received:</strong>{" "}
                        {order.extrareceived?.toLocaleString()}
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        <strong>Combined Received:</strong>{" "}
                        {order.combinedreceived?.toLocaleString()}
                      </Typography>
                    </>
                  )}
                  <Typography variant="body1" color="textSecondary">
                    <strong>Created At:</strong>{" "}
                    {new Date(order.createdAt)?.toLocaleString() || ""}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    <strong>Last Update:</strong>{" "}
                    {new Date(order.updatedAt)?.toLocaleString()}
                  </Typography>

                  <Box mt={2}>
                    <FlexWrapper alignItems="center" flexWrap="nowrap">
                      <Box mr={2} sx={{ width: "100%" }}>
                        <LinearProgress
                          variant="determinate"
                          value={parseInt(
                            (
                              (order.totalreceived /
                                order.totalitemstoreceive) *
                              100
                            ).toFixed(2)
                          )}
                          color="success"
                        />
                      </Box>

                      <Typography variant="body2" color="GrayText">{`${parseInt(
                        (
                          (order.totalreceived / order.totalitemstoreceive) *
                          100
                        ).toFixed(2)
                      )}%`}</Typography>
                    </FlexWrapper>
                  </Box>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography variant="h6" textAlign="center" pt={2}>
              No Transactions Being Received
            </Typography>
          )}
        </Stack>
      </Box>
    </Container>
  );
};
export default ViewReceivedTransactionsPage;
