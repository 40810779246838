import { ExitToApp, ExpandMore, Folder, Person } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";
import { Fragment, useEffect, useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { sendGeneralEmail } from "../../utils/email/generalEmailFunction";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../components/FlexWrapper";
import GeneralModal from "../../components/GeneralModal";
import axios from "../../axios/axios.config";
import RMANotes from "../../components/multi-page/labor-claim/RMANotes";
import Attachments from "../../components/multi-page/labor-claim/Attachments";
import CreditMemoAndRMANetsuiteLinks from "../../components/multi-page/netsuite/CreditMemoAndRMANetsuiteLinks";

const Modal = ({ open, handleClose, children }) => {
  return (
    <GeneralModal open={open} handleClose={handleClose}>
      {children}
    </GeneralModal>
  );
};

const LaborClaimViewMultiPage = () => {
  const {
    data: { form, createdby },
    fileURL,
  } = useLoaderData();

  const [openModal, setOpenModal] = useState({
    info: false,
    file: false,
    close: false,
  });
  const [nsPDFLinks, setNSPDFLinks] = useState({ creditMemo: "", rma: "" });
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formid } = useParams();

  useEffect(() => {
    getNSPDFSLinks();
  }, []);

  const getNSPDFSLinks = async () => {
    let rmaBlobUrl = "";
    let creditMemoBlobUrl = "";
    if (form.rmaNum && !form.rmaNum.startsWith("W")) {
      if (form.creditmemonumber) {
        //Credit Memo
        const nsCreditMemoPDFResponse = await axios.get(
          `netsuite/labor-claim/get/rma/${form.creditmemointernalid}/pdf`
        );
        const creditMemoBase64PDF = nsCreditMemoPDFResponse.data.data64;

        // Convert base64 to Blob
        const cmByteCharacters = atob(creditMemoBase64PDF);
        const cmByteNumbers = new Array(cmByteCharacters.length);
        for (let i = 0; i < cmByteCharacters.length; i++) {
          cmByteNumbers[i] = cmByteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(cmByteNumbers);
        const cmBlob = new Blob([byteArray], { type: "application/pdf" });

        // Create a Blob URL
        creditMemoBlobUrl = URL.createObjectURL(cmBlob);
      }

      if (form.rmaNum) {
        // RMA
        const nsRMAPDFResponse = await axios.get(
          `netsuite/labor-claim/get/rma/${form.rmainternalid}/pdf`
        );

        const rmaBase64 = nsRMAPDFResponse.data.data64;

        // Convert base64 to Blob
        const rmaByteCharacters = atob(rmaBase64);
        const rmaByteNumbers = new Array(rmaByteCharacters.length);
        for (let i = 0; i < rmaByteCharacters.length; i++) {
          rmaByteNumbers[i] = rmaByteCharacters.charCodeAt(i);
        }
        const rmaByteArray = new Uint8Array(rmaByteNumbers);
        const rmaBlob = new Blob([rmaByteArray], { type: "application/pdf" });

        // Create a Blob URL
        rmaBlobUrl = URL.createObjectURL(rmaBlob);
      }

      setNSPDFLinks({ creditMemo: creditMemoBlobUrl, rma: rmaBlobUrl });
    }
  };

  const handleReopenLaborClaim = async () => {
    try {
      const reopenDate = new Date().toLocaleString();

      const { data } = await axios.patch(`forms/update/no-version/${formid}`, {
        updateData: {
          "form.status": form.rmaNum ? "rmaissued" : "new",
          "form.reopenedbyname": `${user.firstname} ${user.lastname}`,
          "form.reopenedbyusername": user.username,
          "form.reopenedbyemail": user.email,
          "form.reopeneddate": reopenDate,
          "form.rmanotes": `${form.rmanotes}\n${reopenDate} (${user.username}) - Reopened`,
        },
      });

      if (data.form.rmaNum && !data.form.rmaNum.startsWith("W")) {
        //update Netsuite notes and reopen rma
        axios.patch(
          `netsuite/patch/rma/${data.form.rmainternalid}/status/toggle`,
          {
            notes: data.form.rmanotes,
            isclosed: false,
          }
        );
      } else {
        //update Netsuite notes
        axios.patch(
          `netsuite/patch/rma/${data.form.rmainternalid}/update-notes`,
          { notes: data.form.rmanotes }
        );
      }

      navigate(
        `/portal/${user.currentDepartment.toLowerCase()}/forms/labor-claim`
      );

      dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully updated labor claim`,
          duration: 5000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Could not reopen labor claim: ${
            error.response?.data?.msg || error.message
          }`,
          duration: 5000,
        })
      );
    }
  };

  const handleSendWarningEmail = async () => {
    try {
      const warningEmailDate = new Date().toLocaleString();

      const { data } = await axios.patch(`forms/update/no-version/${formid}`, {
        updateData: {
          "form.rmawarningemail": warningEmailDate,
          "form.rmanotes": `${
            form.rmanotes ? `${form.rmanotes}\n` : ""
          }${warningEmailDate} (${user.username}) - Closure Email Sent`,
        },
      });

      //update Netsuite notes
      axios.patch(
        `netsuite/patch/rma/${data.form.rmainternalid}/update-notes`,
        { notes: data.form.rmanotes }
      );

      //send closure warning email to customer
      await sendGeneralEmail({
        to: process.env.REACT_APP_TEST_EMAIL || form.warehouseEmail,
        from: "returns@trakmotive.com",
        fromname: "AIT Returns",
        cc: process.env.REACT_APP_TEST_EMAIL
          ? ""
          : data.form.additionalEmails || "",
        bcc: process.env.REACT_APP_TEST_EMAIL ? "" : "returns@trakmotive.com",
        subject: `Closure Warning for RGS ${data.form.rgaNum}`,
        body: `
        <p class=MsoNormal><i><span style='font-family:"Georgia",serif'>After checking our records, we are still showing the following RMA open:</span></i></p>
        <p class=MsoNormal><i><span style='font-family:"Georgia",serif'>${data.form.rmaNum.replace(
          "RMA",
          "RMA #"
        )}</span></i></p>
        <p class=MsoNormal><i><span style='font-family:"Georgia",serif'>RGS ${
          data.form.rgaNum
        }</span></i></p>
        <p class=MsoNormal><i><span style='font-family:"Georgia",serif'>If you still want to move forward, we will need you to send the part(s) as well as all paperwork needed.</span></i></p>
        <p class=MsoNormal><i><span style='font-family:"Georgia",serif'>If you feel you have already sent this claim in please forward proof of delivery.</span></i></p>
        <p class=MsoNormal><i><span style='font-family:"Georgia",serif'>If we DO NOT have any contact pertaining the ${data.form.rmaNum.replace(
          "RMA",
          "RMA #"
        )} within the next 10 days, we will have no other choice but to close this RMA.</span></i></p>
       
        
        <p class=MsoNormal><i><span style='font-family:"Georgia",serif'><i><span style='font-family:"Georgia",serif'>Thank You,</span></i></br></p>
          <p class=MsoNormal><b><i><span style='font-size:12.0pt;font-family:"Georgia",serif;color:#1F3864;mso-ligatures:none'>${
            user.firstname
          } ${
          user.lastname
        }<o:p></o:p></span></i></b></p><p class=MsoNormal><i><span style='font-size:12.0pt;font-family:"Georgia",serif;color:#2F5496;mso-ligatures:none'>Returns Clerk<o:p>
          <p class=MsoNormal><b><span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>Advanced Innovative Technology Corp.</span></b><b>
          <span style='font-family:"Georgia",serif;color:#1F497D;mso-ligatures:none'><o:p></o:p></span></b></p><p class=MsoNormal><span style='font-size:10.0pt;font-family:"Georgia",serif;mso-ligatures:none'>350 Nevada St. Redlands, CA 92373<o:p></o:p></span></p>
          <p class=MsoNormal><b><span style='font-size:10.0pt;font-family:"Georgia",serif;color:#2F5496;mso-ligatures:none'>
          <a href="mailto:${user.email}"><span style='color:#2F5496'>${
          user.email
        }</span></a>
          <o:p></o:p></span></b></p><p class=MsoNormal><span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>O 909-253-0077 Ext:120<o:p></o:p></span></p>
          <p class=MsoNormal><span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>F 417-831-9447<o:p></o:p></span></p>
          <p class=MsoNormal><a href="http://www.trakmotive.com/"><span style='font-size:10.5pt;font-family:"Georgia",serif;mso-ligatures:none;text-decoration:none'><img border=0 width=183 height=53 style='width:1.9062in;height:.552in' id="Picture_x0020_11" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/trakmotive.png"></span></a>
          <span style='font-size:10.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'><o:p></o:p></span></p><p class=MsoNormal><b><i><span style='font-family:"Georgia",serif;mso-ligatures:none'>Our Job Is To Make Your Job Easier<o:p></o:p></span></i></b></p>
          <p class=MsoNormal><b><i><span style='font-family:"Georgia",serif;mso-ligatures:none'><o:p>&nbsp;</o:p></span></i></b></p><p class=MsoNormal><a href="https://www.facebook.com/trakmotive/"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_12" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/facebook.png"></span></a>
          <span style='font-family:"Georgia",serif;mso-ligatures:none'>&nbsp; </span><a href="https://twitter.com/TrakMotiveAxles"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_13" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/twitter.png"></span></a><span style='font-family:"Georgia",serif;mso-ligatures:none'>&nbsp;&nbsp;</span>
          <a href="https://www.linkedin.com/company/advanced-innovative-technology-corp-/"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_14" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/linkedin.png"></span></a><span style='font-family:"Georgia",serif;mso-ligatures:none'>&nbsp;&nbsp;</span>
          <a href="https://www.instagram.com/trakmotive/?hl=en"><span style='font-family:"Georgia",serif;color:windowtext;mso-ligatures:none;text-decoration:none'><img border=0 width=48 height=48 style='width:.5in;height:.5in' id="Picture_x0020_15" src="https://www.trakmotive.com/wp-content/uploads/emailsignature/instagram.png"></span></a><span style='font-family:"Georgia",serif;mso-ligatures:none'><o:p></o:p></span></p><p class=MsoNormal><span style='font-family:"Georgia",serif;mso-ligatures:none'><o:p>&nbsp;</o:p></span></p>
          <p class=MsoNormal><b><span style='font-size:8.0pt;font-family:"Georgia",serif;color:black;mso-ligatures:none'>Community &#8211; Trust &#8211; Dedication &#8211; Responsibility &#8211; Innovation &#8211; Growth<o:p></o:p></span></b></p>
          `,
        isHtml: true,
        type: "Labor Claim",
        currentDepartment: user.currentDepartment,
        currentSubsidiary: user.currentSubsidiary,
        username: user.username,
      });

      navigate(
        `/portal/${user.currentDepartment.toLowerCase()}/forms/labor-claim`
      );

      dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully sent rma closure email`,
          duration: 5000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error: ${error.response?.data?.msg || error.message}`,
          duration: 5000,
        })
      );
    }
  };

  const handleRMAClosureSubmit = async (event) => {
    event.preventDefault();
    try {
      const closedDate = new Date().toLocaleString();
      const formData = new FormData(event.target);
      const reasonInput = formData.get("closedreason");

      if (!reasonInput) {
        throw new Error("Reason is required to close rma");
      }

      const { data } = await axios.patch(`forms/update/no-version/${formid}`, {
        updateData: {
          "form.status": "incomplete",
          "form.closedreason": reasonInput,
          "form.closedbyname": `${user.firstname} ${user.lastname}`,
          "form.closedbyusername": `${user.username}`,
          "form.closedbyemail": `${user.email}`,
          "form.closeddate": closedDate,
          "form.rmanotes": `${
            form.rmanotes ? `${form.rmanotes}\n` : ""
          }${closedDate} (${user.username}) - Closed - ${reasonInput}`,
        },
      });

      if (data.form.rmaNum && !data.form.rmaNum.startsWith("W")) {
        //update Netsuite notes and close rma
        axios.patch(
          `netsuite/patch/rma/${data.form.rmainternalid}/status/toggle`,
          {
            notes: data.form.rmanotes,
            isclosed: true,
          }
        );
      } else {
        //update Netsuite notes
        axios.patch(
          `netsuite/patch/rma/${data.form.rmainternalid}/update-notes`,
          { notes: data.form.rmanotes }
        );
      }

      navigate(
        `/portal/${user.currentDepartment.toLowerCase()}/forms/labor-claim`
      );

      dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully closed rma`,
          duration: 5000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Error, could not close rma:  ${
            error.response?.data?.msg || error.message
          }`,
          duration: 5000,
        })
      );
    }
  };

  return (
    <Container maxWidth="md" sx={{ pb: 2 }}>
      {/* Info Modal */}
      <Modal
        open={openModal.info}
        handleClose={() =>
          setOpenModal({ info: false, file: false, close: false })
        }
      >
        <Box p={2}>
          <Typography gutterBottom variant="h4" textAlign="center">
            Labor Claim Created By
          </Typography>

          <Typography>
            <strong>Name:</strong> {createdby.name}
          </Typography>
          <Typography>
            <strong>Username:</strong> {createdby.username.toUpperCase()}
          </Typography>
          <Typography>
            <strong>Email:</strong> {createdby.email.toUpperCase()}
          </Typography>
          <Typography>
            <strong>Department:</strong> {createdby.department}
          </Typography>
          <Typography>
            <strong>User Type:</strong> {createdby.usertype.toUpperCase()}
          </Typography>
        </Box>
      </Modal>

      {/* Close RMA Modal */}
      <Modal
        open={openModal.close}
        handleClose={() =>
          setOpenModal({ info: false, file: false, close: false })
        }
      >
        <Typography variant="h4" textAlign="center" p={1}>
          Close RMA?
        </Typography>
        <Divider />
        <Box p={2} component="form" onSubmit={handleRMAClosureSubmit}>
          <Typography textAlign="center" pb={2}>
            Please provide a brief descrption for closing RMA:
          </Typography>
          <TextField
            fullWidth
            multiline
            minRows={4}
            name="closedreason"
            label="Reason For Closure"
          />
          <Button
            type="submit"
            color="error"
            fullWidth
            variant="contained"
            sx={{ mt: 2 }}
          >
            Submit and close
          </Button>
        </Box>
      </Modal>

      <Box>
        <FlexWrapper justifyContent="space-between">
          <Tooltip title="Back To Labor Claims" placement="top" arrow>
            <IconButton
              onClick={() =>
                navigate(
                  `/portal/${user.currentDepartment.toLowerCase()}/forms/labor-claim`
                )
              }
            >
              <ExitToApp color="error" />
            </IconButton>
          </Tooltip>

          <FlexWrapper alignItems="center">
            {form.status === "incomplete" && (
              <>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ mr: 2 }}
                  onClick={handleReopenLaborClaim}
                >
                  Reopen Labor Claim
                </Button>
                <Divider orientation="vertical" flexItem />
              </>
            )}

            {form.status === "rmaissued" && form.rmaNum.startsWith("RMA") && (
              <>
                {form.rmawarningemail ? (
                  <Button
                    size="small"
                    variant="contained"
                    color="error"
                    sx={{ mr: 2 }}
                    onClick={() => setOpenModal({ ...openModal, close: true })}
                  >
                    Close RMA
                  </Button>
                ) : (
                  <Button
                    size="small"
                    variant="contained"
                    color="error"
                    sx={{ mr: 2 }}
                    onClick={handleSendWarningEmail}
                  >
                    Send RMA Closure Warning Email
                  </Button>
                )}
                <Divider orientation="vertical" flexItem />
              </>
            )}

            <Tooltip title="Created By User" placement="top" arrow>
              <IconButton
                onClick={() => {
                  setOpenModal({ file: false, info: true, close: false });
                }}
              >
                <Person color="info" />
              </IconButton>
            </Tooltip>

            <Attachments
              formid={formid}
              form={form}
              fileURL={fileURL}
              nsPDFLinks={nsPDFLinks}
            />

            {(user.currentDepartment === "RETURNS" ||
              user.currentDepartment === "PRODUCT" ||
              user.currentDepartment === "CUSTOMER-SERVICE" ||
              user.currentDepartment === "NINGBO") && (
              <RMANotes formid={formid} color="info" />
            )}
          </FlexWrapper>
        </FlexWrapper>

        <Typography variant="h3" textAlign="center" gutterBottom>
          Labor Claim Form
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {form.status === "rmaissued" && form.rmawarningemail && (
          <Grid item xs={12}>
            <Typography variant="body2" color="error">
              Closure warning email has been sent on {form.rmawarningemail}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          <Accordion elevation={1} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h5">Form Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Date"
                    name="date"
                    value={form.date}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Status"
                    name="date"
                    value={form.status.toUpperCase()}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Customer"
                    name="customer"
                    value={form.customer}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="text"
                    name="rgaNum"
                    label="Customer Use Only: RGA#"
                    value={form.rgaNum}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5" sx={{ my: 2 }}>
                    Service Facility and Vehicle Owner
                  </Typography>
                  <TextField
                    fullWidth
                    type="text"
                    name="serviceFacilityCompany"
                    label="Company Name"
                    value={form.serviceFacilityCompany}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="text"
                    name="serviceFacilityContactName"
                    label="Contact Name"
                    value={form.serviceFacilityContactName}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    helperText={
                      <Typography variant="caption" color="error">
                        No Special Characters
                      </Typography>
                    }
                    type="number"
                    inputMode="numeric"
                    name="serviceFacilityPhone"
                    label="Phone"
                    value={form.serviceFacilityPhone}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="email"
                    name="serviceFacilityEmail"
                    label="E-mail"
                    value={form.serviceFacilityEmail}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    minRows={4}
                    type="text"
                    name="serviceFacilityAddress"
                    label="Address"
                    value={form.serviceFacilityAddress}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="text"
                    name="technicianName"
                    label="Technician's Name"
                    value={form.technicianName}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="text"
                    name="serviceFacilityVehicleOwner"
                    label="Vehicle Owner’s Name"
                    value={form.serviceFacilityVehicleOwner}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5" sx={{ my: 2 }}>
                    Selling Party
                  </Typography>
                  <TextField
                    fullWidth
                    type="text"
                    name="warehouseCompany"
                    label="Company Name"
                    value={form.warehouseCompany}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="text"
                    name="warehouseContactName"
                    label="Contact Name"
                    value={form.warehouseContactName}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    helperText={
                      <Typography variant="caption" color="error">
                        No Special Characters
                      </Typography>
                    }
                    type="number"
                    inputMode="numeric"
                    name="warehousePhone"
                    label="Phone"
                    value={form.warehousePhone}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="email"
                    name="warehouseEmail"
                    label="E-mail"
                    value={form.warehouseEmail}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                {form.additionalEmails && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Additional Emails"
                      value={form.additionalEmails}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    minRows={4}
                    type="text"
                    name="warehouseAddress"
                    label="Address"
                    value={form.warehouseAddress}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5" sx={{ my: 2 }}>
                    Vehicle Information
                  </Typography>
                  <TextField
                    fullWidth
                    type="text"
                    name="vinNum"
                    label="VIN"
                    value={form.vinNum}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    type="number"
                    inputMode="numeric"
                    name="vehicleYear"
                    label="Year"
                    value={form.vehicleYear}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    type="text"
                    name="vehicleMake"
                    label="Make"
                    value={form.vehicleMake}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    type="text"
                    name="vehicleModel"
                    label="Model"
                    value={form.vehicleModel}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="text"
                    name="vehicleEngineSize"
                    label="Engine Size"
                    value={form.vehicleEngineSize}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Transmission Type"
                    name="vehicleEngineType"
                    value={form.vehicleEngineType}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Vehicle Drive"
                    name="vehicleDrive"
                    value={form.vehicleDrive}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="select-gas"
                    label="Engine Sub Type"
                    name="vehicleGasType"
                    value={form.vehicleGasType.join(", ")}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5" mt={2}>
                    Associated Parts and Labor
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="number"
                    inputMode="numeric"
                    name="laborHours"
                    label="Total Labor Hrs"
                    value={form.laborHours}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="number"
                    name="partsCost"
                    label="Parts Cost"
                    value={form.partsCost}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="number"
                    name="laborCost"
                    label="Labor Cost"
                    value={form.laborCost}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="number"
                    name="laborRate"
                    label="Labor Rate"
                    InputProps={{
                      readOnly: true,
                    }}
                    value={form.laborRate}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    type="text"
                    name="totalClaim"
                    label="Total Claim Amount"
                    value={form.totalClaim}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5" sx={{ my: 2 }}>
                    Product
                  </Typography>
                </Grid>
                {form.partNumbers?.length &&
                  form.partNumbers.map((partObj) => {
                    return (
                      <Fragment key={partObj.partnumber}>
                        <Grid item xs={10}>
                          <TextField
                            fullWidth
                            type="text"
                            label="Part Number"
                            value={partObj.partnumber}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            fullWidth
                            type="number"
                            label="Part Number Qty"
                            value={partObj.qty}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                      </Fragment>
                    );
                  })}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="text"
                    name="manufacturer"
                    label="Manufacturer"
                    value={form.manufacturer}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="number"
                    inputMode="numeric"
                    name="mileageInstallation"
                    label="Mileage At Installation"
                    value={form.mileageInstallation}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="number"
                    inputMode="numeric"
                    name="mileageFailure"
                    label="Mileage At Failure"
                    value={form.mileageFailure}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="date"
                    name="dateInstallation"
                    label="Date At Installation"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={form.dateInstallation}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="date"
                    name="dateFailure"
                    label="Date At Failure"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={form.dateFailure}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    minRows={4}
                    type="text"
                    name="failureDescription"
                    label="Description of Failure and Collateral Damage"
                    value={form.failureDescription}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                {form.comments && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      minRows={4}
                      type="text"
                      name="comments"
                      label="Additional Comments"
                      value={form.comments}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {form.status === "incomplete" && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">Incomplete</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="text"
                label="Closed By"
                InputProps={{ readOnly: true }}
                value={`${form.closedbyname} (${form.closedbyusername})`}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="text"
                label="Closed By Email"
                InputProps={{ readOnly: true }}
                value={form.closedbyemail}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="text"
                label="Closed Date"
                value={new Date(form.closeddate).toLocaleDateString()}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="text"
                label="Closed Time"
                value={new Date(form.closeddate).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                minRows={4}
                type="text"
                label="Reason For Closure"
                value={form.closedreason}
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </>
        )}

        {form.rmaNum && (
          <>
            <Grid item xs={12}>
              <FlexWrapper alignItems="center" gap={1}>
                <Typography variant="h6">RMA</Typography>
                {(user.currentDepartment === "RETURNS" ||
                  user.currentDepartment === "CUSTOMER-SERVICE" ||
                  user.currentDepartment === "PRODUCT") &&
                  form.rmainternalid && (
                    <CreditMemoAndRMANetsuiteLinks
                      internalid={form.rmainternalid}
                    />
                  )}
              </FlexWrapper>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="text"
                name="rmaNum"
                label="RMA Number"
                InputProps={{ readOnly: true }}
                value={form.rmaNum}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="text"
                name="rmaissuedby"
                label="RMA Issued By"
                value={`${form.rmaissuedbyname} (${form.rmaissuedby})`}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="text"
                name="rmaissuedbyemail"
                label="RMA Issued By Email"
                value={form.rmaissuedbyemail}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="text"
                label="RMA Issued By Department"
                value={form.rmaissueddepartment}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="text"
                label="RMA Issued Date"
                value={new Date(form.rmaissuedate).toLocaleDateString()}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="text"
                label="RMA Issued Time"
                value={new Date(form.rmaissuedate).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </>
        )}

        {form.receivedby && (
          <>
            <Grid item xs={12}>
              <Typography variant="h5">LC Received</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="text"
                name="receivedby"
                label="Received By"
                value={`${form.receivedbyname} (${form.receivedby})`}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="text"
                name="receivedbyemail"
                label="Received By Email"
                value={form.receivedbyemail}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="text"
                name="datereceived"
                label="Date Received"
                value={form.datereceived}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="time"
                name="timereceived"
                label="Time Received"
                value={form.timereceived}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="text"
                label="Bin Number"
                value={form.bin}
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </>
        )}

        {form.lcresponse && (
          <>
            <Grid item xs={12}>
              <Typography variant="h5">Processed</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Response"
                name="lcresponse"
                value={form.lcresponse}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                minRows={4}
                type="text"
                name="detail"
                label="Detail"
                value={form.detail}
                InputLabelProps={{ shrink: true }}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="text"
                name="note"
                label="Note"
                value={form.note}
                InputLabelProps={{ shrink: true }}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            {form.finalresponse && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="text"
                  label="Result"
                  value={form.finalresponse}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="text"
                name="note"
                label="Processed By"
                value={`${form.processedbyname} (${form.processedby})`}
                InputLabelProps={{ shrink: true }}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="text"
                name="note"
                label="Processed By Email"
                value={form.processedbyemail}
                InputLabelProps={{ shrink: true }}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            {form.processedbydate && (
              <>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Processed Date"
                    value={new Date(form.processedbydate).toLocaleDateString()}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Processed Time"
                    value={new Date(form.processedbydate).toLocaleTimeString(
                      "en",
                      {
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    )}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              </>
            )}
          </>
        )}

        {form.creditmemonumber && (
          <>
            <Grid item xs={12}>
              <FlexWrapper alignItems="center" gap={1}>
                <Typography variant="h5">Complete</Typography>
                {(user.currentDepartment === "RETURNS" ||
                  user.currentDepartment === "CUSTOMER-SERVICE" ||
                  user.currentDepartment === "PRODUCT") &&
                  form.creditmemointernalid && (
                    <CreditMemoAndRMANetsuiteLinks
                      type="CM"
                      internalid={form.creditmemointernalid}
                    />
                  )}
              </FlexWrapper>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="text"
                label="Credit Memo Number"
                value={form.creditmemonumber}
                InputLabelProps={{ shrink: true }}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="text"
                label="Credit Amount"
                value={form.creditamountfee}
                InputLabelProps={{ shrink: true }}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="text"
                label="Completed By"
                value={`${form.completedbyname} (${form.completedbyusername})`}
                InputLabelProps={{ shrink: true }}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="text"
                label="Completed By Email"
                value={form.completedbyemail}
                InputLabelProps={{ shrink: true }}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="text"
                label="Completed Date"
                value={new Date(form.completeddate).toLocaleDateString()}
                InputLabelProps={{ shrink: true }}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="text"
                label="Completed Time"
                value={new Date(form.completeddate).toLocaleTimeString(["en"], {
                  hour12: true,
                  hour: "numeric",
                  minute: "numeric",
                })}
                InputLabelProps={{ shrink: true }}
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};
export default LaborClaimViewMultiPage;
