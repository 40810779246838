import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Container,
  Typography,
  MenuItem,
  FormControl,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import axios from "../../../../axios/axios.config";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";

const UserInteractionsReports = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const { currentLocation, currentSubsidiary } = useSelector(
    (state) => state.user
  );
  const { openAlert } = useGeneralAlert();

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      const userResponse = await axios.get(
        `users/get/warehouse/users?location=${currentLocation}&subsidiary=${currentSubsidiary}`
      );
      setUsers(
        userResponse.data.sort((a, b) =>
          a.username.toLowerCase().localeCompare(b.username.toLowerCase())
        )
      );
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not get users: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    }
  };

  const getUserInteractions = async (user) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `multi-actions/get/last-interaction?username=${user.username}&userId=${user._id}`
      );

      setEvents(response.data);
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not get interactions: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
      setEvents([]);
    }
    setLoading(false);
  };

  const handleChange = (event) => {
    const user = users.find((u) => u._id === event.target.value);
    if (user) {
      setSelectedUser(user);
      getUserInteractions(user);
    }
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom textAlign="center">
        User Last Interactions
      </Typography>
      <FormControl fullWidth>
        <Select
          onChange={handleChange}
          value={selectedUser ? selectedUser._id : "Select a User"}
          placeholder="Select a User"
        >
          <MenuItem value="Select a User" disabled>
            Select a User
          </MenuItem>
          {users.map((user) => (
            <MenuItem key={user._id} value={user._id}>
              {user.username}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {loading ? (
        <Typography textAlign="center" marginTop={3}>
          <CircularProgress />
        </Typography>
      ) : selectedUser && events.length > 0 ? (
        <TableContainer component={Paper} sx={{ marginTop: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Interaction</TableCell>
                <TableCell>Reference</TableCell>
                <TableCell>Last Interaction Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map(({ type, timestamp, reference }, index) => {
                const isLatest = index === 0;
                return (
                  <TableRow
                    key={type}
                    sx={{ backgroundColor: isLatest ? "#ffeb3b" : "inherit" }}
                  >
                    <TableCell>
                      {type} {isLatest && "(Latest)"}
                    </TableCell>
                    <TableCell>{reference}</TableCell>
                    <TableCell>
                      {new Date(timestamp).toLocaleString()}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography textAlign="center" marginTop={3}>
          No data available for the selected user.
        </Typography>
      )}
    </Container>
  );
};

export default UserInteractionsReports;
