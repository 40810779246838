import { useEffect, useState } from "react";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleNav } from "../../../redux/features/nav/navBarSlice";
import { Assignment, FactCheck } from "@mui/icons-material";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Container,
  Paper,
} from "@mui/material";

import axios from "../../../axios/axios.config";
import Loading from "../../../components/Loading";
import ReceiveInspectionDetailsScreen from "../../../components/multi-page/receive-inspection/ReceiveInspectionDetailsScreen";
import ReceiveInspectionHeaderSection from "../../../components/multi-page/receive-inspection/ReceiveInspectionHeaderSection";
import ReceiveInspectionScanView from "../../../components/multi-page/receive-inspection/ReceiveInspectionScanView";
import ReceiveInspectionReceivedView from "../../../components/multi-page/receive-inspection/ReceiveInspectionReceivedView";
import ReceiveInspectionSearchView from "../../../components/multi-page/receive-inspection/ReceiveInspectionSearchView";

const ReceiveProcessInspectionResultsPage = () => {
  const { orderid } = useParams();
  const { loading, setLoading, handleStateUpdate } = useOutletContext();
  const { currentDepartment } = useSelector((state) => state.user);
  const { openAlert } = useGeneralAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isSearchViewOpen, setIsSearchViewOpen] = useState(false);
  const [bottomNavigationValue, setBottomNavigationValue] =
    useState("remaining");

  useEffect(() => {
    dispatch(toggleNav(true));
    getReceiveInspectionOrder();

    return () => dispatch(toggleNav(false));
  }, []);

  const getReceiveInspectionOrder = async () => {
    setLoading("Loading Order...");
    try {
      const orderResponse = await axios.get(
        `receive-inspection/get/order/${orderid}`
      );
      handleStateUpdate(orderResponse.data);
      setIsDetailsModalOpen(true);
    } catch (error) {
      // Redirect user back to search page

      openAlert({
        type: "error",
        message: `Could not find order width id: ${orderid}`,
        duration: 8000,
      });
      navigate(
        `/portal/${currentDepartment?.toLowerCase()}/receive-transaction-inspection`,
        {
          replace: true,
        }
      );
    } finally {
      setLoading("");
    }
  };
  const handleBottomNavigationChange = (event, newValue) => {
    setBottomNavigationValue(newValue);
  };

  // Loading Screen
  if (Boolean(loading)) {
    return <Loading message={loading} />;
  }
  // Details Screen
  if (!Boolean(loading) && isDetailsModalOpen) {
    return (
      <ReceiveInspectionDetailsScreen
        setIsDetailsModalOpen={setIsDetailsModalOpen}
      />
    );
  }
  // Search Screen
  if (!Boolean(loading) && isSearchViewOpen) {
    return (
      <ReceiveInspectionSearchView setIsSearchViewOpen={setIsSearchViewOpen} />
    );
  }
  // Main Results Screen
  return (
    <Container maxWidth="md">
      {/* Page Header */}
      <ReceiveInspectionHeaderSection
        setIsDetailsModalOpen={setIsDetailsModalOpen}
        setIsSearchViewOpen={setIsSearchViewOpen}
      />
      {/* Page Body */}
      <Box>
        {/* Scan View w/ Items */}
        {bottomNavigationValue === "remaining" && <ReceiveInspectionScanView />}
        {bottomNavigationValue === "received" && (
          <ReceiveInspectionReceivedView />
        )}
      </Box>

      {/* Bottom Navigation */}
      <Paper
        elevation={3}
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <BottomNavigation
          sx={{ width: "100%" }}
          showLabels
          value={bottomNavigationValue}
          onChange={handleBottomNavigationChange}
        >
          <BottomNavigationAction
            label="Remaining"
            value="remaining"
            icon={<Assignment />}
          />
          <BottomNavigationAction
            label="Received"
            value="received"
            icon={<FactCheck />}
          />
        </BottomNavigation>
      </Paper>
    </Container>
  );
};
export default ReceiveProcessInspectionResultsPage;
